import {Map} from "../Components/Map";
import { useTheme } from '@mui/material/styles';
import {
	Card,
	CardContent,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {DataGrid} from "@mui/x-data-grid";
import Link from "@mui/material/Link";
import {useState} from "react";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


export default function RobotJourneyMap({robots, heatmap, configset}) {
  const theme = useTheme();
	const [robotIds, setRobotIds] = useState([0]);

	let robotJourneyColumns = [{
		field: 'robot_index',
		headerName: 'Robot',
		type: 'number',
		width: 50,
		editable: false,
		sortable: true,
	},{
		field: 'job_index',
		headerName: 'Job',
		type: 'number',
		width: 50,
		editable: false,
		sortable: false,
	},{
		field: 'time',
		headerName: 'Time',
		width: 65,
		editable: false,
	},{
		field: 'pickup',
		headerName: 'Pickup',
		width: 280,
		editable: false,
		renderCell: (props) => (<span>
				<Link
					href={`https://maps.google.com/?q=${props.value["lat"]},${props.value["lng"]}`}
					target="_blank"
				>
					{`${props.value["name"]} (${props.value["id"]})`}
				</Link>
				{` (${props.value["arrive_time"]} - ${props.value["complete_time"]})`}
			</span>
		)
	},{
		field: 'dropoff',
		headerName: 'Dropoff',
		width: 260,
		editable: false,
		sortable: false,
		renderCell: (props) => (<span>
				<Link
					href={`https://maps.google.com/?q=${props.value["lat"]},${props.value["lng"]}`}
					target="_blank"
				>
					{`${Number(props.value["lat"]).toFixed(5)},${Number(props.value["lng"]).toFixed(5)}`}
				</Link>
				{` (${props.value["arrive_time"]} - ${props.value["complete_time"]})`}
			</span>
		)
	},{
		field: 'return',
		headerName: 'Return',
		width: 220,
		editable: false,
		sortable: false,
		renderCell: (props) => (!props.value["exists"]? <span>-</span> : <span>
				<Link
					href={`https://maps.google.com/?q=${props.value["lat"]},${props.value["lng"]}`}
					target="_blank"
				>
					{`${Number(props.value["lat"]).toFixed(5)},${Number(props.value["lng"]).toFixed(5)}`}
				</Link>
				{` (${props.value["time"]})`}
			</span>
		)
	},{
		field: 'distance_pickup_to_dropoff',
		headerName: 'Pickup to Dropoff',
		type: 'number',
		width: 125,
		editable: false,
	},{
		field: 'distance_base_to_pickup',
		headerName: 'Base to Pickup',
		type: 'number',
		width: 125,
		editable: false,
	},{
		field: 'distance_dropoff_to_base',
		headerName: 'Base to Dropoff',
		type: 'number',
		width: 125,
		editable: false,
	}];
	if (configset["UseHotZones"])
		robotJourneyColumns = robotJourneyColumns.concat([{
			field: 'hotzone',
			headerName: 'Hotzone',
			type: 'number',
			width: 70,
			editable: false,
		},{
			field: 'distance_hotzone_to_pickup',
			headerName: 'Hotzone to Pickup',
			type: 'number',
			width: 150,
			editable: false,
		},{
			field: 'distance_dropoff_to_hotzone',
			headerName: 'Hotzone to Dropoff',
			type: 'number',
			width: 150,
			editable: false,
		}]);

	const handleChange = (event) => {
    setRobotIds(event.target.value);
  };

	function getTime(ts, tz) {
		const dt = ts? new Date((ts + tz*3600)*1000) : null;
		return dt? `${String(dt.getUTCHours()).padStart(2, '0')}:${String(dt.getUTCMinutes()).padStart(2, '0')}` : "";
	}

	const robotJourneyRows = robotIds.map(robotIdx => robots[robotIdx].journey.map(j => ({
		"id": j["job_id"],
		"robot_index": robotIdx + 1,
		"job_index": j["job_no"] + 1,
		"time": getTime(robots[robotIdx].jobs[j["job_no"]]["timestamp"], robots[robotIdx].jobs[j["job_no"]]["timezone"]),
		"pickup": {
			"name": robots[robotIdx].jobs[j["job_no"]]["pickup_name"],
			"id": robots[robotIdx].jobs[j["job_no"]]["pickup_id"],
			"lat": j["pickup"][0],
			"lng": j["pickup"][1],
			"arrive_time": getTime(j["arrived_pickup_ts"], robots[robotIdx].jobs[j["job_no"]]["timezone"]),
			"complete_time": getTime(j["completed_pickup_ts"], robots[robotIdx].jobs[j["job_no"]]["timezone"]),
		},
		"dropoff": {
			"lat": j["dropoff"][0],
			"lng": j["dropoff"][1],
			"arrive_time": getTime(j["arrived_dropoff_ts"], robots[robotIdx].jobs[j["job_no"]]["timezone"]),
			"complete_time": getTime(j["completed_dropoff_ts"], robots[robotIdx].jobs[j["job_no"]]["timezone"]),
		},
		"return": {
			"exists": j["has_return_leg"],
			"lat": j["return"][0],
			"lng": j["return"][1],
			"time": getTime(j["completed_return_ts"], robots[robotIdx].jobs[j["job_no"]]["timezone"]),
		},
		"distance_pickup_to_dropoff": (Number(robots[robotIdx].jobs[j["job_no"]]["distance_pickup_to_dropoff_km"]) * 0.621371).toPrecision(3) + " mi",
		"distance_base_to_pickup": (Number(robots[robotIdx].jobs[j["job_no"]]["distance_base_to_pickup_km"]) * 0.621371).toPrecision(3) + " mi",
		"distance_dropoff_to_base": (Number(robots[robotIdx].jobs[j["job_no"]]["distance_dropoff_to_base_km"]) * 0.621371).toPrecision(3) + " mi",
		"hotzone": robots[robotIdx].jobs[j["job_no"]]["hotzone_index"] + 1,
		"distance_hotzone_to_pickup": (Number(robots[robotIdx].jobs[j["job_no"]]["distance_hotzone_to_pickup_km"]) * 0.621371).toPrecision(3) + " mi",
		"distance_dropoff_to_hotzone": (Number(robots[robotIdx].jobs[j["job_no"]]["distance_dropoff_to_hotzone_km"]) * 0.621371).toPrecision(3) + " mi",
	})));

	function renderRobotsList() {

		return (
			<FormControl sx={{ width: 300, textAlign: "left" }} >
        <InputLabel id="robot-selector-label">Robot</InputLabel>
        <Select
          labelId="robot-selector-label"
					id="robot-selector"
          multiple
          value={robotIds}
          onChange={handleChange}
					renderValue={value => value.map(idx => `Robot ${idx+1}`).join(", ")}
          input={<OutlinedInput label="Name" />}
          MenuProps={MenuProps}
        >
          {robots.map((robot, idx) => (
            <MenuItem
              key={idx}
              value={idx}
              style={getStyles(idx, robotIds, theme)}
            >
              {`Robot ${idx+1}` +
								(robots[idx]["hotzone"] && robots[idx]["hotzone"].length? ` (${robots[idx]["hotzone"]})` : "") +
								`: ${robot.jobs.length} jobs`
							}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
		)
	}

	function renderJourneyGrid() {
		return (
			<div style={{width: '100%', height: '320px'}}>
				<DataGrid
					rows={robotJourneyRows.flat()}
					columns={robotJourneyColumns}
					disableSelectionOnClick
					density={"compact"}
				/>
			</div>
		);
	}


	function renderJourneyMap() {
		return (
			<div style={{width: '100%', height: '320px'}}>
				<Map
					enableMarkerDrag={false}
					configset={configset}
					heatmap={heatmap}
					robots={Object.assign({}, ...robotIds.map((idx) => ({[idx]: robots[idx]})))}
				/>
			</div>
		);
	}

	return (<Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", flexDirection: "column"}}>
		<Card>
			<CardContent>
				<Stack spacing={2}>
					<Typography sx={{fontSize: 14}} color="text.secondary" align="left">Robot Details</Typography>

					{renderRobotsList()}

					<Grid container>
						<Grid item xs={12} sm={12} md={12} lg={6} sx={{p: 1}}>
							{renderJourneyMap()}
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6} sx={{p: 1}}>
							{renderJourneyGrid()}
						</Grid>
					</Grid>
				</Stack>
			</CardContent>
		</Card>

	</Grid>);
}