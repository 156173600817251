import * as React from "react";
import {useState, useEffect} from "react";
import axios from "axios";
import {
	urlApiConfigMeta,
	urlApiConfigFile,
	urlApiConfig,
	urlApiDataIndexMeta,
	distanceConvMult
} from "../../constants";
import {Alert, Checkbox, FormControlLabel, Grid, LinearProgress, MenuItem, Select} from "@mui/material";
import Button from "@mui/material/Button";
import {BoolInput, DecimalInput, IntegerInput, PercentInput, SetInput} from "./ConfigInput";
import Box from "@mui/material/Box";
import ConfigColumn from "./ConfigColumn";
import {FleetLocationDialog} from "./FleetLocationDialog";
import {SaveDialog} from "./SaveDialog";
import {MerchantDialog} from "./MerchantDialog";
import {BatchSimulationDialog} from "../../Batching/BatchSimulationDialog";


const ConfigurationPage = React.memo(({
										  configsetId, datasetId, userInfo, onRunBatch, onConfigsetIdChange,
										  batchOpen, batchParamsEncoded, onBatchOpenChange,
										  onBatchParamChange, onDone}) => {
	const [isLoading, setLoading] = useState(true);
	const [isFailed, setFailed] = useState(false);
	const [configset, setConfigset] = useState(null);
	const [configsetMeta, setConfigsetMeta] = useState(null);
	const [configsetChanged, setConfigsetChanged] = useState(false);
	const [datasetMeta, setDatasetMeta] = useState(null);
	const [showAdvanced, setShowAdvanced] = useState(false);
	const [isLocationDialogOpen, setIsLocationDialogOpen] = useState(false);
	const [isMerchantDialogOpen, setIsMerchantDialogOpen] = useState(false);
	const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);
	const [isPosting, setIsPosting] = useState(false);


	useEffect(() => {
		setConfigset(null);
		setConfigsetMeta(null);
		setDatasetMeta(null);
		setFailed(false);
		setConfigsetChanged(false);
		setIsSaveDialogOpen(false);
		setIsLocationDialogOpen(false);
		setIsMerchantDialogOpen(false);

		if (!configsetId || configsetId === "")
			return;
		loadConfigset();
		loadConfigsetIndex();
	}, [configsetId]);

	useEffect(() => {
		setDatasetMeta(null);
		loadDatasetIndex();
	}, [datasetId]);

	function handleKeyValueChange(value, key) {
		if (configset[key] !== value) {
			setConfigset(cur => ({...cur, [key]: value}));
			setConfigsetChanged(true);
		}
	}

	function handleNestedKeyValueChange(value, keyParent, keyChild) {
		if (configset[keyParent][keyChild] !== value) {
			setConfigset(cur => ({...cur, [keyParent]: {...cur[keyParent], [keyChild]: value}}));
			setConfigsetChanged(true);
		}
	}

	function loadConfigset() {
		setLoading(true);
		axios.get(urlApiConfigFile(configsetId)).then(response => {
			console.log("loadConfigset", response)
			setLoading(false);
			setConfigset(response.data);
		}).catch(error => {
			setLoading(false);
			setFailed(true);
			console.log(error);
		})
	}

	function loadConfigsetIndex() {
		axios.get(urlApiConfigMeta(configsetId)).then(response => {
			setConfigsetMeta(response.data);
		}).catch(error => {
			setFailed(true);
			console.log(error);
		})
	}

	function loadDatasetIndex() {
		axios.get(urlApiDataIndexMeta(datasetId)).then(response => {
			setDatasetMeta(response.data);
		}).catch(error => {
			setFailed(true);
			console.log(error);
		})
	}

	function saveConfigset(description, isPrivate, overwrite) {
		const axiosFunc = overwrite? axios.put : axios.post;
		let  url = overwrite? urlApiConfigFile(configsetId) : urlApiConfig;

		const params = new URLSearchParams();
		if (description != null)
			params.set("desc", description);
		if (isPrivate != null)
			params.set("private", isPrivate);
		url = url + "?" + params.toString()

		setIsPosting(true);
		setIsSaveDialogOpen(false);

		axiosFunc(url, configset).then(response => {
      console.log(response.data);
			setIsPosting(false);
			setConfigsetChanged(false);

			if (!overwrite)
				onConfigsetIdChange(response.data.id);
		}).catch(error => {
			setIsPosting(false);
			console.log(error);
		})
	}

	if (isLoading)
		return <LinearProgress />

  if (isFailed)
    return <Alert severity="error" sx={{margin: 2}}>Failed to load configuration data</Alert>

	if (configset == null)
		return <Alert severity="info" sx={{margin: 2}}>Please select a valid scenario file</Alert>;

	function renderFleetConfigColumn() {
		if (!configset)
			return null;

		return <ConfigColumn
			title="Fleet"
			inputs={[
				<IntegerInput
					key="robot-count" disabled={isPosting}
					label="Robot Count" min={0} max={1000} value={configset["RobotCount"]["value"]}
					onChange={value => {handleNestedKeyValueChange(value, "RobotCount", "value")}}
				/>,
				<Box
					key="operating-hours" component="form" noValidate autoComplete="off"
					sx={{display: "flex", flexDirection: "row"}}
				>
					<IntegerInput
						label="Start Hour" disabled={isPosting}
						min={0} max={configset["OperatingHours"][1]} value={configset["OperatingHours"][0]}
						onChange={value => {
							handleKeyValueChange([value, configset["OperatingHours"][1]], "OperatingHours");
						}}
					/>
					<IntegerInput
						label="End Hour" disabled={isPosting}
						min={configset["OperatingHours"][0]} max={23} value={configset["OperatingHours"][1]}
						onChange={value => {
							handleKeyValueChange([configset["OperatingHours"][0], value], "OperatingHours");
						}}
					/>
				</Box>,
				<Button
					key="fleet-location-button"
					variant="outlined" fullWidth
					onClick={() => {
						setIsLocationDialogOpen(true)
					}}
				>
					Deployment Location
				</Button>,
				<DecimalInput
					key="robot-speed" disabled={isPosting}
					label="Robot Average Speed (mph)" min={1} max={10}
					value={(configset["RobotAvgSpeedKmh"] * distanceConvMult).toFixed(2)}
					hint="Average robot speed (includes stops and waits, e.g. red lights, but not wait times at customer or merchant"
					onChange={value => {handleKeyValueChange(value / distanceConvMult, "RobotAvgSpeedKmh")}}
				/>,
				!showAdvanced ? null : <BoolInput
					key="robot-stay-at-dropoff" disabled={isPosting}
					label="Remain at Dropoff"
					hint="Specify whether robot should remain put at dropoff location upon completing a delivery, rather than head back towards hot zone / base"
					value={configset["StayAtDropoffUponCompletion"]}
					onChange={value => {handleKeyValueChange(value, "StayAtDropoffUponCompletion")}}
				/>,
				!showAdvanced ? null : <SetInput
					key="dispatch-mode" disabled={isPosting}
					label="Dispatch Mode"
					choices={["Full Delivery", "Pickup Only"]}
					hints={["Robot picks up order at merchant and drops off at customer", "Robot picks up order at merchant and hands off to a vehicle that goes on to complete dropoff"]}
					minRequired={1}
					value={[configset["DispatchFullDelivery"], configset["DispatchPickupOnlyDelivery"]]}
					onChange={value => {
						handleKeyValueChange(value[0], "DispatchFullDelivery");
						handleKeyValueChange(value[1], "DispatchPickupOnlyDelivery");
					}}
				/>,
			]}
		/>;
	}

	function renderJobSelectionConfigColumn() {
		if (!configset)
			return null;

		return <ConfigColumn
			title="Job Selection"
			inputs={[
				<PercentInput
					key="funnel-leak" disabled={isPosting}
					label="Job Funnel Leak" min={0} max={99} value={configset["JobFunnelLeak"]}
					hint="The specified percentage of jobs are randomly rejected"
					onChange={value => {handleKeyValueChange(value, "JobFunnelLeak")}}
				/>,
				<DecimalInput
					key="full-delivery-max-pickup-km" disabled={isPosting}
					label="Max Pickup Radius (mi)" min={0} max={10} value={(configset["FullDelivery/MaxPickupKm"]["value"] * distanceConvMult).toFixed(2)}
					onChange={value => {handleNestedKeyValueChange(value / distanceConvMult, "FullDelivery/MaxPickupKm", "value")}}
				/>,
				<DecimalInput
					key="full-delivery-max-delivery-km" disabled={isPosting}
					label="Max Delivery Radius (mi)" min={0} max={10} value={(configset["FullDelivery/MaxDeliveryKm"]["value"] * distanceConvMult).toFixed(2)}
					hint="Maximum pickup & dropoff distance"
					onChange={value => {handleNestedKeyValueChange(value / distanceConvMult, "FullDelivery/MaxDeliveryKm", "value")}}
				/>,
				datasetMeta && !datasetMeta["deadline"] ? null : <BoolInput
					key="filter-by-lab-deadline" disabled={isPosting}
					label="Enforce LAB Deadline"
					hint="If delivery deadline (Latest Arrival By, aka LAB) is provided, it would be enforced to filter out jobs that can't be completed within a given margin of the deadline"
					value={configset["FilterByDeadline"]}
					onChange={value => {handleKeyValueChange(value, "FilterByDeadline")}}
				/>,
				(datasetMeta && !datasetMeta["deadline"]) || ("FilterByDeadline" in configset && !configset["FilterByDeadline"]) ? null : <DecimalInput
					key="filter-by-lab-deadline-margin" disabled={isPosting}
					label="LAB Deadline Margin (mins before deadline)" min={-30} max={30}
					hint="How much margin to leave with LAB deadline (negative means job can end after deadline)"
					value={(configset["FilterByDeadlineMarginSec"]/60).toFixed(2)}
					onChange={value => {handleKeyValueChange(value * 60, "FilterByDeadlineMarginSec")}}
				/>,
				!showAdvanced ? null : <DecimalInput
					key="full-delivery-max-dropoff-to-base-km" disabled={isPosting}
					label="Max Dropoff to Base Distance (mi)" min={0} max={25} value={(configset["FullDelivery/MaxDropoffToBaseKm"]["value"] * distanceConvMult).toFixed(2)}
					hint="Keeping a limit on dropoff distance from base makes sure robots don't wonder off too far"
					onChange={value => {handleNestedKeyValueChange(value / distanceConvMult, "FullDelivery/MaxDropoffToBaseKm", "value")}}
				/>,
				!showAdvanced ? null : <DecimalInput
					key="filter-base-to-merchant" disabled={isPosting}
					label="Max Merchant to Base Distance (mi)" min={0} max={25} value={(configset["FilterByBaseToMerchantDistanceKm"] * distanceConvMult).toFixed(2)}
					onChange={value => {handleKeyValueChange(value / distanceConvMult, "FilterByBaseToMerchantDistanceKm")}}
				/>,
				!showAdvanced ? null : <DecimalInput
					key="filter-hotzone-to-merchant" disabled={isPosting}
					label="Max Merchant to Hot Zone Distance (mi)" min={0} max={25} value={(configset["FilterByHotzoneToMerchantDistanceKm"] * distanceConvMult).toFixed(2)}
					onChange={value => {handleKeyValueChange(value / distanceConvMult, "FilterByHotzoneToMerchantDistanceKm")}}
				/>,
				<Button
					key="merchant-button"
					variant="outlined" fullWidth
					disabled={!datasetMeta}
					onClick={() => {
						setIsMerchantDialogOpen(true)
					}}
				>
					{configset["MerchantsWhitelist"] && configset["MerchantsWhitelist"].length>0? `Merchants Selected: ${configset["MerchantsWhitelist"].length}` :
						configset["MerchantsBlacklist"] && configset["MerchantsBlacklist"].length>0? `Merchants Excluded: ${configset["MerchantsBlacklist"].length}` :
							"Merchant Selection"
					}
				</Button>,
			]}
		/>;
	}

	const getFilterValue = (filterKey, valueKey, defaultValue) => {
		if (!("CustomFilters" in configset))
			return defaultValue;
		if (!(filterKey in configset["CustomFilters"]))
			return defaultValue;
		if (!(valueKey in configset["CustomFilters"][filterKey]))
			return defaultValue;
		return configset["CustomFilters"][filterKey][valueKey];
	}

	function handleCustomFilterChange(filterKey, updateKey, updateValue) {
		const filters = "CustomFilters" in configset? configset["CustomFilters"] : {};
		const filter = filters[filterKey];
		handleKeyValueChange({...filters, [filterKey]: {...filter, [updateKey]: updateValue}}, "CustomFilters");
	}

	function renderCustomDataFilters() {
		if (!datasetMeta)
			return null;

		const customFields = "custom_fields" in datasetMeta? datasetMeta["custom_fields"] : null;
		if (!customFields || Object.keys(customFields).length === 0)
			return null;

		return <ConfigColumn
			title="Custom Data Filters"
			inputs={Object.keys(customFields).map((key, idx) => {
				if (customFields[key] === "BoolType")
					return (
						<Grid container key={idx}>
							<FormControlLabel
								control={<Checkbox
									checked={getFilterValue(key, "enabled", false)}
									onChange={(e) => {handleCustomFilterChange(key, "enabled", e.target.checked)}}
								/>}
								label={key}
							/>
							<Select label={key}
											fullWidth
											disabled={!	getFilterValue(key, "enabled", false)}
											value={getFilterValue(key, "value", "")}
											onChange={(e) => {handleCustomFilterChange(key, "value", e.target.value)}}
							>
								<MenuItem value={0}>False</MenuItem>
								<MenuItem value={1}>True</MenuItem>
							</Select>
						</Grid>
					)
			})}
		/>;
	}

	function renderTimingConfigColumn() {
		return <ConfigColumn
			title="Timing"
			inputs={[
				!showAdvanced? null : <DecimalInput
					key="pickup-wait" disabled={isPosting}
					label="Pickup Wait (mins)" min={0} max={60} value={configset["AvgPickupWaitSec"] / 60}
					hint="Average delay at pickup while waiting for merchant to load"
					onChange={value => {handleKeyValueChange(value * 60, "AvgPickupWaitSec")}}
				/>,
				!showAdvanced? null : <DecimalInput
					key="dropoff-wait" disabled={isPosting}
					label="Dropoff Wait (mins)" min={0} max={60} value={configset["AvgDropoffWaitSec"] / 60}
					hint="Average delay at dropoff while waiting for customer to unload"
					onChange={value => {handleKeyValueChange(value * 60, "AvgDropoffWaitSec")}}
				/>,
				!showAdvanced? null : <DecimalInput
					key="min-sim-increment" disabled={isPosting}
					label="Sim Time Increment (min)" min={1} max={12} value={configset["MinSimTimeIncrementSecs"]? configset["MinSimTimeIncrementSecs"] / 60 : 2}
					hint="The simulation runs at a given time interval. It can impact how long jobs remain unmatched."
					onChange={value => {handleKeyValueChange(value * 60, "MinSimTimeIncrementSecs")}}
				/>,

			]}
		/>;
	}

	function renderPickupOnlyConfigColumn() {
		return <ConfigColumn
			title="Pickup Only Dispatch Mode"
			inputs={[
				<DecimalInput
					key="pickup-only-max-pickup-km" disabled={isPosting}
					label="Max Pickup Radius (mi)" min={0} max={20} value={(configset["PickupOnlyDelivery/MaxPickupKm"]["value"] * distanceConvMult).toFixed(2)}
					onChange={value => {handleNestedKeyValueChange(value / distanceConvMult, "PickupOnlyDelivery/MaxPickupKm", "value")}}
				/>,
				<DecimalInput
					key="pickup-only-max-dropoff-km" disabled={isPosting}
					label="Max Dropoff Radius" min={0} max={20} value={(configset["PickupOnlyDelivery/MaxDropoffKm"]["value"] * distanceConvMult).toFixed(2)}
					onChange={value => {handleNestedKeyValueChange(value / distanceConvMult, "PickupOnlyDelivery/MaxDropoffKm", "value")}}
				/>,
				<DecimalInput
					key="pickup-only-batching-period" disabled={isPosting}
					label="Batching Period (mins)" min={0} max={120} value={configset["PickupOnlyDelivery/BatchingPeriodSec"] / 60}
					hint="[Pickup Only Dispatch] How long to wait for additional jobs to arrive at handoff to batch into a single vehicle"
					onChange={value => {handleKeyValueChange(value * 60, "PickupOnlyDelivery/BatchingPeriodSec")}}
				/>,
				<IntegerInput
					key="pickup-only-max-jobs-in-batch" disabled={isPosting}
					label="Max Jobs Per Batch" min={0} max={10} value={configset["PickupOnlyDelivery/MaxJobsInBatch"]}
					hint="[Pickup Only Dispatch] Max dropoffs batched into a single vehicle trip"
					onChange={value => {handleKeyValueChange(value, "PickupOnlyDelivery/MaxJobsInBatch")}}
				/>,
				<DecimalInput
					key="pickup-only-max-batch-distance-increase-km" disabled={isPosting}
					label="Max Batch Distance Increase (mi)" min={0} max={60} value={(configset["PickupOnlyDelivery/MaxDeliveryDistanceIncreaseWhenBatchingKm"] * distanceConvMult).toFixed(2)}
					hint="[Pickup Only Dispatch] Max permitted distance increase because of batching"
					onChange={value => {handleKeyValueChange(value / distanceConvMult, "PickupOnlyDelivery/MaxDeliveryDistanceIncreaseWhenBatchingKm")}}
				/>,
				<PercentInput
					key="pickup-only-max-batch-distance-increase-percent" disabled={isPosting}
					label="Max Batch Distance Increase (%)" min={0} max={200} value={configset["PickupOnlyDelivery/MaxDeliveryDistanceIncreaseWhenBatchingPercent"]}
					hint="[Pickup Only Dispatch] Max permitted % distance increase because of batching"
					onChange={value => {handleKeyValueChange(value, "PickupOnlyDelivery/MaxDeliveryDistanceIncreaseWhenBatchingPercent")}}
				/>,
				<DecimalInput
					key="pickup-only-handoff-wait" disabled={isPosting}
					label="Average Handoff Wait (mins)" min={0} max={60} value={configset["PickupOnlyDelivery/AvgHandoffWaitSec"] / 60}
					hint="[Pickup Only Dispatch] Average wait time at handoff location for order to be unloaded from robot"
					onChange={value => {handleKeyValueChange(value * 60, "PickupOnlyDelivery/AvgHandoffWaitSec")}}
				/>,
				<DecimalInput
					key="pickup-only-dropoff-vehicle-dropoff-wait" disabled={isPosting}
					label="Average Vehicle Dropoff Wait (mins)" min={0} max={60} value={configset["PickupOnlyDelivery/AvgVehicleDropoffWaitSec"] / 60}
					hint="[Pickup Only Dispatch] Average time to complete dropoff when vehicle arrives at customer location"
					onChange={value => {handleKeyValueChange(value * 60, "PickupOnlyDelivery/AvgVehicleDropoffWaitSec")}}
				/>,
				<DecimalInput
					key="pickup-only-dropoff-vehicle-speed" disabled={isPosting}
					label="Average Vehicle Speed (mph)" min={0} max={50} value={(configset["PickupOnlyDelivery/VehicleAvgSpeedKmh"] * distanceConvMult).toFixed(2)}
					hint="[Pickup Only Dispatch] Average speed of dropoff vehicle (includes traffic stops but not wait time at customer location)"
					onChange={value => {handleKeyValueChange(value / distanceConvMult, "PickupOnlyDelivery/VehicleAvgSpeedKmh")}}
				/>,
			]}
		/>;
	}

	return (<div>
			<Grid container sx={{padding: 2}} spacing={2}>

				{renderFleetConfigColumn()}
				{renderJobSelectionConfigColumn()}
				{renderCustomDataFilters()}
				{!showAdvanced? null : renderTimingConfigColumn()}
				{!showAdvanced || !configset["DispatchPickupOnlyDelivery"]? null : renderPickupOnlyConfigColumn()}

			</Grid>

			{!isPosting? <div style={{height: "20px", width: "100%"}}/> : <LinearProgress sx={{m: 1}}/>}

			<Grid container justifyContent="flex-end">
				<Button sx={{marginRight: 1}} disabled={configsetId === '' || isPosting} onClick={() => {setShowAdvanced(!showAdvanced)}}>
					{showAdvanced ? "Hide" : "Show"} Advance Options
				</Button>
				{!configsetMeta || !configsetMeta["write"] ? null :
					<Button sx={{marginRight: 1}} variant="contained" disabled={!configsetChanged || isPosting} onClick={() => {saveConfigset(null, null, true)}}>
						Save
					</Button>
				}
				<Button sx={{marginRight: 1}} variant="contained" disabled={!configsetChanged || isPosting} onClick={() => {setIsSaveDialogOpen(true)}}>
					Save As
				</Button>
				<Button sx={{marginRight: 1}} variant="outlined"
						disabled={configsetId === '' || datasetId === '' || isPosting || configsetChanged}
						onClick={() => {
							onBatchOpenChange(true);
						}}
				>
					Batch
				</Button>
				<Button variant="contained"
						disabled={configsetId === '' || datasetId === '' || isPosting || configsetChanged}
						onClick={() => {
							setIsPosting(true);
							const callback = () => {setIsPosting(true)}
							onDone(callback, callback);
						}}
				>
					Run Simulation
				</Button>
			</Grid>

			<BatchSimulationDialog
				configset={configset}
				open={batchOpen}
				batchParamsEncoded={batchParamsEncoded}
				onBatchParamChange={onBatchParamChange}
				onRunBatch={onRunBatch}
				onClose={() => {
					onBatchOpenChange(false);
				}}
			/>

			<FleetLocationDialog
				datasetId={datasetId}
				configset={configset}
				merchants={datasetMeta && "merchants" in datasetMeta? datasetMeta["merchants"]: null}
				onChange={handleKeyValueChange}
				open={isLocationDialogOpen}
				onClose={() => {setIsLocationDialogOpen(false)}}
			/>

			{!configset || !datasetMeta || !("merchants" in datasetMeta) ? null :
				<MerchantDialog
					datasetId={datasetId}
					configset={configset}
					merchants={datasetMeta["merchants"]}
					onChange={handleKeyValueChange}
					open={isMerchantDialogOpen}
					onClose={() => {setIsMerchantDialogOpen(false)}}
				/>
			}

			<SaveDialog
				open={isSaveDialogOpen}
				initDescription={configsetMeta? configsetMeta["desc"] : ""}
				initPrivate={configsetMeta && configsetMeta["private"] === true}
				overwritePermitted={configsetMeta && configsetMeta["write"]}
				onSave={(description, isPrivate) => {
					saveConfigset(description, isPrivate, false);
				}}
				onClose={() => {setIsSaveDialogOpen(false)}}
			/>
		</div>
	)
});

export default ConfigurationPage;