let TILE_SIZE = 256;

//Mercator --BEGIN--
function bound(value, opt_min, opt_max) {
	if (opt_min !== null) value = Math.max(value, opt_min);
	if (opt_max !== null) value = Math.min(value, opt_max);
	return value;
}

function degreesToRadians(deg) {
	return deg * (Math.PI / 180);
}

function radiansToDegrees(rad) {
	return rad / (Math.PI / 180);
}

function MercatorProjection() {
	this.pixelOrigin_ = new window.google.maps.Point(TILE_SIZE / 2,
	TILE_SIZE / 2);
	this.pixelsPerLonDegree_ = TILE_SIZE / 360;
	this.pixelsPerLonRadian_ = TILE_SIZE / (2 * Math.PI);
}

MercatorProjection.prototype.fromLatLngToPoint = function (latLng, opt_point) {
	let me = this;
	let point = opt_point || new window.google.maps.Point(0, 0);
	let origin = me.pixelOrigin_;

	point.x = origin.x + latLng.lng() * me.pixelsPerLonDegree_;

	// NOTE(appleton): Truncating to 0.9999 effectively limits latitude to
	// 89.189.  This is about a third of a tile past the edge of the world
	// tile.
	let siny = bound(Math.sin(degreesToRadians(latLng.lat())), - 0.9999,
	0.9999);
	point.y = origin.y + 0.5 * Math.log((1 + siny) / (1 - siny)) * -me.pixelsPerLonRadian_;
	return point;
};

MercatorProjection.prototype.fromPointToLatLng = function (point) {
		let me = this;
		let origin = me.pixelOrigin_;
		let lng = (point.x - origin.x) / me.pixelsPerLonDegree_;
		let latRadians = (point.y - origin.y) / -me.pixelsPerLonRadian_;
		let lat = radiansToDegrees(2 * Math.atan(Math.exp(latRadians)) - Math.PI / 2);
		return new window.google.maps.LatLng(lat, lng);
};

//Mercator --END--

export function getNewRadius(map, zoomLevel, desiredRadiusPerPointInKm) {
	let numTiles = 1 << zoomLevel;
	let center = map.getCenter();
	let moved = window.google.maps.geometry.spherical.computeOffset(center, 10000, 90); /*1000 meters to the right*/
	let projection = new MercatorProjection();
	let initCoord = projection.fromLatLngToPoint(center);
	let endCoord = projection.fromLatLngToPoint(moved);
	let initPoint = new window.google.maps.Point(initCoord.x * numTiles, initCoord.y * numTiles);
	let endPoint = new window.google.maps.Point(endCoord.x * numTiles, endCoord.y * numTiles);
	let pixelsPerMeter = (Math.abs(initPoint.x - endPoint.x))/10000.0;
	let totalPixelSize = Math.floor(desiredRadiusPerPointInKm * 1000 * pixelsPerMeter);
	return totalPixelSize;
}
