import Typography from "@mui/material/Typography";
import * as React from "react";
import {Card, CardContent, Grid, Stack} from "@mui/material";

export default function ConfigColumn({title, inputs}) {

	return (
		<Grid item xs={12} sm={12} md={6} lg={4} textAlign="start">
			<Card>
				<CardContent>
					<Typography sx={{ fontSize: 14, marginBottom: 2 }} color="text.secondary" align="left">{title}</Typography>
					<Stack spacing={2}>
						{inputs.filter(i => i != null)}
					</Stack>
				</CardContent>
			</Card>
		</Grid>
	);
}