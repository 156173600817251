import Link from "@mui/material/Link";
import {CircularProgress} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import * as React from "react";

const appTitle = "Serve Polaris";

const googleMapsKey = "AIzaSyAmytFDQ8SWq47DKuqqD2I_tFJK8pLY_7A"

const urlHome = (datasetId, configsetId) => `/ds/${datasetId}/cf/${configsetId}`;  // do not include host address since window.pushHistory will reject it
const urlHomeBatchDialog = (datasetId, configsetId, encodedParamList) => `/ds/${datasetId}/cf/${configsetId}/batch/${encodedParamList}`;  // do not include host address since window.pushHistory will reject it
const urlDeployment = (resultId) => `/deployment/${resultId}`;  // do not include host address since window.pushHistory will reject it
const urlBatchResult = (batchId) => `/batch-result/${batchId}`;  // do not include host address since window.pushHistory will reject it
const urlCalculator = (batchId) => `/calculator/${batchId}`;  // do not include host address since window.pushHistory will reject it
const urlLogs = `/logs`
const urlUsersRecentActivity = "/users-recent"

const host = ((window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")? "http://localhost:5000" : "");
const urlAuthLogin = host + "/a/login";
const urlAuthLogout = host + "/a/logout";


const urlApi = `${host}/api`
const urlApiUser = `${urlApi}/user`
const urlRefreshAccessControl = `${urlApiUser}/refresh_access`;
const urlApiData = urlApi + "/data";
const urlApiDataIndexFile =  `${urlApi}/data/index`;
const urlApiDataIndexMeta = (id) => `${urlApi}/data/index/${id}`;
const urlApiDataFile = (id) => `${urlApi}/data/${id}`;
const urlApiDataHeatmapFromConfigsetId = (datasetId, configsetId) => `${urlApi}/data/heatmap/${datasetId}/${configsetId}`;
const urlApiDataHeatmapFromConfigset = (datasetId) => `${urlApi}/data/heatmap/${datasetId}`;
const urlApiConfig = `${urlApi}/config`;
const urlApiConfigMeta = (id) => `${urlApi}/config/index/${id}`;
const urlApiConfigFile = (id) => `${urlApi}/config/${id}`;
const urlApiSimulation = (datasetId, configsetId) => `${urlApi}/simulation/ds/${datasetId}/cf/${configsetId}`;
const urlApiSimulationResultData = (resultId) => `${urlApi}/simulation/log/${resultId}/data.json`;
const urlApiBatchSimulation  = `${urlApi}/batch_simulation`;
const urlApiBatchResultsIndex = `${urlApiBatchSimulation}/index`;
const urlApiBatchResultsIndexItem = (batchId) => `${urlApiBatchSimulation}/index/${batchId}`;
const urlApiBatchResultsData  = (batchId, pageNo) => `${urlApi}/batch_simulation/res/${batchId}/${pageNo}`;
const urlApiBatchCSVFile  = (batchId) => `${urlApi}/batch_simulation/csv/${batchId}`;
const urlApiBatchConfigFile  = (batchId) => `${urlApi}/batch_simulation/config/${batchId}`;
const urlApiBatchModelFile  = (batchId) => `${urlApi}/batch_simulation/model/${batchId}`;
const urlApiBatchListFiles  = (batchId) => `${urlApi}/batch_simulation/${batchId}`;

const urlApiSimulationRobotData = (resultId) => `${urlApi}/simulation/log/${resultId}/robots.json`;
const urlApiLogs = `${urlApi}/logs`;
const urlApiUsersRecentActivity = `${urlApi}/logs/users`;
const urlApiLogsResponse = (recordId) => `${urlApi}/logs/response/${recordId}`;

const convertToMiles = true;
const distanceConvMult = convertToMiles? 0.621371 : 1;

const configParamsMetaInfo = {
	'RobotCount': {field: "config:robots", title: "Robots", mult: 1, min: 0, max: 3000, step: 10},
	'RobotAvgSpeedKmh': {field: "config:robotSpeed", title: `Speed ${convertToMiles? '(mph)' : '(km/h)'}`, mult: distanceConvMult, min: 0.5/distanceConvMult, max: 50/distanceConvMult, step: 0.1/distanceConvMult},
	'AvgPickupWaitSec': {field: "config:pickupWait", title: "Pickup Wait Mins", mult: 1/60, min: 0, max: 3600, step: 30},
	'AvgDropoffWaitSec': {field: "config:dropoffWait", title: "Dropoff Wait Mins", mult: 1/60, min: 0, max: 3600, step: 30},
	'FullDelivery/MaxPickupKm': {field: "config:maxPickupDist", title: `Max Pickup ${convertToMiles? '(mi)' : '(km)'}`, mult: distanceConvMult, min: 0, max: 20/distanceConvMult, step: 0.1/distanceConvMult},
	'FullDelivery/MaxDeliveryKm': {field: "config:maxDeliveryDist", title: `Max Delivery ${convertToMiles? '(mi)' : '(km)'}`, mult: distanceConvMult, min: 0, max: 20/distanceConvMult, step: 0.1/distanceConvMult},
	'FullDelivery/MaxDropoffToBaseKm': {field: "config:maxDropoffToBaseDist", title: `Max Dropoff to Base ${convertToMiles? '(mi)' : '(km)'}`, mult: distanceConvMult, min: 0, max: 50/distanceConvMult, step: 0.1/distanceConvMult},
	'FilterByHotzoneToMerchantDistanceKm': {field: "config:filterMerchantHotzoneDist", title: `Max Hotzone to Merchant ${convertToMiles? '(mi)' : '(km)'}`, mult: distanceConvMult, min: 0, max: 50/distanceConvMult, step: 0.5/distanceConvMult},
	'FilterByBaseToMerchantDistanceKm': {field: "config:filterMerchantToBaseDist", title: `Max Base to Merchant ${convertToMiles? '(mi)' : '(km)'}`, mult: distanceConvMult, min: 0, max: 50/distanceConvMult, step: 0.5/distanceConvMult},
	'FilterByDeadlineMarginSec': {field: "config:filterDeadlineMargin", title: "LAB Margin (min)", mult: 1/60, min: -1800, max: 1800, step: 30},
	'JobFunnelLeak': {field: "config:funnelLeak", title: "Job Funnel Leak %", mult: 100, min: 0, max: 1, step: 0.01},
	// 'PickupOnlyDelivery/AvgHandoffWaitSec': {field: "", title: "Pickups—Handoff (min)", mult: 1/60, min: 0, max: 3600, step: 30},
	// 'PickupOnlyDelivery/AvgVehicleDropoffWaitSec': {field: "", title: "Pickups—Dropoff Vehicle Wait (min)", mult: 1/60, min: 0, max: 3600, step: 30},
	// 'PickupOnlyDelivery/BatchingPeriodSec': {field: "", title: "Pickups—Batching Wait (min)", mult: 1/60, min: 30, max: 1800, step: 30},
	// 'PickupOnlyDelivery/MaxDeliveryDistanceIncreaseWhenBatchingKm': {field: "", title: `Pickups—Max Batching Distance Increase ${convertToMiles? '(mi)' : '(km)'}`, mult: distanceConvMult, min: 0, max: 50/distanceConvMult, step: 0.1/distanceConvMult},
	// 'PickupOnlyDelivery/MaxDeliveryDistanceIncreaseWhenBatchingPercent': {field: "", title: "Pickups—Max Batching Distance Increase %", mult: 100, min: 0, max: 1, step: 0.01},
	// 'PickupOnlyDelivery/MaxDropoffKm': {field: "", title: `Pickups—Max Dropoff ${convertToMiles? '(mi)' : '(km)'}`, mult: distanceConvMult, min: 0, max: 50/distanceConvMult, step: 0.1/distanceConvMult},
	// 'PickupOnlyDelivery/MaxJobsInBatch': {field: "", title: "Pickups—Max Batch Jobs", mult: 1, min: 2, max: 20, step: 1},
	// 'PickupOnlyDelivery/MaxPickupKm': {field: "", title: `Pickups—Max Pickup ${convertToMiles? '(mi)' : '(km)'}`, mult: distanceConvMult, min: 0/distanceConvMult, max: 50/distanceConvMult, step: 0.1/distanceConvMult},
	// 'PickupOnlyDelivery/VehicleAvgSpeedKmh': {field: "", title: `Pickups—Dropoff Vehicle Speed ${convertToMiles? '(mph)' : '(km/h)'}`, mult: distanceConvMult, min: 0.5/distanceConvMult, max: 50/distanceConvMult, step: 0.1/distanceConvMult},
};

const configParamsByField = Object.keys(configParamsMetaInfo)
	.map(key => configParamsMetaInfo[key])
	.reduce((accumulator, item) => {
		accumulator[item.field] = item;
		return accumulator;
	}, {});

const renderResultsColumnsCell = (props) => (
	<span style={{
		backgroundColor: (props.colDef.backgroundColor ? props.colDef.backgroundColor : 'transparent'),
		padding: '8px',
		fontWeight: (props.colDef.boldText ? 'bold' : '')
	}}>
		{props.value}
	</span>
);

const batchResultsPrimaryObjectiveColumn = "robotHourlyJobs";

const batchResultsColumns = [{
	field: 'link',
	headerName: 'Link',
	width: 60,
	align: 'center',
	customData: (resultId, configset, _) => (urlDeployment(resultId)),
	renderCell: (props) => (
		<Link href={props.row.link} target="_blank">
			{props.row.loading? <CircularProgress size={24} /> : <LaunchIcon />}
		</Link>
	),
}, {
	field: configParamsMetaInfo['RobotCount'].field,
	headerName: configParamsMetaInfo['RobotCount'].title,
	width: 120,
	sortable: true,
	backgroundColor: '#e6e6e6',
	boldText: true,
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, _) => (!configset? "" : configset["RobotCount"]["value"]),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), configParamsMetaInfo['RobotCount'].min) : Number(cell.value)).
		toFixed(0).
		replace(/\B(?=(\d{3})+(?!\d))/g, ",")), // remove decimal places and add commas
}, {
	field: configParamsMetaInfo['RobotAvgSpeedKmh'].field,
	headerName: configParamsMetaInfo['RobotAvgSpeedKmh'].title,
	width: 120,
	sortable: true,
	backgroundColor: '#e6e6e6',
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, _) => (!configset? "" : configset["RobotAvgSpeedKmh"] * distanceConvMult),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), configParamsMetaInfo['RobotAvgSpeedKmh'].min) : Number(cell.value)).
		toFixed(2)),
}, {
	field: 'config:startHour',
	headerName: 'Start Hour',
	width: 120,
	sortable: true,
	backgroundColor: '#e6e6e6',
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, _) => (!configset? "" : configset["OperatingHours"][0]),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), 0) : Number(cell.value)).
		toFixed(0)),
}, {
	field: 'config:endHour',
	headerName: 'End Hour',
	width: 120,
	sortable: true,
	backgroundColor: '#e6e6e6',
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, _) => (!configset? "" : configset["OperatingHours"][1]),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), 0) : Number(cell.value)).
		toFixed(0)),
}, {
	field: configParamsMetaInfo['FullDelivery/MaxPickupKm'].field,
	headerName: configParamsMetaInfo['FullDelivery/MaxPickupKm'].title,
	width: 140,
	sortable: true,
	backgroundColor: '#e6e6e6',
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, _) => (!configset? "" : configset["FullDelivery/MaxPickupKm"]["value"] * distanceConvMult),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), configParamsMetaInfo['FullDelivery/MaxPickupKm'].min) : Number(cell.value)).
		toFixed(1)),
}, {
	field: configParamsMetaInfo['FullDelivery/MaxDeliveryKm'].field,
	headerName: configParamsMetaInfo['FullDelivery/MaxDeliveryKm'].title,
	width: 140,
	sortable: true,
	backgroundColor: '#e6e6e6',
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, _) => (!configset? "" : configset["FullDelivery/MaxDeliveryKm"]["value"] * distanceConvMult),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), configParamsMetaInfo['FullDelivery/MaxDeliveryKm'].min) : Number(cell.value)).
		toFixed(1)),
}, {
	field: configParamsMetaInfo['FullDelivery/MaxDropoffToBaseKm'].field,
	headerName: configParamsMetaInfo['FullDelivery/MaxDropoffToBaseKm'].title,
	width: 160,
	sortable: true,
	backgroundColor: '#e6e6e6',
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, _) => (!configset? "" : configset["FullDelivery/MaxDropoffToBaseKm"]["value"] * distanceConvMult),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), configParamsMetaInfo['FullDelivery/MaxDropoffToBaseKm'].min) : Number(cell.value)).
		toFixed(1)),
}, {
	field: configParamsMetaInfo['FilterByBaseToMerchantDistanceKm'].field,
	headerName: configParamsMetaInfo['FilterByBaseToMerchantDistanceKm'].title,
	width: 160,
	sortable: true,
	backgroundColor: '#e6e6e6',
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, _) => (!configset? "" : configset["FilterByBaseToMerchantDistanceKm"] * distanceConvMult),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), configParamsMetaInfo['FilterByBaseToMerchantDistanceKm'].min) : Number(cell.value)).
		toFixed(1)),
}, {
	field: configParamsMetaInfo['FilterByHotzoneToMerchantDistanceKm'].field,
	headerName: configParamsMetaInfo['FilterByHotzoneToMerchantDistanceKm'].title,
	width: 160,
	sortable: true,
	backgroundColor: '#e6e6e6',
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, _) => (!configset? "" : configset["FilterByHotzoneToMerchantDistanceKm"] * distanceConvMult),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), configParamsMetaInfo['FilterByHotzoneToMerchantDistanceKm'].min) : Number(cell.value)).
		toFixed(1)),
}, {
	field: configParamsMetaInfo['AvgPickupWaitSec'].field,
	headerName: configParamsMetaInfo['AvgPickupWaitSec'].title,
	width: 140,
	sortable: true,
	backgroundColor: '#e6e6e6',
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, _) => (!configset? "" : configset["AvgPickupWaitSec"] / 60),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), configParamsMetaInfo['AvgPickupWaitSec'].min) : Number(cell.value)).
		toFixed(1)),
}, {
	field: configParamsMetaInfo['AvgDropoffWaitSec'].field,
	headerName: configParamsMetaInfo['AvgDropoffWaitSec'].title,
	width: 140,
	sortable: true,
	backgroundColor: '#e6e6e6',
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, _) => (!configset? "" : configset["AvgDropoffWaitSec"] / 60),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), configParamsMetaInfo['AvgDropoffWaitSec'].min) : Number(cell.value)).
		toFixed(1)),
}, {
	field: configParamsMetaInfo['JobFunnelLeak'].field,
	headerName: configParamsMetaInfo['JobFunnelLeak'].title,
	width: 140,
	sortable: true,
	backgroundColor: '#e6e6e6',
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, _) => (!configset? "" : configset["JobFunnelLeak"] * 100),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.min(Math.max(Number(cell.value), configParamsMetaInfo['JobFunnelLeak'].min * configParamsMetaInfo['JobFunnelLeak'].mult), 100) : Number(cell.value)).
		toFixed(1)),
}, {
	field: 'config:filterDeadline',
	headerName: 'LAB Deadline',
	sortable: true,
	width: 120,
	backgroundColor: '#e6e6e6',
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, _) => (!configset? "" : configset["FilterByDeadline"]),
	valueGetter: (cell, limit=true) => ((cell.value && (cell.value === "true" || Math.round(cell.value) === 1)).toString()),
}, {
	field: configParamsMetaInfo['FilterByDeadlineMarginSec'].field,
	headerName: configParamsMetaInfo['FilterByDeadlineMarginSec'].title,
	width: 160,
	sortable: true,
	backgroundColor: '#e6e6e6',
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, _) => (!configset? "" : configset["FilterByDeadlineMarginSec"] / 60),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), configParamsMetaInfo['FilterByDeadlineMarginSec'].min) : Number(cell.value)).
		toFixed(1)),
}, {
	field: 'jobs',
	headerName: 'Jobs Done',
	width: 120,
	sortable: true,
	boldText: true,
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, stats) => (!stats? "" : stats["jobs_done"]),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), 0) : Number(cell.value)).
		toFixed(0).
		replace(/\B(?=(\d{3})+(?!\d))/g, ",")), // remove decimal places and add commas
}, {
	field: 'robotDailyJobs',
	headerName: 'Robot Daily Jobs',
	width: 140,
	sortable: true,
	boldText: true,
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, stats) => (!stats? "" : stats["robot_avg_job_count"]),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), 0) : Number(cell.value)).
		toFixed(1)),
}, {
	field: batchResultsPrimaryObjectiveColumn,
	headerName: 'Robot Hourly Jobs',
	width: 140,
	sortable: true,
	boldText: true,
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, stats) => (!stats || !configset? "" : stats["robot_avg_job_count"] / (configset["OperatingHours"][1] - configset["OperatingHours"][0])),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), 0) : Number(cell.value)).
		toFixed(2)),
}, {
	field: 'jobsSuitable',
	headerName: 'All Jobs',
	width: 120,
	sortable: true,
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, stats) => (!stats? "" : stats["jobs_suitable"]),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), 0) : Number(cell.value)).
		toFixed(0).
		replace(/\B(?=(\d{3})+(?!\d))/g, ",")), // remove decimal places and add commas
}, {
	field: 'jobsFulfilledPerc',
	headerName: 'Fulfill %',
	width: 120,
	sortable: true,
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, stats) => (!stats? "" : stats["jobs_done"] / stats["jobs_suitable"] * 100),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		`${(limit? Math.min(Math.max(Number(cell.value), 0), 100) : Number(cell.value)).toFixed(1)}%`),
}, {
	field: 'jobsLate',
	headerName: 'Jobs Late',
	width: 120,
	sortable: true,
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, stats) => (!stats || stats["late_deliveries_count"] === null ? "" : stats["late_deliveries_count"]),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), 0) : Number(cell.value)).
		toFixed(0)),
}, {
	field: 'jobsLatePerc',
	headerName: 'Late %',
	width: 120,
	sortable: true,
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, stats) => (!stats || stats["late_deliveries_perc"] === null ? "" : stats["late_deliveries_perc"] * 100),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		`${(limit? Math.max(Number(cell.value), 0) : Number(cell.value)).toFixed(2)}%`),
}, {
	field: 'jobsLateMins',
	headerName: 'Avg Late Mins',
	width: 120,
	sortable: true,
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, stats) => (!stats || stats["late_deliveries"] === null ? "" : stats["late_deliveries"]["avg"]),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		Number(cell.value).
		toFixed(1)),
}, {
	field: 'usedHoursPerc',
	headerName: 'Used Robot Hours',
	width: 120,
	sortable: true,
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, stats) => (!stats || !configset? "" : (stats["delivery_duration"]["avg"] * stats["robot_avg_job_count"] / 60.0) / (configset["OperatingHours"][1] - configset["OperatingHours"][0]) * 100),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		`${(limit? Math.max(Number(cell.value), 0) : Number(cell.value)).toFixed(1)}%`),
}, {
	field: 'avgDeliveryDist',
	headerName: 'Avg Delivery Miles',
	width: 120,
	sortable: true,
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, stats) => (!stats? "" : stats["delivery_distance"]["avg"] * distanceConvMult),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), 0) : Number(cell.value)).
		toFixed(3)),
}, {
	field: 'maxDeliveryDist',
	headerName: 'Max Delivery Miles',
	width: 120,
	sortable: true,
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, stats) => (!stats? "" : stats["delivery_distance"]["max"] * distanceConvMult),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), 0) : Number(cell.value)).
		toFixed(3)),
}, {
	field: 'avgDeliveryMins',
	headerName: 'Avg Delivery Mins',
	width: 120,
	sortable: true,
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, stats) => (!stats? "" : stats["delivery_duration"]["avg"]),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), 0) : Number(cell.value)).
		toFixed(2)),
}, {
	field: 'maxDeliveryMins',
	headerName: 'Max Delivery Mins',
	width: 120,
	sortable: true,
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, stats) => (!stats? "" : stats["delivery_duration"]["max"]),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), 0) : Number(cell.value)).
		toFixed(2)),
}, {
	field: 'avgPickupDist',
	headerName: 'Avg Pickup Miles',
	width: 120,
	sortable: true,
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, stats) => (!stats? "" : stats["pickup_distance"]["avg"] * distanceConvMult),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), 0) : Number(cell.value)).
		toFixed(3)),
}, {
	field: 'maxPickupDist',
	headerName: 'Max Pickup Miles',
	width: 120,
	sortable: true,
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, stats) => (!stats? "" : stats["pickup_distance"]["max"] * distanceConvMult),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), 0) : Number(cell.value)).
		toFixed(3)),
}, {
	field: 'avgPickupMins',
	headerName: 'Avg Pickup Mins',
	width: 120,
	sortable: true,
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, stats) => (!stats? "" : stats["pickup_leg_duration"]["avg"]),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), 0) : Number(cell.value)).
		toFixed(2)),
}, {
	field: 'maxPickupMins',
	headerName: 'Max Pickup Mins',
	width: 120,
	sortable: true,
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, stats) => (!stats? "" : stats["pickup_leg_duration"]["max"]),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), 0) : Number(cell.value)).
		toFixed(2)),
}, {
	field: 'avgDropoffDist',
	headerName: 'Avg Dropoff Miles',
	width: 120,
	sortable: true,
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, stats) => (!stats? "" : stats["dropoff_distance"]["avg"] * distanceConvMult),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), 0) : Number(cell.value)).
		toFixed(3)),
}, {
	field: 'maxDropoffDist',
	headerName: 'Max Dropoff Miles',
	width: 120,
	sortable: true,
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, stats) => (!stats? "" : stats["dropoff_distance"]["max"] * distanceConvMult),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), 0) : Number(cell.value)).
		toFixed(3)),
}, {
	field: 'avgDropoffMins',
	headerName: 'Avg Dropoff Mins',
	width: 120,
	sortable: true,
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, stats) => (!stats? "" : stats["dropoff_leg_duration"]["avg"]),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), 0) : Number(cell.value)).
		toFixed(2)),
}, {
	field: 'maxDropoffMins',
	headerName: 'Max Dropoff Mins',
	width: 120,
	sortable: true,
	renderCell: renderResultsColumnsCell,
	customData: (resultsId, configset, stats) => (!stats? "" : stats["dropoff_leg_duration"]["max"]),
	valueGetter: (cell, limit=true) => (cell.value === ""? "" :
		(limit? Math.max(Number(cell.value), 0) : Number(cell.value)).
		toFixed(2)),
}];


export {appTitle,
	googleMapsKey,
	urlAuthLogin, urlAuthLogout,
	urlHome, urlHomeBatchDialog, urlDeployment, urlBatchResult, urlCalculator, urlLogs, urlUsersRecentActivity,
	urlApiUser, urlRefreshAccessControl,
	urlApiData, urlApiConfigMeta,
	urlApiDataFile, urlApiDataIndexFile, urlApiDataIndexMeta, urlApiDataHeatmapFromConfigsetId, urlApiDataHeatmapFromConfigset,
	urlApiConfig, urlApiConfigFile,
	urlApiSimulation, urlApiSimulationResultData, urlApiSimulationRobotData,
	urlApiBatchSimulation, urlApiBatchResultsIndex, urlApiBatchResultsIndexItem, urlApiBatchResultsData,
	urlApiBatchCSVFile, urlApiBatchConfigFile, urlApiBatchModelFile, urlApiBatchListFiles, batchResultsColumns,
	urlApiLogs, urlApiLogsResponse, urlApiUsersRecentActivity,
	configParamsMetaInfo, configParamsByField, convertToMiles, distanceConvMult, batchResultsPrimaryObjectiveColumn
}
