import './App.css';
import React from 'react';
import {Navigate, BrowserRouter, Routes, Route, useLocation} from 'react-router-dom';
import Login from "./Login/Login";
import Home from "./Home/Home";
import {useEffect} from "react";
import axios from "axios";
import {appTitle, urlApiUser} from "./constants";
import Loading from "./Loading/Loading";
import Deployment from "./Deployment/Deployment";
import ActivityLogs from "./LogView/ActivityLogs";
import UsersRecentActivity from "./LogView/UsersRecentActivity";
import BatchResults from "./Batching/BatchResults";
import Calculator from "./Batching/Calculator";


export const RemoveTrailingSlash = ({...rest}) => {
    const location = useLocation()

    // If the last character of the url is '/'
    if (location.pathname.match('/.*/$')) {
        return <Navigate replace {...rest} to={{
            pathname: location.pathname.replace(/\/+$/, ""),
            search: location.search
        }}/>
    } else return null
}


const App = React.memo(() => {
  const [loadingUserInfo, setLoadingUserInfo] = React.useState(true);
  const [userInfo, setUserInfo] = React.useState(null);
  const [userLoggedIn, setUserLoggedIn] = React.useState(false);

  useEffect(() => {
    document.title = appTitle;
  }, []);

	useEffect(()=> {
    axios.get(urlApiUser).then(response => {
      setUserLoggedIn(true);
      setUserInfo(response.data);
      setLoadingUserInfo(false);
    }).catch(error => {
      setLoadingUserInfo(false);
      setUserLoggedIn(false);
    })
  }, []);

  function getRoutes() {
    return <Routes>
      <Route path="/">
        <Route path="login" element={<Login/>} />
        <Route index element={<Home userInfo={userInfo} batchOpen={false} />} />
        <Route path="/ds" element={<Home userInfo={userInfo} batchOpen={false} />} />
        <Route path="/ds//cf" element={<Home userInfo={userInfo} batchOpen={false} />} />
        <Route path="/ds/:datasetId" element={<Home userInfo={userInfo} batchOpen={false} />} />
        <Route path="/ds/:datasetId/cf" element={<Home userInfo={userInfo} batchOpen={false} />} />
        <Route path="/ds//cf/:configsetId" element={<Home userInfo={userInfo} batchOpen={false} />} />
        <Route path="/ds/:datasetId/cf/:configsetId" element={<Home userInfo={userInfo} batchOpen={false} />} />
        <Route path="/ds/:datasetId/cf/:configsetId/batch" element={<Home userInfo={userInfo} batchOpen={true} />} />
        <Route path="/ds/:datasetId/cf/:configsetId/batch/:batchParamsEncoded" element={<Home userInfo={userInfo} batchOpen={true} />} />
        <Route path="/deployment/:resultId" element={<Deployment userInfo={userInfo} />} />
        <Route path="/batch-result/:batchId" element={<BatchResults userInfo={userInfo} />} />
        <Route path="/batch-result/" element={<BatchResults userInfo={userInfo} />} />
        <Route path="/calculator" element={<Calculator userInfo={userInfo} />} />
        <Route path="/calculator/:mainBatchId" element={<Calculator userInfo={userInfo} />} />
        <Route path="/logs" element={<ActivityLogs userInfo={userInfo} />} />
        <Route path="/users-recent" element={<UsersRecentActivity userInfo={userInfo} />} />
      </Route>
    </Routes>;
  }

  return (
    <div className="App">
      <header className="App-header">
        <BrowserRouter>
          <RemoveTrailingSlash/>
          {loadingUserInfo?
            <Loading/>
            :
            (!userLoggedIn?
                <Login/>
                :
                getRoutes()
            )
          }
        </BrowserRouter>
      </header>
    </div>
  );
});

export default App;