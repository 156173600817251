import {
	Checkbox,
	FormControl,
	FormControlLabel, InputAdornment,
	InputLabel, MenuItem,
	OutlinedInput,
	Select,
	TextField,
	Tooltip
} from "@mui/material";
import * as React from "react";
import ListItemText from "@mui/material/ListItemText";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export function BoolInput({label, hint="", value, disabled=false, onChange}) {

	const handleChange = (event) => {
		if (onChange)
			onChange(event.target.checked);
	}

	return (
		<Tooltip title={hint}>
			<FormControlLabel
				label={label}
				disabled={disabled}
				control={<Checkbox
					checked={value}
					onChange={handleChange}
				/>}
			/>
		</Tooltip>
	);
}

export function IntegerInput({label, hint="", min=null, max=null, value, disabled=false, onChange}) {

	const handleChange = (event) => {
		let value = event.target.value;
		value = Math.round(value);
		if (min != null)
			value = Math.max(min, value);
		if (max != null)
			value = Math.min(max, value);
		if (onChange)
			onChange(value);
	}

	return (
		<Tooltip title={hint}>
			<TextField
				label={label}
				type="number"
				value={value}
				disabled={disabled}
				onChange={handleChange}
				InputLabelProps={{shrink: true}}
			/>
		</Tooltip>
	)
}

export function DecimalInput({label, hint="", min=null, max=null, decimalIncrement=0.1, value, disabled=false, onChange}) {

	const handleChange = (event) => {
		let value = event.target.value;
		if (min != null)
			value = Math.max(min, value);
		if (max != null)
			value = Math.min(max, value);
		if (onChange)
			onChange(value);
	}

	return (
		<Tooltip title={hint}>
			<TextField
				label={label}
				type="number"
				value={value}
				disabled={disabled}
				inputProps={{step: decimalIncrement}}
				onChange={handleChange}
				InputLabelProps={{shrink: true}}
			/>
		</Tooltip>
	)
}

export function PercentInput({label, hint="", min=null, max=null, value, disabled=false, onChange}) {

	const handleChange = (event) => {
		let value = event.target.value;
		if (min != null)
			value = Math.max(min, value);
		if (max != null)
			value = Math.min(max, value);
		if (onChange)
			onChange(value / 100.0);
	}

	return (
		<Tooltip title={hint}>
			<TextField
				label={label}
				type="number"
				value={Math.round(value * 100)}
				onChange={handleChange}
				disabled={disabled}
				InputLabelProps={{shrink: true}}
				InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}}
			/>
		</Tooltip>
	)
}

export function SetInput({label, minRequired, hints, choices, value, disabled=false, onChange}) {

	const handleChange = (event) => {
		// if there's min number of selections required, make sure we don't go under that
		if (minRequired && event.target.value.length < minRequired)
			return;

		if (onChange)
			onChange(choices.map((choice) => event.target.value.includes(choice)));
	}

	function getSelectValue() {
		return value.map((enabled, idx) => enabled? choices[idx] : null).filter(t => t != null);
	}

	return (
		<FormControl>
			<InputLabel >{label}</InputLabel>
			<Select
				multiple
				value={getSelectValue()}
				disabled={disabled}
				onChange={handleChange}
				input={<OutlinedInput label={label} />}
				renderValue={(selected) => selected.join(', ')}
				MenuProps={MenuProps}
			>
				{choices.map((choice, idx) => (
					<MenuItem key={choice} value={choice}>
						<Checkbox checked={value[idx]} />
						<Tooltip title={hints[idx]}>
							<ListItemText primary={choice} />
						</Tooltip>
					</MenuItem>
				))}
			</Select>
		</FormControl>)
}
