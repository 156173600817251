import * as React from "react";
import GoogleMapReact from "google-map-react";
import {googleMapsKey} from "../constants";
import * as PropTypes from "prop-types";
import {useEffect, useRef, useState} from "react";
import {getNewRadius} from "./mercatorProjection";


GoogleMapReact.propTypes = {
	bootstrapURLKeys: PropTypes.shape({key: PropTypes.string}),
	children: PropTypes.node
};


export const Map = ({configset, merchants, merchantsServed, robots, heatmap, heatmapOutdated,
											cursor, enableMarkerDrag, filterType,
											onMoveHomeMarker, onMoveHotZoneMarker, onClick,
											onMerchantMarkerClick, onWhitelistMerchantMarkerClick, onBlacklistMerchantMarkerClick}) => {
	const [map, setMap] = useState(null);
	const [maps, setMaps] = useState(null);
	const [zoomLevel, setZoomLevel] = useState(13);
	const markers = useRef({
		base: null,
		hotZones: [],
		merchants: [],
		whitelistMerchants: [],
		blacklistMerchants: [],
		merchantsServed: [],
		robots: [],
		robotBases: [],
		robotPickupMerchants: [],
		robotDropoffCustomers: [],
		robotReturnLocations: [],
		robotPaths: [],
		heatmap: null,
	})
	const icons = useRef({})

	const hotZones = configset? configset["HotZones"] : null;
	const useHotZones = configset? configset["UseHotZones"] : null;
	const baseCoords = configset? configset["BaseCoords"] : null;
	const robotCount = configset? configset["RobotCount"]["value"] : null;
	const hotZoneRobotTotal = configset? hotZones.reduce((sum, a) => sum + a["FleetSize"], 0) : null;
	const whitelistMerchants = configset && "MerchantsWhitelist" in configset? configset["MerchantsWhitelist"] : null;
	const blacklistMerchants = configset && "MerchantsBlacklist" in configset? configset["MerchantsBlacklist"] : null;

	let robotBases = [];
	let robotPickupMerchants = [];
	let robotDropoffCustomers = [];
	let robotReturnLocations = [];
	let robotPaths = [];

	if (robots) {
		Object.keys(robots).forEach(idx => {
			robots[idx]["base_coords"] = [
				Number(robots[idx]["base_location"].split(",")[0]),
				Number(robots[idx]["base_location"].split(",")[1])
		]});

		const tmp = {};
		for (const idx in robots) {
			let base = robots[idx]["base_location"];
			if (base in tmp)
				tmp[base]["robots"].push(idx);
			else {
				tmp[base] = {
					"name": robots[idx]["hotzone"],
					"lat": robots[idx]["base_coords"][0],
					"lng": robots[idx]["base_coords"][1],
					"robots": [idx],
				}
			}
		}
		robotBases = Object.keys(tmp).map(idx => tmp[idx]);

		Object.keys(robots).forEach((idx) => {
			robotPickupMerchants = robotPickupMerchants.concat(
				robots[idx].journey.map(j => ({
					"jobId": j["job_id"],
					"jobIndex": j["job_no"],
					"robotIndex": idx,
					"time": getTime(j["arrived_pickup_ts"], robots[idx].jobs[j["job_no"]]["timezone"]),
					"pickup_id": robots[idx].jobs[j["job_no"]]["pickup_id"],
					"pickup_name": robots[idx].jobs[j["job_no"]]["pickup_name"],
					"pickup_lat": j["pickup"][0],
					"pickup_lng": j["pickup"][1],
				}))
			);

			robotDropoffCustomers = robotDropoffCustomers.concat(
				robots[idx].journey.map(j => ({
					"jobId": j["job_id"],
					"jobIndex": j["job_no"],
					"robotIndex": idx,
					"time": getTime(j["arrived_dropoff_ts"], robots[idx].jobs[j["job_no"]]["timezone"]),
					"pickup_name": robots[idx].jobs[j["job_no"]]["pickup_name"],
					"dropoff_lat": j["dropoff"][0],
					"dropoff_lng": j["dropoff"][1],
				}))
			);

			robotReturnLocations = robotReturnLocations.concat(
				robots[idx].journey.filter(j => j["has_return_leg"]).map(j => ({
					"jobId": j["job_id"],
					"jobIndex": j["job_no"],
					"robotIndex": idx,
					"time": getTime(j["completed_return_ts"], robots[idx].jobs[j["job_no"]]["timezone"]),
					"return_lat": j["return"][0],
					"return_lng": j["return"][1],
				}))
			);

			let tmp = [{
				"lat": robots[idx]["base_coords"][0],
				"lng": robots[idx]["base_coords"][1]
			}].concat(
				robots[idx].journey.map(j => ([{
					"lat": j["pickup"][0],
					"lng": j["pickup"][1],
				},{
					"lat": j["dropoff"][0],
					"lng": j["dropoff"][1],
				},{
					"lat": j["return"][0],
					"lng": j["return"][1],
				}])).flat()
			);
			robotPaths.push(tmp);
		});
	}

	const loadMap = (map, maps) => {
		setMap(map);
		setMaps(maps);

		icons.current.home = {
			url: '/home-marker.png',
			scaledSize: new maps.Size(25, 25),
			origin: new maps.Point(0, 0),
			anchor: new maps.Point(15, 25),
		};

		icons.current.hotZone = {
			url: '/hotzone-marker-red.png',
			scaledSize: new maps.Size(16, 23),
			origin: new maps.Point(0, 0),
			anchor: new maps.Point(8, 23),
			labelOrigin: new maps.Point(8, 35)
		};

		icons.current.merchant = {
			url: '/merchant-grey.png',
			scaledSize: new maps.Size(12, 12),
			origin: new maps.Point(0, 0),
			anchor: new maps.Point(6, 12),
		};

		icons.current.merchantActive = {
			url: '/merchant-blue.png',
			scaledSize: new maps.Size(12, 12),
			origin: new maps.Point(0, 0),
			anchor: new maps.Point(6, 12),
		};

		icons.current.merchantInclude = {
			url: '/merchant-green.png',
			scaledSize: new maps.Size(12, 12),
			origin: new maps.Point(0, 0),
			anchor: new maps.Point(6, 12),
		};

		icons.current.merchantExclude = {
			url: '/merchant-red.png',
			scaledSize: new maps.Size(12, 12),
			origin: new maps.Point(0, 0),
			anchor: new maps.Point(6, 12),
		};

		icons.current.robots = {
			url: '/serve-icon.png',
			scaledSize: new maps.Size(14, 14),
			origin: new maps.Point(0, 0),
			anchor: new maps.Point(7, 14),
		};

		icons.current.robotBases = {
			url: '/hotzone-marker-blue.png',
			scaledSize: new maps.Size(16, 23),
			origin: new maps.Point(0, 0),
			anchor: new maps.Point(8, 23),
		};

		icons.current.robotPickupMerchants = {
			url: '/merchant-blue.png',
			scaledSize: new maps.Size(12, 12),
			origin: new maps.Point(0, 0),
			anchor: new maps.Point(6, 12),
		};

		icons.current.robotDropoffCustomers = {
			url: '/customer-green.png',
			scaledSize: new maps.Size(12, 12),
			origin: new maps.Point(0, 0),
			anchor: new maps.Point(6, 12),
		};

		icons.current.robotReturnLocations = {
			url: '/return-black.png',
			scaledSize: new maps.Size(10, 10),
			origin: new maps.Point(0, 0),
			anchor: new maps.Point(5, 10),
		};
	};

	const handleHomeMarkerDragEnd = (event) => {
		if (onMoveHomeMarker)
			onMoveHomeMarker(event.latLng.lat(), event.latLng.lng())
	}

	const handleHotZoneMarkerDragEnd = (index) => (event) => {
		if (onMoveHotZoneMarker)
			onMoveHotZoneMarker(index, event.latLng.lat(), event.latLng.lng())
	}

	function calcFleetSizeAtHotZone(size) {
		return Math.round(size / hotZoneRobotTotal * robotCount);
	}

	function getTime(ts, tz) {
		const dt = ts? new Date((ts + tz*3600)*1000) : null;
		return dt? `${String(dt.getUTCHours()).padStart(2, '0')}:${String(dt.getUTCMinutes()).padStart(2, '0')}` : "";
	}

	useEffect(() => {
		if (map && maps) {
			for (const idx in markers.current.merchants)
				markers.current.merchants[idx].setMap(null);

			if (merchants) {
				const merchantIDs = Object.keys(merchants);
				markers.current.merchants = merchantIDs.map(id => (
					new maps.Marker({
						map,
						position: {
							lat: Number(merchants[id]["lat"]),
							lng: Number(merchants[id]["lng"])
						},
						draggable: false,
						icon: icons.current.merchant,
						title: `${id}: ${merchants[id]["name"]} (x${merchants[id]["jobs"]})`,
						zIndex: 1,
					})
				));

				if (onMerchantMarkerClick)
					for (const idx in markers.current.merchants)
						markers.current.merchants[idx].addListener('click', () => {
								onMerchantMarkerClick(merchantIDs[Number(idx)])
							}
						);
			}
		}
	}, [map, maps, merchants, filterType, onMerchantMarkerClick]);

	useEffect(() => {
		if (map && maps) {
			for (const idx in markers.current.merchantsServed)
				markers.current.merchantsServed[idx].setMap(null);

			if (merchantsServed) {
				markers.current.merchantsServed = merchantsServed.map((merch, idx) => (
					new maps.Marker({
						map,
						position: {
							lat: Number(merch["lat"]),
							lng: Number(merch["lng"])
						},
						draggable: false,
						icon: icons.current.merchantActive,
						title: `${merch["id"]}: ${merch["name"]} (done ${merch["count"]} of ${merch["filtered_jobs"]})`,
						zIndex: 1,
					})
				));
			}
		}
	}, [map, maps, merchantsServed]);

	useEffect(() => {
		if (map && maps) {
			for (const idx in markers.current.whitelistMerchants)
				markers.current.whitelistMerchants[idx].setMap(null);

			if (merchants && whitelistMerchants) {
				markers.current.whitelistMerchants = whitelistMerchants.filter(id => id in merchants).map(id => (
					new maps.Marker({
						map,
						position: {
							lat: Number(merchants[id]["lat"]),
							lng: Number(merchants[id]["lng"])
						},
						draggable: false,
						icon: icons.current.merchantInclude,
						title: `${id}: ${merchants[id]["name"]} (x${merchants[id]["jobs"]})`,
						zIndex: 9999,
					})
				));

				if (onWhitelistMerchantMarkerClick)
					for (const idx in markers.current.whitelistMerchants)
						markers.current.whitelistMerchants[idx].addListener('click', () => {
							onWhitelistMerchantMarkerClick(whitelistMerchants[Number(idx)]);
						});
			}
		}
	}, [map, maps, merchants, whitelistMerchants, onWhitelistMerchantMarkerClick]);

	useEffect(() => {
		if (map && maps) {
			for (const idx in markers.current.blacklistMerchants)
				markers.current.blacklistMerchants[idx].setMap(null);

			if (merchants && blacklistMerchants) {
				markers.current.blacklistMerchants = blacklistMerchants.filter(id => id in merchants).map(id => (
					new maps.Marker({
						map,
						position: {
							lat: Number(merchants[id]["lat"]),
							lng: Number(merchants[id]["lng"])
						},
						draggable: false,
						icon: icons.current.merchantExclude,
						title: `${id}: ${merchants[id]["name"]} (x${merchants[id]["jobs"]})`,
						zIndex: 9999,
					})
				));

				if (onBlacklistMerchantMarkerClick)
					for (const idx in markers.current.blacklistMerchants)
						markers.current.blacklistMerchants[idx].addListener('click', () => {
							onBlacklistMerchantMarkerClick(blacklistMerchants[Number(idx)])
						});
			}
		}
	}, [map, maps, merchants, blacklistMerchants, onBlacklistMerchantMarkerClick]);

	useEffect(() => {
		// add markers
		if (map && maps && hotZones) {
			for (const idx in markers.current.hotZones)
				markers.current.hotZones[idx].setMap(null);

			if (useHotZones) {
				markers.current.hotZones = hotZones.map((item, index) => (
					new maps.Marker({
						map,
						position: {
							lat: item["Coords"][0],
							lng: item["Coords"][1]
						},
						draggable: enableMarkerDrag,
						label: {
							color: 'red', fontWeight: 'bold', fontSize: '10px',
							text: `${item["Name"]} (${calcFleetSizeAtHotZone(item["FleetSize"])})`,
						},
						icon: icons.current.hotZone,
					})
				));

				for (const idx in markers.current.hotZones)
					markers.current.hotZones[idx].addListener('dragend', handleHotZoneMarkerDragEnd(Number(idx)));
			}
		}
	}, [map, maps, hotZones, useHotZones]);

	useEffect(() => {
		if (map && maps) {
			if (markers.current.base)
				markers.current.base.setMap(null);

			markers.current.base = new maps.Marker({
				map,
				position: {
					lat: baseCoords[0],
					lng: baseCoords[1]
				},
				draggable: enableMarkerDrag,
				icon: icons.current.home,
			});

			markers.current.base.addListener('dragend', handleHomeMarkerDragEnd);
		}
	}, [map, maps, baseCoords]);

	useEffect(() => {
		if (map && maps) {
			for (const idx in markers.current.robotBases)
				markers.current.robotBases[idx].setMap(null);

			if (robotBases) {
				markers.current.robotBases = robotBases.map((jobInfo, idx) => (
					new maps.Marker({
						map,
						position: {lat: Number(jobInfo["lat"]), lng: Number(jobInfo["lng"])},
						draggable: false,
						icon: icons.current.robotBases,
						title: `${jobInfo["name"]}: ${jobInfo["robots"].map(i => `Robot ${i+1}`).join(", ")}`,
						zIndex: 100,
					})
				));
			}
		}
	}, [map, maps, robotBases]);

	useEffect(() => {
		if (map && maps) {
			for (const idx in markers.current.robotPickupMerchants)
				markers.current.robotPickupMerchants[idx].setMap(null);

			if (robotPickupMerchants) {
				markers.current.robotPickupMerchants = robotPickupMerchants.map((j) => (
					new maps.Marker({
						map,
						position: {lat: Number(j["pickup_lat"]), lng: Number(j["pickup_lng"])},
						draggable: false,
						icon: icons.current.robotPickupMerchants,
						title: `R${Number(j["robotIndex"])+1}-J${Number(j["jobIndex"])+1} from ${j["pickup_name"]} (arrived ${j["time"]})`,
						zIndex: 100,
					})
				));
			}
		}
	}, [map, maps, robotPickupMerchants]);

	useEffect(() => {
		if (map && maps) {
			for (const idx in markers.current.robotDropoffCustomers)
				markers.current.robotDropoffCustomers[idx].setMap(null);

			if (robotDropoffCustomers) {
				markers.current.robotDropoffCustomers = robotDropoffCustomers.map(j => (
					new maps.Marker({
						map,
						position: {lat: Number(j["dropoff_lat"]), lng: Number(j["dropoff_lng"])},
						draggable: false,
						icon: icons.current.robotDropoffCustomers,
						title: `R${Number(j["robotIndex"])+1}-J${Number(j["jobIndex"])+1} from ${j["pickup_name"]} (arrived ${j["time"]})`,
						zIndex: 100,
					})
				));
			}
		}
	}, [map, maps, robotDropoffCustomers]);

	useEffect(() => {
		if (map && maps) {
			for (const idx in markers.current.robotReturnLocations)
				markers.current.robotReturnLocations[idx].setMap(null);

			if (robotReturnLocations) {
				markers.current.robotReturnLocations = robotReturnLocations.map((j) => (
					new maps.Marker({
						map,
						position: {lat: Number(j["return_lat"]), lng: Number(j["return_lng"])},
						draggable: false,
						icon: icons.current.robotReturnLocations,
						title: `R${Number(j["robotIndex"])+1}-J${Number(j["jobIndex"])+1} Return End (${j["time"]})`,
						zIndex: 100,
					})
				));
			}
		}
	}, [map, maps, robotReturnLocations]);

	useEffect(() => {
		if (map && maps) {
			for (const idx in markers.current.robotPaths)
				markers.current.robotPaths[idx].setMap(null);

			if (robotPaths) {
				markers.current.robotPaths = robotPaths.map((path) => (
					new maps.Polyline({
						path: path,
						geodesic: true,
						strokeColor: "#000000",
						strokeOpacity: .5,
						strokeWeight: 1,
					})
				));
				markers.current.robotPaths.forEach(p => p.setMap(map));
			}
		}
	}, [map, maps, robotPaths])

	useEffect(() => {
		if (map && maps) {
			for (const idx in markers.current.robots)
				markers.current.robots[idx].setMap(null);

			if (robots) {
				markers.current.robots = Object.keys(robots).map((key, idx) => (
					new maps.Marker({
						map,
						position: robotPaths[idx][robotPaths[idx].length - 1],
						draggable: false,
						icon: icons.current.robots,
						title: `Robot ${idx+1}`,
						zIndex: 100,
					})
				));
			}
		}
	}, [map, maps, robots, robotPaths]);

	useEffect(() => {
		if (map && maps) {
			if (markers.current.heatmap)
				markers.current.heatmap.setMap(null);

			if (heatmap) {
				const data = heatmap["positions"].map(p => ({
					location: new maps.LatLng(p[0], p[1]),
					weight: p[2],
				}));

				markers.current.heatmap = new maps.visualization.HeatmapLayer({
					data: data,
					radius: getNewRadius(map, zoomLevel, heatmap["influence_radius_km"] / 4),
					opacity: heatmapOutdated === true? 0.25 : 0.5,
				});
				markers.current.heatmap.setMap(map);
			}
		}
	}, [map, maps, heatmap, heatmapOutdated, zoomLevel])

	return (
		<GoogleMapReact
			bootstrapURLKeys={{ key: googleMapsKey, libraries: ['visualization','geometry'] }}
			options={{draggableCursor: cursor? cursor : 'default'}}
			center={{lat: baseCoords[0], lng: baseCoords[1]}}
			zoom={zoomLevel}
			onZoomAnimationStart={(zoom) => {setZoomLevel(zoom)}}
			onClick={onClick}
			yesIWantToUseGoogleMapApiInternals
			onGoogleApiLoaded={({ map, maps }) => loadMap(map, maps)}
		>
		</GoogleMapReact>
	);
}