import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {
    configParamsByField,
    urlApiBatchConfigFile,
    urlApiBatchModelFile,
    urlApiBatchResultsIndex,
    batchResultsColumns,
    urlBatchResult,
    urlCalculator,
    urlApiConfigFile,
    urlHomeBatchDialog, batchResultsPrimaryObjectiveColumn, urlApiBatchListFiles,
} from "../constants";
import {
    Card, CardContent,
    CardHeader, Checkbox,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, FormControl, FormControlLabel,
    Grid,
    LinearProgress, MenuItem, Select, Stack,
    TextField,
    Tooltip
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeIcon from "@mui/icons-material/Home";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {DataGrid} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import LaunchIcon from "@mui/icons-material/Launch";
import SourceIcon from '@mui/icons-material/Source';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import TuneIcon from '@mui/icons-material/Tune';
import {arraysEqual, encodeObjectIntoUrlBinaryString} from "../utils";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import BatchSelector from "./BatchSelector";


export default function Calculator() {
    const {mainBatchId} = useParams();
    const [indexData, setIndexData] = useState({});
    const [batchIds, setBatchIds] = useState([]);
    const [comparableBatchIds, setComparableBatchIds] = useState([]);
    const [comparisonBatchIds, setComparisonBatchIds] = useState([]);
    const [models, setModels] = useState({});
    const [batchConfigs, setBatchConfigs] = useState({});
    const [inputs, setInputs] = useState({});
    const [inputDisabled, setInputDisabled] = useState(batchResultsPrimaryObjectiveColumn);
    const [isAwaitingResults, setIsAwaitingResults] = useState({});
    const [results, setResults] = useState([]);
    const [compBatchesSelectDialogOpen, setCompBatchesSelectDialogOpen] = useState(false);
    const navigate = useNavigate();

    const location = useLocation();
    const validInputsKeys = Object.keys(inputs).filter(field => field !== inputDisabled && inputs[field] !== "");

    const fieldMeta = Object.assign(configParamsByField,{
        [batchResultsPrimaryObjectiveColumn]: {
            field: batchResultsPrimaryObjectiveColumn,
            title: "Robot Hourly Jobs",
            mult: 1,
            min: 0.1,
            max: 3.0,
            step: 0.1
        }
    })

    // Load index.json on mount
    useEffect(() => {
        axios.get(urlApiBatchResultsIndex)
            .then((response) => {
                // console.log("index file", response.data);
                setIndexData(response.data);
                setBatchIds(Object.keys(response.data));
            }).catch((error) => {
                // console.error('Error loading index.json:', error);
            }
        );
    }, []);

    // Whenever main batch is changed, reset
    useEffect(() => {
        setInputs([]);
        setComparableBatchIds([]);
        setComparisonBatchIds([]);
        setModels({});
        setBatchConfigs({});
        setInputDisabled(batchResultsPrimaryObjectiveColumn);
        setResults([]);
        setCompBatchesSelectDialogOpen(false);
    }, [mainBatchId]);

    // Handle URL parameters to keep batchIds in URL
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const urlComps = params.get('comps');
        if (urlComps) {
            const ids = urlComps.split(',');
            const validIds = ids.filter(id => comparableBatchIds.includes(id));
            const uniqueIds = [...new Set(validIds)];
            setComparisonBatchIds(uniqueIds);
        }
    }, [location.search, mainBatchId, comparableBatchIds]);

    // when mainBatchId is selected
    useEffect(() => {
        if (mainBatchId) {
            setIsAwaitingResults(prev => ({
                ...prev,
                [`${mainBatchId}-model`]: true
            }));
            axios.get(urlApiBatchModelFile(mainBatchId)).then((response) => {
                // console.log("main batch model", mainBatchId, response.data);
                setModels((prev) => ({
                    ...prev,
                    [mainBatchId]: response.data,
                }));
            }).catch((error) => {
                // console.error('Error loading model file:', error);
            }).finally(() => {
                setIsAwaitingResults(prev => ({
                    ...prev,
                    [`${mainBatchId}-model`]: false
                }));
            });

            setIsAwaitingResults(prev => ({
                ...prev,
                [`${mainBatchId}-config`]: true
            }));
            axios.get(urlApiBatchConfigFile(mainBatchId)).then((response) => {
                // console.log("main batch config", mainBatchId, response.data);
                setBatchConfigs((prev) => ({
                    ...prev,
                    [mainBatchId]: response.data,
                }));

                const mainFields = response.data.batch_params.map(
                    (param) => param.field
                );

                // Limit comparable batchIds to those with same params
                const comps = batchIds.filter((id) => {
                    if (id === mainBatchId)
                        return false;
                    const params = indexData[id].params;
                    const fields = params.map((param) => param.field);
                    return arraysEqual(fields, mainFields);
                });
                setComparableBatchIds(comps);

            }).catch((error) => {
                // console.error('Error loading batchConfig:', error);
            }).finally(() => {
                setIsAwaitingResults(prev => ({
                    ...prev,
                    [`${mainBatchId}-config`]: false
                }));
            });
        }
    }, [mainBatchId, batchIds, indexData]);

    // when comparison batches change
    useEffect(() => {
        if (comparisonBatchIds && comparisonBatchIds.length > 0) {
            comparisonBatchIds.forEach(id => {
                setIsAwaitingResults(prev => ({
                    ...prev,
                    [`${id}-model`]: true
                }));
                axios.get(urlApiBatchModelFile(id)).then((response) => {
                    // console.log("main batch model", mainBatchId, response.data);
                    setModels((prev) => ({
                        ...prev,
                        [id]: response.data,
                    }));
                }).catch((error) => {
                    // console.error('Error loading model file:', error);
                }).finally(() => {
                    setIsAwaitingResults(prev => ({
                        ...prev,
                        [`${id}-model`]: false
                    }));
                });
            })
        }
    }, [comparisonBatchIds]);


    // Perform calculations when inputs change
    useEffect(() => {
        // reset results
        setResults([]);

        const totalInputs = (batchConfigs[mainBatchId]?.batch_params.length || 0) + 1; // +1 for the batchResultsPrimaryObjectiveColumn
        const filledInputs = Object.values(inputs).filter(
            (v) => v !== '' && v !== null && v !== undefined
        ).length;

        if (totalInputs > 0 && filledInputs >= totalInputs - 1)
            performCalculations();
    }, [inputs, batchConfigs, mainBatchId, comparisonBatchIds]);

    const handleRegenerateModel = () => {

        if (!!models[mainBatchId]) {
            alert("Model already exists");
            return false;
        }

        setIsAwaitingResults(prev => ({
            ...prev,
            [`${mainBatchId}-model`]: true
        }));

        axios.get(urlApiBatchModelFile(mainBatchId)).then((response) => {
            console.log("Model exists and was just loaded");
            setModels((prev) => ({
                ...prev,
                [mainBatchId]: response.data,
            }));
            setIsAwaitingResults(prev => ({
                ...prev,
                [`${mainBatchId}-model`]: false
            }));
        }).catch(() => {
            // Send the CSV content to the server via PUT request
            axios.put(urlApiBatchModelFile(mainBatchId)).then().catch((error) => {
                const statusCode = error.response.status;  // Get the status code
                const errorText = error.response.data;
                alert(`${statusCode}: ${errorText}`);
            });
        });

    };

    // Handle param input changes
    const handleParamInputChange = (e) => {
        const {name, value} = e.target;
        setInputs((prevInputs) => ({
            ...prevInputs,
            [name]: value ? parseFloat(value) : '',
        }));
    };

    const handleParamInputDisableClick = (e) => {
        const name = e.target.name;
        const existingDisabledState = e.target.checked;
        let newDisabledStateRequested = !existingDisabledState;

        if (newDisabledStateRequested === true) {
            setInputDisabled(name);
            setResults([]);
        } else if (name !== batchResultsPrimaryObjectiveColumn) {
            setInputDisabled(batchResultsPrimaryObjectiveColumn);
            setResults([]);
        }
    };

    const urlRunNewBatch = () => {
		if (!indexData || !mainBatchId || !(mainBatchId in indexData))
			return null;
		const datasetId = indexData[mainBatchId]["configset_meta"]["id"];
		const configsetId = indexData[mainBatchId]["configset_meta"]["id"];
		const params = indexData[mainBatchId]["params"];
		const encodedParamList = encodeObjectIntoUrlBinaryString(params);
		return urlHomeBatchDialog(datasetId, configsetId, encodedParamList);
	}

    const handleMainBatchSelectionChange = (event) => {
        if (!event.target.value)
            return;
        navigate(urlCalculator(event.target.value));
    };

    const handleCompBatchesSelectDialogOpen = () => {
        setCompBatchesSelectDialogOpen(true);
    };

    const handleCompBatchesSelectDialogClose = () => {
        setCompBatchesSelectDialogOpen(false);
    };

    const handleCompBatchesSelectionChange = (event) => {
        setComparisonBatchIds(event.target.value.filter(id => id !== mainBatchId));
    };

    // Handle "OK" button click - update URL
    const handleCompBatchesSelectDialogConfirm = () => {
        const params = new URLSearchParams(location.search);
        params.set('comps', comparisonBatchIds.join(','));
        navigate(`${location.pathname}?${params.toString()}`);
        setCompBatchesSelectDialogOpen(false);
    };

    const renderMainBatchSelector = () => {
        return (
            <BatchSelector
                indexFile={indexData}
                onSelectCallback={(newBatchId) => navigate(urlCalculator(newBatchId))}
            />
        )
        // if (!indexData)
        //     return;
        //
        // return (
        //     <Box>
        //         <DialogTitle>Select Batch</DialogTitle>
        //         <DialogContent >
        //             <FormControl fullWidth sx={{mb: 4}}>
        //                 <Select
        //                     labelId="batch-select-label"
        //                     value={mainBatchId || ""}
        //                     onChange={handleMainBatchSelectionChange}
        //                 >
        //                     {!!batchIds && batchIds.sort((a, b) => new Date(indexData[b].date) - new Date(indexData[a].date)).map((batchId) => (
        //                         <MenuItem key={batchId} value={batchId} disabled={batchId === mainBatchId}>
        //                             {indexData[batchId]? `[${indexData[batchId].date} : ${indexData[batchId].owner.split("@")[0] }] ${indexData[batchId].desc || batchId}` : batchId} {/* Use desc or fallback to batchId */}
        //                         </MenuItem>
        //                     ))}
        //                 </Select>
        //             </FormControl>
        //         </DialogContent>
        //     </Box>
        // );
    }

    // Render batch selection checkboxes in dialog
    const renderBatchCompareDialog = () => {
        if (!mainBatchId)
            return;
        return (
            <Dialog fullWidth maxWidth={"sm"} open={compBatchesSelectDialogOpen} onClose={handleCompBatchesSelectDialogClose}>
                <DialogTitle>Select Batches to Compare</DialogTitle>
                <DialogContent >
                    <FormControl fullWidth sx={{mb: 4}}>
                        <Select
                            labelId="multiple-batch-select-label"
                            multiple
                            value={[mainBatchId].concat(comparisonBatchIds)}
                            onChange={handleCompBatchesSelectionChange}
                        >
                            {[mainBatchId].concat(comparableBatchIds).map((batchId) => (
                                <MenuItem key={batchId} value={batchId} disabled={batchId === mainBatchId}>
                                    {indexData[batchId]?.desc || batchId} {/* Use desc or fallback to batchId */}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions sx={{m: 2}}>
                    <Button variant="contained" onClick={handleCompBatchesSelectDialogConfirm} sx={{mt: 2}}>Compare</Button>
                </DialogActions>
            </Dialog>
        );
    };

    const getCorrectModel = (batchId, col) => (models[batchId][col].find(m => arraysEqual(m.params, validInputsKeys)));

    // Perform calculations
    const performCalculations = () => {
        let computedValues = {};
        validInputsKeys.forEach(field => {
            computedValues[field] = inputs[field]
        });

        const newResults = [mainBatchId, ...comparisonBatchIds].filter(batchId => !!models[batchId]).map((batchId) => {
            const variablesToCompute = Object.keys(models[batchId]);
            const batchComputedValues = {...computedValues};
            const variablesComputed = new Set(Object.keys(batchComputedValues));

            let iterations = 0;
            const maxIterations = variablesToCompute.length;
            while (variablesComputed.size < variablesToCompute.length && iterations < maxIterations) {
                iterations++;
                variablesToCompute.forEach((varName) => {

                    if (!variablesComputed.has(varName)) {
                        const modelObj = getCorrectModel(batchId, varName); // batchModels[varName].find(m => arraysEqual(m.params, validInputsKeys));
                        const modelParams = modelObj? modelObj["model"] : null;

                        if (modelParams) {
                            let canCompute = true;
                            let value = modelParams.const || 0;

                            Object.keys(modelParams).forEach((depVar) => {
                                if (depVar !== 'const') {
                                    if (batchComputedValues[depVar] !== undefined) {
                                        value += modelParams[depVar] * batchComputedValues[depVar];
                                    } else {
                                        canCompute = false;
                                    }
                                }
                            });

                            if (canCompute) {
                                batchComputedValues[varName] = value;
                                variablesComputed.add(varName);
                            }
                        }
                    }
                });
            }

            return {
                id: batchId,
                batchId,
                ...batchComputedValues,
            };
        });

        setResults(newResults);
    };

    function getBatchInfoText(batchId) {
        if (!indexData || !batchId || !(batchId in indexData))
            return;
        const indexItem = indexData[batchId];
        const batchDesc = indexItem["desc"]
        const batchSize = indexItem["size"];
        const datasetMeta = indexItem["dataset_meta"];
        const datasetDesc = datasetMeta["desc"]
        const datasetRows = datasetMeta["rows"]
        const configsetMeta = indexItem["configset_meta"];
        const configsetDesc = configsetMeta["desc"]

        return `${batchDesc} (${Number(batchSize).toLocaleString()} runs) — ${datasetDesc} (${Number(datasetRows).toLocaleString()} rows)  |  ${configsetDesc}`;
    }

    function renderInputs() {
        const getParamDataAsText = (batchId, field, varName) => {
            const params = batchConfigs[batchId].batch_params;
            const param = params.find(param => param.field === field);
            const configMeta = configParamsByField[field];
            if (!param || !(varName in param))
                return null;
            const value = param[varName] * (!configMeta || !("mult" in configMeta)? 1 : configMeta.mult);
            const column = batchResultsColumns.find(item => item.field === field);
            return !!column && "valueGetter" in column? column.valueGetter({value}, false) : value;

        }

        return (batchConfigs[mainBatchId]?.batch_params && (
            <Card sx={{ flexShrink: 0 }}>
                <CardHeader subheader="Input Parameters" />
                <CardContent>
                    <Grid container spacing={2}>
                        {batchConfigs[mainBatchId].batch_params.map(item => item["field"]).concat(batchResultsPrimaryObjectiveColumn).map((field) => (
                            <Grid item container key={field} xs={6} sm={4} md={3} >
                                <Grid item xs={12} display="flex" alignItems="center">
                                    <FormControlLabel
                                        control={<Checkbox
                                            name={field}
                                            checked={inputDisabled !== field}
                                            disabled={field === batchResultsPrimaryObjectiveColumn && inputDisabled === batchResultsPrimaryObjectiveColumn}
                                            onChange={handleParamInputDisableClick}
                                            icon={<CheckCircleOutlineIcon/>}
                                            checkedIcon={<CheckCircleIcon/>}
                                        />}
                                        label=""
                                        sx={{ml: 1}} // Optional: Add some spacing between the TextField and the Checkbox
                                    />
                                    <TextField
                                        type="number"
                                        name={field}
                                        label={field === batchResultsPrimaryObjectiveColumn? "Robot Hourly Jobs" : fieldMeta[field].title}
                                        disabled={inputDisabled === field}
                                        value={inputDisabled === field || typeof inputs[field] === "undefined" || inputs[field] === null? '' : inputs[field]}
                                        inputProps={{
                                            min: fieldMeta[field].min * fieldMeta[field].mult,
                                            max: fieldMeta[field].max * fieldMeta[field].mult,
                                            step: fieldMeta[field].step * fieldMeta[field].mult
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        onChange={handleParamInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                   <Typography variant="body2" align="right">
                                       <i>{!getParamDataAsText(mainBatchId, field, "start")? "N/A" :
                                           `Batch Run: ${getParamDataAsText(mainBatchId, field, "start")} to 
                                            ${getParamDataAsText(mainBatchId, field, "stop")}, 
                                            Every ${getParamDataAsText(mainBatchId, field, "step")}`}
                                       </i>
                                   </Typography>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </CardContent>
            </Card>
        ));
    }

    function renderEstimateTable() {
        if (!batchConfigs[mainBatchId] || !results || results.length === 0)
            return;

        const getStdevText = (props, col) => {
            const modelObj = getCorrectModel(props.row.batchId, col["field"]);
            return !modelObj? "" : `± ${col.valueGetter({value: modelObj["stdev"]})}`;
        };

        let resultsColumns = [{
            field: "batchId",
            headerName: "Batch",
            width: 60,
        	align: 'center',
            colNo: 1,
            renderCell: (props) => (
                <Tooltip title={getBatchInfoText(props.row.batchId)}>
                    <Link href={urlBatchResult(props.row.batchId)} target="_blank">
                        <LaunchIcon />
                    </Link>
                </Tooltip>
            )
        }, {
            field: "desc",
            headerName: "Desc",
            width: 200,
            colNo: 2,
            renderCell: (props) => (indexData[props.row.batchId].desc),
        }];

        let paramColNo = 1000;
        let resultColNo = 2000;
        let configColNo = 3000;
        resultsColumns.push(...batchResultsColumns.filter(item => (
            // remove "id" and "link"
            item["field"] !== "link" && item["field"] !== "id"
            // and make sure the column exists in our results model
            && Object.keys(results[0]).includes(item["field"])
        )).map(item => ({
            ...item,
            colNo: (item["field"] in inputs || item["field"] === batchResultsPrimaryObjectiveColumn? paramColNo++ : (item["field"].startsWith("config:")? configColNo++ : resultColNo++)),
            backgroundColor: item["field"] in inputs && item["field"] !== inputDisabled? "#fffe6d" : item.backgroundColor,
            renderCell: (props) => (
                <Tooltip title={getStdevText(props, item)}>
                    {"renderCell" in item ? item.renderCell(props) : <span>{props.value}</span>}
                </Tooltip>
            )
        })));

        resultsColumns.sort((a, b) => (a.colNo - b.colNo));

        return (
            <Box sx={{ height: "100%", flexGrow: 0, overflow: "auto" }}>
				<DataGrid
					rows={results}
					columns={resultsColumns}
					initialState={{
						pagination: {
							paginationModel: { page: 0, pageSize: 100 },
						},
					}}
					autoHeight={false} // disable autoHeight
                    getRowClassName={(params) => { // highlight the row belonging to the main batch
                        return params.id === mainBatchId ? 'highlight-row' : '';
                    }}
                    sx={{
                        // highlight the row belonging to the main batch
                        '& .highlight-row': {
                            backgroundColor: '#e3fff7', // Light yellow background for highlighted row
                            '&:hover': {
                                backgroundColor: '#d3fff4', // Darker yellow on hover
                            },
                        },
                    }}
				/>
			</Box>
        );
    }

    function renderModelLoading() {
        return null;
    }

    function renderModelNotExists() {
        return (
            <Card sx={{ flexShrink: 0 }}>
                <CardContent>
                    <Stack spacing={2}>
                        <h3>Model does not exist. Refresh in a few minutes or if it's been more than 10 minutes, try re-generating one.</h3>
                        <Button variant="outlined" onClick={handleRegenerateModel}>
                            Re-Generate Model
                        </Button>
                        <Link href={urlApiBatchListFiles(mainBatchId)} target="_blank">
                            <i>List Batch Files</i>
                        </Link>
                    </Stack>
                </CardContent>
            </Card>
        );
    }

    return (
        <Dialog fullScreen open={true} fullWidth maxWidth={'lg'} PaperProps={{sx: {minHeight: "100%"}}}>
            <DialogTitle sx={{ m: 0, p: 2, gap: 1 }} display="flex">
                <Box>
                    <IconButton onClick={() => { navigate(-1) }}>
                        <Tooltip title="Go Back">
                            <ArrowBackIcon/>
                        </Tooltip>
                    </IconButton>
                </Box>
                <Box sx={{marginRight: 2}}>
                    <IconButton onClick={() => { navigate("/") }}>
                        <Tooltip title="Go Home">
                            <HomeIcon/>
                        </Tooltip>
                    </IconButton>
                </Box>

                <Box sx={{alignSelf: "center"}}>
                    Polaris Calculator

                    {!!indexData &&
                        <Box sx={{alignSelf: "center"}}>
                            <Typography variant="body1">{getBatchInfoText(mainBatchId)}</Typography>
                        </Box>
                    }
                </Box>

                <Box sx={{flexGrow: 1}} />

                <Link href={urlRunNewBatch()} disabled={!indexData || !mainBatchId}>
                    <IconButton disabled={!indexData || !mainBatchId}>
                        <Tooltip title="Run New Batch">
                            <AutoModeIcon />
                        </Tooltip>
                    </IconButton>
                </Link>
                <Box>
                    <IconButton onClick={handleCompBatchesSelectDialogOpen} disabled={!mainBatchId || !comparableBatchIds || comparableBatchIds.length === 0}>
						<Tooltip title="Compare to Other Batches">
                            <PlaylistAddIcon />
						</Tooltip>
                    </IconButton>
                </Box>
				<Link href={urlBatchResult(mainBatchId)} disabled={!mainBatchId}>
					<IconButton disabled={!mainBatchId}>
						<Tooltip title="Original Batch Results">
							<SourceIcon />
						</Tooltip>
					</IconButton>
				</Link>
				<Link href={urlApiConfigFile(!!indexData && !!mainBatchId && mainBatchId in indexData? indexData[mainBatchId]["configset_meta"]["id"] : "")} target="_blank" disabled={!indexData || !mainBatchId}>
					<IconButton disabled={!indexData || !mainBatchId}>
						<Tooltip title="Base Configset">
							<TuneIcon />
						</Tooltip>
					</IconButton>
				</Link>
            </DialogTitle>

            {Object.keys(isAwaitingResults).some(key => isAwaitingResults[key]) &&
                <LinearProgress variant={"indeterminate"}/>
            }

            <DialogContent dividers sx={{
                height: "calc(100vh - 120px)",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden", // prevent page-level scroll
                gap: "16px",  // adjust the value based on the desired spacing
            }}>
                {/*{!mainBatchId && <span>No model selected</span>}*/}
                {mainBatchId && !models[mainBatchId] && isAwaitingResults[`${mainBatchId}-model`] && renderModelLoading()}
                {mainBatchId && !models[mainBatchId] && !isAwaitingResults[`${mainBatchId}-model`] && renderModelNotExists()}
                {!!models[mainBatchId] && renderInputs()}
                {!!batchConfigs[mainBatchId] && !!results && results.length > 0 && renderEstimateTable()}

                {!mainBatchId && renderMainBatchSelector()}
                {renderBatchCompareDialog()}
            </DialogContent>

        </Dialog>
    );

}
