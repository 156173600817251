import {
	Alert,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid,
	Switch,
	TextField
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import {useEffect, useRef, useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import {DataGrid} from "@mui/x-data-grid";
import * as React from "react";
import {Map} from "../../Components/Map";
import axios from "axios";
import {urlApiDataHeatmapFromConfigset} from "../../constants";


const hotZoneColumns = [
	{
		field: 'name',
		headerName: 'Name',
		width: 120,
		editable: true,
		sortable: false,
	}, {
		field: 'size',
		headerName: 'Robots',
		type: 'number',
		width: 70,
		editable: true,
		sortable: false,
	}, {
		field: 'lat',
		headerName: 'Latitude',
		width: 100,
		type: 'number',
		editable: true,
		sortable: false,
	}, {
		field: 'lng',
		headerName: 'Longitude',
		width: 100,
		type: 'number',
		editable: true,
		sortable: false,
	}, {
		field: 'delete',
		headerName: "",
		width: 50,
		sortable: false,
		renderCell: (props) => (
			<IconButton onClick={() => props.row.onDelete(props.id)}>
				<DeleteIcon/>
			</IconButton>
		),
	}
]

export const FleetLocationDialog = ({open, datasetId, configset, merchants, onClose, onChange}) => {
	const [isAddingHotZone, setIsAddingHotZone] = useState(false);
	const [heatmap, setHeatmap] = useState(null);
	const [heatmapOutdated, setHeatmapOutdated] = useState(false);

	const heatmapFetcherSource = useRef(null);

	const hotZones = configset? configset["HotZones"] : null;
	const useHotZones = configset? configset["UseHotZones"] : null;
	const baseCoords = configset? configset["BaseCoords"] : null;
	const robotCount = configset? configset["RobotCount"]["value"] : null;
	const hotZoneRobotTotal = configset? hotZones.reduce((sum, a) => sum + a["FleetSize"], 0) : null;

	useEffect(() => {
		setHeatmapOutdated(true);
		if (open)
			fetchHeatmap();
		else
			setHeatmap(null);
	}, [configset, datasetId]);

	useEffect(() => {
		if (open && !heatmap) {
			fetchHeatmap();
		}
	}, [open])

	function fetchHeatmap() {
		if (configset && datasetId) {
			console.log("fetching heatmap...");
			// cancel previous request
			if (heatmapFetcherSource.current)
				heatmapFetcherSource.current.cancel("Canceling previous fetch requests");
			else
				heatmapFetcherSource.current = axios.CancelToken.source();
			// make a new request
			axios.post(urlApiDataHeatmapFromConfigset(datasetId), {"configset": configset}, {cancelToken: heatmapFetcherSource.current.token}).then(response => {
				console.log("heatmap", response)
				heatmapFetcherSource.current = null;
				setHeatmapOutdated(false);
				setHeatmap(response.data);
			}).catch(error => {
				heatmapFetcherSource.current = null;
				console.log("heatmap error", error);
			});
		}
	}

	function handleMapClick(event) {
		if (!isAddingHotZone)
			return;
		onChange(hotZones.concat([{
			"Name": "New Zone " + (hotZones.length+1).toString(),
			"FleetSize": 1,
			"Coords": [event.lat, event.lng],
		}]), "HotZones")
		setIsAddingHotZone(false);
	}

	function handleAddHotZone() {
		setIsAddingHotZone(!isAddingHotZone);
	}

	function handleDelete(id) {
		onChange(hotZones.filter((hz, idx) => idx !== id), "HotZones");
	}

	function handleMoveHomeMarker(lat, lng) {
		onChange([Number(lat.toFixed(6)), Number(lng.toFixed(6))], "BaseCoords");
	}

	function handleMoveHotZoneMarker(index, lat, lng) {
		onChange(hotZones.map((item, idx) =>
			idx !== index? item : {...item, "Coords": [lat, lng]}
		), "HotZones");
	}

	function handleHotZoneRowChange(newRow, oldRow) {
		onChange(hotZones.map((hz, idx) => oldRow.id !== idx? hz : {
			"Name": newRow.name,
			"FleetSize": newRow.size,
			"Coords": [newRow.lat, newRow.lng]
		}), "HotZones")
		return newRow;
	}


	function renderHotZonesTable() {
		if (!useHotZones)
			return;

		let rows = hotZones.map((hz, idx) => ({
			id: idx,
			name: hz["Name"],
			lat: hz["Coords"][0],
			lng: hz["Coords"][1],
			size: hz["FleetSize"],
			onDelete: handleDelete,
		}));

		return (
			<Box sx={{stretch: { height: "100%" }, minHeight: "250px", height: '100%', width: '100%'}}>
				<DataGrid
					rows={rows}
					columns={hotZoneColumns}
					hideFooter
					hideFooterPagination
					disableSelectionOnClick
					disableColumnMenu
					disableColumnFilter
					density={"compact"}
					experimentalFeatures={{ newEditingApi: true }}
					processRowUpdate={handleHotZoneRowChange}
				/>
			</Box>
		)
	}

	if (!configset)
		return <Alert severity="info" sx={{margin: 2}}>Please select a valid scenario file</Alert>;

	if (!open)
		return null;

	return (
		<Dialog fullScreen open={open} fullWidth maxWidth = {'lg'} PaperProps={{sx: {minHeight: "60%"}}}>
			<DialogTitle sx={{ m: 0, paddingTop: 0, paddingBottom: 0 }} open textAlign="end">
				<IconButton onClick={onClose}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>

			<DialogContent dividers>
				<Grid container spacing={2} sx={{height: "90vh", paddingBottom: 2}} alignItems="stretch">
					<Grid item sm={12} md={4} textAlign={"left"} sx={{display: "flex", flexDirection: "column" }}>

						<Grid container direction="row">
							<TextField label="Base Latitude" variant="outlined" value={baseCoords[0]} sx={{width: "50%"}}
												 onChange={event => onChange([event.target.value, baseCoords[1]], "BaseCoords")}
							/>
							<TextField label="Base Longitude" variant="outlined" value={baseCoords[1]} sx={{width: "50%"}}
												 onChange={event => onChange([baseCoords[0], event.target.value], "BaseCoords")}
							/>
						</Grid>

						<Grid container direction="row" sx={{margin: 1}}>
							<FormControlLabel label="Use Hot Zones" sx={{flex: "auto"}}
								control={
									<Switch
										checked={useHotZones}
										onChange={(event) => {onChange(event.target.checked, "UseHotZones")}}
									/>}
							/>

							{!useHotZones? null :
								<Button sx={{flex: "auto"}} onClick={handleAddHotZone}>
									{!isAddingHotZone? "Add Hot Zone" : "Cancel"}
								</Button>
							}
						</Grid>

						{renderHotZonesTable()}
					</Grid>

					<Grid item xs={12} sm={12} md={8} lg={8} sx={{display: "flex", flexDirection: "column", paddingBottom: 2}}>

						{!useHotZones || robotCount === hotZoneRobotTotal? null :
							<Alert sx={{marginBottom: 2}} severity="warning">
								Total {hotZoneRobotTotal} robots in Hot Zones don't match Robot Count. Hot Zone values will be normalized.
								<Button onClick={() => {
									onChange({...configset["RobotCount"], value: hotZoneRobotTotal}, "RobotCount")
								}}>
									Click to fix
								</Button>
							</Alert>
						}

						<div style={{stretch: { height: "100%" }, width: '100%', height: '100%', minHeight: "300px"}}>
							<Map
								configset={configset}
								merchants={merchants}
								heatmap={heatmap}
								heatmapOutdated={heatmapOutdated}
								enableMarkerDrag={true}
								cursor={isAddingHotZone? 'crosshair' : 'default'}
								onClick={handleMapClick}
								onMoveHomeMarker={handleMoveHomeMarker}
								onMoveHotZoneMarker={handleMoveHotZoneMarker}
							/>
						</div>
					</Grid>

				</Grid>
			</DialogContent>
		</Dialog>
	);
}