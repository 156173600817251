import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import axios from "axios";
import {urlApiData} from "../../constants";
import {Grid, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import DatasetList from "./DatasetList";
import DatasetUpload from "./DatasetUpload";
import Button from "@mui/material/Button";

const DatasetPage = React.memo(({initDatasetId, userInfo, onSelect}) => {
	const [isLoading, setLoading] = useState(true);
	const [isFailed, setFailed] = useState(false);
	const [datasets, setDatasets] = useState(null);
	const [selectedId, setSelectedId] = React.useState(initDatasetId);

	function fetchDatasetList() {
		axios.get(urlApiData).then(response => {
			setLoading(false);
			setDatasets(response.data);
		}).catch(error => {
			setLoading(false);
			setFailed(true);
			console.log(error);
		})
	}

	const handleOnSelect = useCallback(id => {
		setSelectedId(id);
		if (onSelect)
			onSelect(id);
	}, []);

	const handleDeleted = (id) => {
		fetchDatasetList();
		if (selectedId === id) {
			setSelectedId("");
			if (onSelect)
				onSelect("");
		}
	};

	const handleUploaded = (id) => {
		fetchDatasetList();
		setSelectedId(id);
		if (onSelect)
			onSelect(id)
	}

	useEffect(()=>{
		fetchDatasetList();
	}, []);

	return <div>
		<Grid container alignItems="flex-start" justifyContent="center" spacing={3} sx={{padding: 2}}>
			<Grid item lg={4} md={5} sm={12} xs={12}>
				<Stack spacing={1}>
					<Typography align="left">
						Upload a new data set:
					</Typography>
					<DatasetUpload onUploadCompleted={handleUploaded}/>
				</Stack>
			</Grid>
			<Grid item lg={8} md={7} sm={12} xs={12}>
				<Stack spacing={1}>
					<Typography align="left">
						Or select existing dataset:
					</Typography>
					<DatasetList
						isLoading={isLoading}
						isFailed={isFailed}
						items={datasets}
						selectedId={selectedId}
						onSelect={handleOnSelect}
						onDelete={handleDeleted}
					/>
				</Stack>
			</Grid>
		</Grid>
		<Grid container justifyContent="flex-end">
			{!(userInfo && userInfo["admin_permission"]) ? null :
				<Button download="index-list.json" href={urlApiData} target="_blank">
					Download index
				</Button>
			}
		</Grid>
	</div>
});

export default DatasetPage;