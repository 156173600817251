import {
	Autocomplete,
	Card,
	CardContent,
	CardHeader,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid, LinearProgress,
	TextField
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from '@mui/icons-material/Warning';
import * as React from "react";
import {useRef, useState} from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import {configParamsMetaInfo, urlBatchResult} from "../constants";
import {decodeUrlBinaryStringToObject, encodeObjectIntoUrlBinaryString} from "../utils";
import {useNavigate} from "react-router-dom";

const maxBatchSize = 2000;
const maxParams = 3;


export const BatchSimulationDialog = ({open, configset, batchParamsEncoded, onClose, onRunBatch, onBatchParamChange}) => {
	const [isBatchRequestProcessing, setIsBatchRequestProcessing] = useState(false);
	const paramList = useRef(decodeUrlBinaryStringToObject(batchParamsEncoded) || []);
  	const navigate = useNavigate();

	const getConfigValue = (c) => (typeof c === "number"? c : (typeof c === "object" && 'value' in c? c["value"] : null));

	function handleClose() {
		if (onClose)
			onClose();
	}

	function isParamListValid() {
		return paramList.current.every(param =>
			param.key !== "" &&
			param.start !== "" &&
			param.step !== "" &&
			param.stop !== ""
	  	);
	}

	function countBatchSize() {
		if (paramList.current.length === 0 || !isParamListValid())
			return 0;
		let cnt = 1;
		paramList.current.forEach(item => {
			cnt *= Math.floor((item["stop"] + item["step"] - item["start"]) / item["step"])
		});
		return cnt;
	}

	function updateParamList(newParamList) {
		paramList.current = newParamList;
		onBatchParamChange(encodeObjectIntoUrlBinaryString(newParamList))
	}

	function handleAddParameter() {
		updateParamList([...paramList.current, {
			"key": "",
			"start": "",
			"step": "",
			"stop": ""
		}]);
	}

	function handleChangeKey(index, event, newValue) {
		updateParamList(paramList.current.map((item, prevIdx) =>
			prevIdx !== index ? item : { ...item,
				key: !newValue? "" : newValue.id,
				field: !newValue? "" : configParamsMetaInfo[newValue.id].field,
				start: !newValue? "" : getConfigValue(configset[newValue.id]),
				stop: !newValue? "" : getConfigValue(configset[newValue.id]),
				step: !newValue? "" : configParamsMetaInfo[newValue.id].step,
			}
		));
	}

	function handleChangeStartValue(index, event) {
		const newVal = (Number(event.target.value) / (paramList.current[index]["key"]? configParamsMetaInfo[paramList.current[index]["key"]].mult : 1));
		updateParamList(paramList.current.map((item, prevIdx) =>
			prevIdx !== index ? item : {
				...item,
				start: item["stop"] !== "" && item["stop"] !== null ? Math.min(newVal, item["stop"]) : newVal
			}
		));
	}

	function handleChangeStepValue(index, event) {
		const newVal = (Number(event.target.value) / (paramList.current[index]["key"]? configParamsMetaInfo[paramList.current[index]["key"]].mult : 1));
		if (newVal <= 0)
			return;
		updateParamList(paramList.current.map((item, prevIdx) =>
			prevIdx !== index ? item : {
				...item,
				step: newVal
			}
		));
	}

	function handleChangeStopValue(index, event) {
		const newVal = (Number(event.target.value) / (paramList.current[index]["key"]? configParamsMetaInfo[paramList.current[index]["key"]].mult : 1));
		updateParamList(paramList.current.map((item, prevIdx) =>
			prevIdx !== index ? item : {
				...item,
				stop: item["start"] !== "" && item["start"] !== null ? Math.max(newVal, item["start"]) : newVal
			}
		));
	}

	function handleRunBatch() {
		onRunBatch(paramList.current, handleBatchRequestDone, handleBatchRequestError);
		setIsBatchRequestProcessing(true);
	}

	function handleBatchRequestDone(batchId) {
		setIsBatchRequestProcessing(false);
		const url = urlBatchResult(batchId);
		navigate(url);
	}

	function handleBatchRequestError() {
		setIsBatchRequestProcessing(false);
	}

	function renderParamList() {
		const paramOptions = Object.keys(configset).filter(key => (
			Object.keys(configParamsMetaInfo).includes(key)
		)).map(key => ({
			id: key,
			label: configParamsMetaInfo[key].title,
		}));

		return paramList.current.map((param, index) => (
			<Grid item sm={12} key={index}>
				<Card>
					<CardHeader
						action={
							<IconButton
								disabled={isBatchRequestProcessing}
								onClick={() => {
									updateParamList(paramList.current.filter((item, prevIdx) =>
										prevIdx !== index
									));
								}}
							>
								<DeleteIcon/>
							</IconButton>
						}
						subheader={`Parameter ${index + 1}`}
					/>
					<CardContent>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={6} md={3}>
								<Autocomplete
									disablePortal
								 	disabled={isBatchRequestProcessing}
									id="param-key"
									options={paramOptions}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									getOptionDisabled={(option) =>
										paramList.current.filter((item, i) => i !== index).map(item => item["key"]).includes(option.id)
									}
									renderInput={(params) => <TextField {...params} label="Parameter"/>}
									value={paramOptions.find(item => item.id === param["key"]) || null}
									onChange={(e, v) => handleChangeKey(index, e, v)}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={3}>
								<TextField
									id="param-start"
									label="Start"
									type="number"
									fullWidth={true}
									disabled={param["key"] === "" || isBatchRequestProcessing}
									InputLabelProps={{shrink: true}}
									value={param["key"]? parseFloat((param["start"] * configParamsMetaInfo[param["key"]].mult).toPrecision(6)) : ""}
									inputProps={{
										min: param["key"]? configParamsMetaInfo[param["key"]].min * configParamsMetaInfo[param["key"]].mult : "",
										max: param["key"]? configParamsMetaInfo[param["key"]].max * configParamsMetaInfo[param["key"]].mult : "",
										step: param["key"]? configParamsMetaInfo[param["key"]].step * configParamsMetaInfo[param["key"]].mult : 1,
									}}
									error={param["start"] === null || param["start"] === ""}
									onChange={(e) => handleChangeStartValue(index, e)}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={3}>
								<TextField
									id="param-step"
									label="Step"
									type="number"
									fullWidth={true}
									disabled={param["key"] === "" || isBatchRequestProcessing}
									InputLabelProps={{shrink: true}}
									value={param["key"]? parseFloat((param["step"] * configParamsMetaInfo[param["key"]].mult).toPrecision(6)) : ""}
									inputProps={{
										min: param["key"]? configParamsMetaInfo[param["key"]].min * configParamsMetaInfo[param["key"]].mult : "",
										max: param["key"]? configParamsMetaInfo[param["key"]].max * configParamsMetaInfo[param["key"]].mult : "",
										step: param["key"]? configParamsMetaInfo[param["key"]].step * configParamsMetaInfo[param["key"]].mult : 1,
									}}
									error={param["step"] === null || param["step"] === ""}
									onChange={(e) => handleChangeStepValue(index, e)}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={3}>
								<TextField
									id="param-stop"
									label="Stop"
									type="number"
									fullWidth={true}
									disabled={param["key"] === "" || isBatchRequestProcessing}
									InputLabelProps={{shrink: true}}
									value={param["key"]? parseFloat((param["stop"] * configParamsMetaInfo[param["key"]].mult).toPrecision(6)) : ""}
									inputProps={{
										min: param["key"]? configParamsMetaInfo[param["key"]].min * configParamsMetaInfo[param["key"]].mult : "",
										max: param["key"]? configParamsMetaInfo[param["key"]].max * configParamsMetaInfo[param["key"]].mult : "",
										step: param["key"]? configParamsMetaInfo[param["key"]].step * configParamsMetaInfo[param["key"]].mult : 1,
									}}
									error={param["stop"] === null || param["stop"] === ""}
									onChange={(e) => handleChangeStopValue(index, e)}
								/>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		))
	}

    if (!open)
		return null;

	function renderSetupPanel() {
		return (
			<Grid container spacing={2} alignItems="stretch">
				{renderParamList()}
				{(paramList.current.length === 0) &&
					<Grid item sm={12} textAlign="center">
						<p style={{color: "grey"}}>
							No batch parameter defined.
						</p>
					</Grid>
				}
				<Grid item sm={12} container justifyContent="flex-end">
					<p style={{color: "red", height: 12}}>
						{countBatchSize() > maxBatchSize &&
							`Batch size cannot be more than ${maxBatchSize}`
						}
					</p>
				</Grid>
				<Grid item sm={12} container justifyContent="flex-end">
					<Button variant="outlined"
							size="large"
							style={{marginLeft: 6}}
							disabled={isBatchRequestProcessing || (paramList.current.length >= maxParams) || (paramList.current.length > 0 && !paramList.current[paramList.current.length-1]["key"])}
							onClick={handleAddParameter}>
						Add Parameter
					</Button>
					<Button variant="contained"
							size="large"
							style={{marginLeft: 6}}
							disabled={isBatchRequestProcessing || paramList.current.length === 0 || !isParamListValid() || countBatchSize() > maxBatchSize}
							onClick={handleRunBatch}
					>
						Run Batch Simulation
					</Button>
				</Grid>
			</Grid>
		);
	}


	return (
		<Dialog fullScreen open={open} fullWidth maxWidth = {'lg'} PaperProps={{sx: {minHeight: "60%"}}}>
			<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
				Batch Simulation
				{paramList.current.length > 0? `: ${countBatchSize()} Scenarios` : ""}
				{countBatchSize() > maxBatchSize &&
					<WarningIcon sx={{marginLeft: 1, color: "red"}}/>
				}
			</DialogTitle>
			{isBatchRequestProcessing &&
				<LinearProgress />
			}
			<IconButton
				onClick={handleClose}
				sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}
			>
				<CloseIcon />
			</IconButton>

			<DialogContent dividers sx={{paddingBottom: 10}}>
				<Grid container spacing={2}>
					<Grid item sm={12}>
						{renderSetupPanel()}
					</Grid>
				</Grid>
            </DialogContent>
        </Dialog>
    )
}