import Box from "@mui/material/Box";
import {DialogContent, DialogTitle, FormControl, MenuItem, Select} from "@mui/material";
import React, {useEffect, useState} from "react";
import {urlApiBatchResultsIndex} from "../constants";
import axios from "axios";


export default function BatchSelector({ indexFile, onSelectCallback }) {
    const [indexData, setIndexData] = useState(null);
    const [batchId, setBatchId] = useState(indexData && Object.keys(indexData));
    const [batchIds, setBatchIds] = useState([]);

    // Load index.json on mount
    useEffect(() => {
        if (!!indexData && indexData.length > 0)
            return;

        axios.get(urlApiBatchResultsIndex)
            .then((response) => {
                // console.log("index file", response.data);
                setIndexData(response.data);
                setBatchIds(Object.keys(response.data));
            }).catch((error) => {
                // console.error('Error loading index.json:', error);
            }
        );
    }, []);

    const handleMainBatchSelectionChange = (event) => {
        if (!event.target.value)
            return;
        setBatchId(event.target.value);
        if (onSelectCallback)
            onSelectCallback(event.target.value)
    };

    return (indexData &&
        <Box>
            <DialogTitle>Select Batch</DialogTitle>
            <DialogContent >
                <FormControl fullWidth sx={{mb: 4}}>
                    <Select
                        labelId="batch-select-label"
                        value={batchId || ""}
                        onChange={handleMainBatchSelectionChange}
                    >
                        {!!batchIds && batchIds.sort((a, b) => new Date(indexData[b].date) - new Date(indexData[a].date)).map((batchId) => (
                            <MenuItem key={batchId} value={batchId}>
                                {indexData[batchId]? `[${indexData[batchId].date} : ${indexData[batchId].owner.split("@")[0] }] ${indexData[batchId].desc || batchId}` : batchId} {/* Use desc or fallback to batchId */}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
        </Box>
    );
}