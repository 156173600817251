import {
	Alert, Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle, FormControlLabel, Snackbar,
	TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import * as React from "react";
import {useState, useRef} from "react";
import {urlApiConfig} from "../../constants";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axios from "axios";


const ScenarioUploadDialog = ({open, onUpload, onDismiss}) => {
  // reference to the file selector input component, so we can clear it after each upload
  const fileInput = useRef(null);

	const [file, setFile] = useState(null);
  const [description, setDescription] = useState("");
	const [isPrivate, setIsPrivate] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

	function resetControls() {
    fileInput.current.value = null;
    setFile(null);
    setDescription("");
		setIsUploading(false);
		setErrorAlertOpen(false);
		setErrorMessage("");
  }

	function handleFileSelection(event) {
    setFile(event.target.files[0]);
  }

  function handleDescriptionChange(event) {
    setDescription(event.target.value);
  }

	function handlePrivateCheckboxChange(event) {
			setIsPrivate(event.target.checked);
	}

	function handleClose() {
		resetControls();
		if (onDismiss)
			onDismiss();
	}

	const handleErrorAlertClose = () => {
    setErrorAlertOpen(false);
  };

	function handleUpload() {
		setErrorAlertOpen(false);
    setIsUploading(true);

		const formData = new FormData();
		formData.append('file', file);

		const params = new URLSearchParams();
		params.set("desc", description);
		params.set("private", isPrivate.toString());

		const url = urlApiConfig + "?" + params.toString();

		axios.post(url, formData).then(response => {
      console.log("Upload completed", response.data);
			handleClose();
			if (onUpload)
				onUpload(response.data.id);
		}).catch(error => {
			console.error(error);
			setIsUploading(false);
			if (error.response.status === 403)
				setErrorMessage("Upload failed: File content is not a valid format.");
			setErrorAlertOpen(true);
		})
	}

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Upload Configuration File</DialogTitle>
			<DialogContent>
				<Button variant="outlined" component="label" fullWidth disabled={isUploading}>
					Select File{file != null? ": " + file.name : ""}
					<input type="file" hidden accept=".json" ref={fileInput} onChange={handleFileSelection} />
				</Button>
				<TextField autoFocus margin="dense" id="desc" label="Description" fullWidth variant="standard"
									 disabled={isUploading} onChange={handleDescriptionChange}/>
				<FormControlLabel
						control={<Checkbox
							checked={isPrivate}
							icon={<VisibilityIcon />}
							checkedIcon={<VisibilityOffIcon />}
							onChange={handlePrivateCheckboxChange}
						/>}
						label="Make private"
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} disabled={isUploading}>Cancel</Button>
				<Button variant="contained" disabled={file == null || description === "" || isUploading} onClick={handleUpload}>Upload</Button>
			</DialogActions>

			<Snackbar
				open={errorAlertOpen}
				autoHideDuration={4000}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				onClose={handleErrorAlertClose}
			>
				<Alert severity="error" sx={{ width: '100%' }}>
					{errorMessage === ""? "Upload failed" : errorMessage}
				</Alert>
	    </Snackbar>

		</Dialog>
	);
};

export default ScenarioUploadDialog;