import {useNavigate, useParams} from "react-router-dom";
import * as React from "react";
import Box from "@mui/material/Box";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	LinearProgress,
	TextField,
	Tooltip
} from "@mui/material";
import {
	batchResultsColumns, batchResultsPrimaryObjectiveColumn,
	urlApiBatchCSVFile,
	urlApiBatchResultsData,
	urlApiBatchResultsIndexItem, urlApiConfigFile, urlBatchResult,
	urlCalculator,
	urlHomeBatchDialog
} from "../constants";
import {DataGrid} from "@mui/x-data-grid";
import {useEffect, useRef, useState} from "react";
import Link from "@mui/material/Link";
import CalculateIcon from "@mui/icons-material/Calculate";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import AutoModeIcon from '@mui/icons-material/AutoMode';
import TuneIcon from '@mui/icons-material/Tune';
import HomeIcon from "@mui/icons-material/Home";
import {encodeObjectIntoUrlBinaryString, parseCsv} from "../utils";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import BatchSelector from "./BatchSelector";


const maxPageSize = 100;


function decodeBatchSizeFromBatchId(batchId) {
	if (!batchId || batchId.length === 0 || batchId.indexOf("-") === -1)
		return 0;

    const parts = batchId.split('-');
    // The base64 encoded number is always the last part
    const encodedNumber = parts.pop();
    // Decode the base64 encoded number
    try {
        const decodedBytes = atob(encodedNumber); // Decode from base64
        return parseInt(decodedBytes, 10); // Convert to integer
    } catch (e) {
        console.error('Invalid encoding or unique ID format', e);
        return null;
    }
}


export default function BatchResults() {
	const {batchId} = useParams();
	const [indexItem, setIndexItem] = useState(null);
	const [batchDesc, setBatchDesc] = useState('');
	const [csvResults, setCsvResults] = useState(null);
	const [batchResults, setBatchResults] = useState(null);
	const dataFetchTimer = useRef(null);
	const [isAwaitingResults, setIsAwaitingResults] = useState(false);
	const [isEditingDesc, setIsEditingDesc] = useState(false);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const batchSize = useRef(decodeBatchSizeFromBatchId(batchId))
	const navigate = useNavigate();

    useEffect(() => {
		// executed upon component unmount
		return () => {
			clearFetchTimer();
		}
	},[]);

    useEffect(() => {
		setIndexItem(null);
		setBatchDesc('');
		setCsvResults(null);
		setBatchResults(null);
		setIsAwaitingResults(false);
		setIsEditingDesc(false);
		setOpenDeleteDialog(false);
		batchSize.current = 0;

		if (!batchId)
            return;
		fetchIndexItem();
		batchSize.current = decodeBatchSizeFromBatchId(batchId);
        setIsAwaitingResults(true);
	}, [batchId]);

	useEffect(() => {
		if (indexItem && "desc" in indexItem)
			setBatchDesc(indexItem["desc"]);
	}, [indexItem])

    useEffect(() => {
		const loadingCompleted = !(batchSize.current > 0 && (!batchResults || Object.keys(batchResults).length < batchSize.current));
		const priorStateIsWaiting = isAwaitingResults;
		setIsAwaitingResults(!loadingCompleted);
		if (priorStateIsWaiting && loadingCompleted)
			onFinishLoadingAllData();
	}, [batchResults]); // The effect will only run when `count` changes

	function clearFetchTimer() {
		if (dataFetchTimer.current)
			dataFetchTimer.current.forEach(c => clearTimeout(c));
	}

	function fetchCSVResults() {
		if (batchId === null)
			return;
		axios.get(urlApiBatchCSVFile(batchId)).then(response => {
			const data = response['data'];
			setCsvResults(data);
			setIsAwaitingResults(false);
		}).catch(error => {
			startFetchingBatchResults();
		})
	}

	function fetchIndexItem() {
		if (batchId === null)
			return;
		axios.get(urlApiBatchResultsIndexItem(batchId)).then(response => {
			const data = response['data'];
			setIndexItem(data);
			fetchCSVResults();
		}).catch((error) => {
			console.log("Fetch index error", error)
			setIsAwaitingResults(false);
		})
	}

	function startFetchingBatchResults() {
		if (batchId === null || !batchSize.current || batchSize.current <= 0)
			return;
		const pageCount = Math.ceil(batchSize.current / maxPageSize);
		clearFetchTimer();
		dataFetchTimer.current = Array(pageCount);
		for (let i = 0; i < pageCount; i++)
			fetchBatchResultPage(i, i === pageCount - 1? batchSize.current - i * maxPageSize : maxPageSize );
	}

	function fetchBatchResultPage (pageNo, expectedSize)  {
		axios.get(urlApiBatchResultsData(batchId, pageNo)).then(response => {
			const data = response['data'];
			const results = data['results'];
			const pageNo = data['page_no'];
			const pageSize = data['max_page_size'];
			const fetchedCount = Object.keys(results).length;
			const isPageComplete = fetchedCount >= expectedSize;

			if (!isPageComplete)
				dataFetchTimer.current[pageNo] = setTimeout(fetchBatchResultPage, 3000, pageNo, expectedSize);

			setBatchResults(prev => ({
			  ...prev, // Keep all previous state
			  ...results,
			}));

		}).catch(error => {
			// console.error("Error while fetching results", error);
		})
	}

	function onFinishLoadingAllData() {
		if (!csvResults) {
			console.log("Since no CSV was found, uploading the resulting one");
			const csvContent = buildCSVString();
			setCsvResults(csvContent);

			// Send the CSV content to the server via PUT request
			axios.put(urlApiBatchCSVFile(batchId)).then(response => {
				// console.log("CSV file successfully uploaded:", response);
			}).catch(error => {
				// console.error("Error uploading CSV file:", error);
			});
		}
	}

	const urlRunNewBatch = () => {
		if (!indexItem)
			return null;
		const datasetId = indexItem["configset_meta"]["id"];
		const configsetId = indexItem["configset_meta"]["id"];
		const params = indexItem["params"];
		const encodedParamList = encodeObjectIntoUrlBinaryString(params);
		return urlHomeBatchDialog(datasetId, configsetId, encodedParamList);
	}

	const handleDeleteBatch = () => {
		setOpenDeleteDialog(true);
	};

	const handleConfirmDelete = () => {
		setOpenDeleteDialog(false);
		// Step 2: Make the DELETE request if the user confirms
		axios.delete(urlApiBatchResultsIndexItem(batchId)).then(response => {
			// Navigate back or display a success message
			console.log("Batch deleted successfully", response);
			navigate(urlBatchResult("")); // Or any other logic to handle successful deletion
		})
		.catch(error => {
			console.error("Error deleting the batch", error);
		});
	};

	const handleCancelDelete = () => {
		setOpenDeleteDialog(false); // Close the dialog if user cancels
	};

	function getBatchInfoText(batchId) {
        if (!indexItem)
            return;
        const datasetMeta = indexItem["dataset_meta"];
        const datasetDesc = datasetMeta["desc"]
        const datasetRows = datasetMeta["rows"]
        const configsetMeta = indexItem["configset_meta"];
        const configsetDesc = configsetMeta["desc"]

        return `${datasetDesc} (${Number(datasetRows).toLocaleString()} rows)  |  ${configsetDesc}`;
    }
	function buildCSVString() {
				// extract the headers from the `batchResultsColumns`
		const titles = batchResultsColumns.map(col => col.headerName).join(',');
		const fieldIds = batchResultsColumns.map(col => col.field).join(',');

		// extract the rows from the `batchResults` and prepare the data for CSV
		const rows = [];
		for (let i = 0; i < batchSize.current; i++) {
			const resultId = `${batchId}-${i}`;
			const result = batchResults && batchResults[resultId];
			if (result) {
				// Construct row using the same logic as in `renderBatchResults`
				const configset = result["configset"];
				const stats = result["results"]["stats"]["all"];
				const row = batchResultsColumns.map(col => col.customData(resultId, configset, stats));
				rows.push(row.join(','));
			}
		}

		// Step 3: Create a CSV string with headers and rows
		return [titles, fieldIds, ...rows].join('\n');
	}

	function handleDownloadCSV() {
		if (!csvResults)
			return;
		// trigger download of the CSV file
		const blob = new Blob([csvResults], { type: 'text/csv;charset=utf-8;' });
		const link = document.createElement('a');
		const url = URL.createObjectURL(blob);
		link.setAttribute('href', url);
		link.setAttribute('download', 'batch_results.csv');
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	function renderBatchNotFound() {
		return (
			<h3>Batch not found</h3>
		);
	}

	function getOrderedColumns() {
		const params = indexItem["params"];
		const paramFields = params.map(p => p["field"]);

		let paramColNo = 1000;
		let resultColNo = 2000;
		let configColNo = 3000;
		let columns = [...batchResultsColumns].map(item => ({
			...item,
			colNo: (
				item["field"] === "link" ? 1 : (
					paramFields.indexOf(item["field"]) > -1 || item["field"] === batchResultsPrimaryObjectiveColumn ? paramColNo++ : (
						item["field"].startsWith("config:") ? configColNo++ : resultColNo++
					)
				)
			),
		}))
		columns.sort((a, b) => (a.colNo - b.colNo));
		return columns;
	}

	function renderCsvResults() {
		if (!csvResults)
			return;

		// Parse the CSV results
		const { headers, rows } = parseCsv(csvResults, 1);
		batchSize.current = rows.length;

		// Create rows by giving each row an ID (DataGrid requires an ID)
		const gridRows = rows.map((row, index) => ({
			id: index,
			...row,
		}));

		const columns = getOrderedColumns();

		return (
			<Box sx={{ width: "100%", height: "100%" }}>
				<DataGrid
					rows={gridRows}
					columns={columns}
					initialState={{
						pagination: {
							paginationModel: { page: 0, pageSize: 100 },
						},
					}}
					autoHeight={false} // Disable autoHeight
					pageSizeOptions={[50, 100, 200, 500]}
				/>
			</Box>
		);
	}

    function renderBatchResults() {
		let rows = [];
		for (let i = 0; i < batchSize.current; i++) {
			const resultId = `${batchId}-${i}`;
			const result = batchResults && (resultId in batchResults)? batchResults[resultId] : null;
			const configset = result && result["configset"];
			const stats = result && result["results"]["stats"]["all"];

			const row = batchResultsColumns.reduce((accumulator, item) => {
				accumulator[item.field] = item.customData(resultId, configset, stats);
				return accumulator;
			}, {});

			rows.push({
				id: resultId,
				loading: !result,
				...row
			});
		}

		const columns = getOrderedColumns();

		return (
			<Box sx={{ width: "100%", height: "100%" }}>
				<DataGrid
					rows={rows}
					columns={columns}
					initialState={{
						pagination: {
							paginationModel: { page: 0, pageSize: 100 },
						},
					}}
					autoHeight={false} // Disable autoHeight
				/>
			</Box>
		)
	}

	// Toggle editing mode
	const handleDescDoubleClick = () => {
		setIsEditingDesc(true);
	};

	// Save the edited value
	const handleDescSave = () => {
		setIsEditingDesc(false);

		axios.put(urlApiBatchResultsIndexItem(batchId), {...indexItem,
			desc: batchDesc
		}).then(response => {
		}).catch(error => {
		})
	};

	const handleDescKeyPress = (event) => {
    	if (event.key === 'Enter')
      		handleDescSave();
  	};

	// Handle changes in TextField
	const handleDescChange = (event) => {
		setBatchDesc(event.target.value);
	};

	function renderMainBatchSelector() {
		return (
            <BatchSelector
                onSelectCallback={(newBatchId) => navigate(urlBatchResult(newBatchId))}
            />
        )
	}

	return (<>
		<Dialog fullScreen open={true} fullWidth maxWidth = {'lg'} PaperProps={{sx: {minHeight: "100%"}}}>
			<DialogTitle sx={{ m: 0, p: 2, gap: 1 }} display="flex">
                <Box>
                    <IconButton onClick={() => { navigate(-1) }}>
                        <Tooltip title="Go Back">
                            <ArrowBackIcon/>
                        </Tooltip>
                    </IconButton>
                </Box>
                <Box sx={{marginRight: 2}}>
                    <IconButton onClick={() => { navigate("/") }}>
                        <Tooltip title="Go Home">
                            <HomeIcon/>
                        </Tooltip>
                    </IconButton>
                </Box>

				{indexItem && <Box sx={{alignSelf: "center"}}>
					<Box sx={{alignSelf: "center"}} display="flex">
						{isEditingDesc ? (
							<TextField
								variant="outlined"
								size="small"
								value={batchDesc}
								onChange={handleDescChange}
								onBlur={handleDescSave} // automatically save when the input loses focus
								onKeyPress={handleDescKeyPress}
								autoFocus

							/>
						) : (
							<Box onDoubleClick={handleDescDoubleClick} sx={{alignSelf: "center"}}>
								{!indexItem? "" : (!batchDesc || batchDesc === ""? "Untitled" : batchDesc)}
							</Box>
						)}

						<Box sx={{alignSelf: "center"}}>
							{!batchResults && csvResults? `—${batchSize.current} of ${batchSize.current} Results` : ""}
							{batchResults !== null && batchSize.current > 0? `—${Object.keys(batchResults).length} of ${batchSize.current} Results` : ""}
						</Box>
					</Box>
					{!!indexItem &&
                        <Box sx={{alignSelf: "center"}}>
                            <Typography variant="body1">{getBatchInfoText(batchId)}</Typography>
                        </Box>
                    }
				</Box>}

				<Box sx={{flexGrow: 1}} />

				<Link href={urlCalculator(batchId)} disabled={!csvResults}>
					<IconButton disabled={!csvResults}>
						<Tooltip title="Open Calculator">
							<CalculateIcon/>
						</Tooltip>
					</IconButton>
				</Link>
				<Link href={urlRunNewBatch()} disabled={!indexItem}>
					<IconButton disabled={!indexItem}>
						<Tooltip title="Run New Batch">
							<AutoModeIcon />
						</Tooltip>
					</IconButton>
				</Link>
				<Box>
					<IconButton onClick={handleDeleteBatch} disabled={!indexItem}>
						<Tooltip title="Delete Batch">
							<DeleteIcon/>
						</Tooltip>
					</IconButton>
				</Box>
				<Box>
					<IconButton onClick={handleDownloadCSV} disabled={!csvResults}>
						<Tooltip title="Download CSV">
							<DownloadIcon/>
						</Tooltip>
					</IconButton>
				</Box>
				<Link href={urlApiConfigFile(!!indexItem? indexItem["configset_meta"]["id"] : "")} target="_blank" disabled={!indexItem}>
					<IconButton disabled={!indexItem}>
						<Tooltip title="Base Configset">
							<TuneIcon />
						</Tooltip>
					</IconButton>
				</Link>

			</DialogTitle>

			{isAwaitingResults &&
				<LinearProgress variant={!batchResults || Object.keys(batchResults).length === 0? "indeterminate" : "determinate"} value={(batchResults? Object.keys(batchResults).length : 0) / (batchSize.current? batchSize.current : 1) * 100 } />
			}

			<DialogContent dividers sx={{
				height: "calc(100vh - 120px)",
				display: "flex",
				flexDirection: "column",
				overflow: "hidden", // prevent page-level scroll
 			}}>
				{batchId && !indexItem && !isAwaitingResults && renderBatchNotFound()}
				{indexItem && (csvResults ? renderCsvResults() : renderBatchResults())}

				{/* Either no batch ID provided, or provided batch was not found */}
			 	{(!batchId || (!indexItem && !isAwaitingResults)) && renderMainBatchSelector()}
			</DialogContent>
		</Dialog>

		{/* The confirmation dialog */}
        <Dialog open={openDeleteDialog} onClose={handleCancelDelete}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this batch? This action cannot be undone.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelDelete} color="primary">Cancel</Button>
                <Button onClick={handleConfirmDelete} color="secondary" autoFocus>Delete</Button>
            </DialogActions>
        </Dialog>
    </>)
}