import './Home.css';
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Avatar from "@mui/material/Avatar";
import {useCallback, useRef, useState} from "react";
import DatasetPage from "./Data/DatasetPage";
import ScenarioPage from "./Scenario/ScenarioPage";
import {
  urlHome,
  urlAuthLogout,
  urlApiSimulation,
  urlApiBatchSimulation,
  urlDeployment,
  urlLogs,
  urlUsersRecentActivity,
  urlRefreshAccessControl, urlHomeBatchDialog
} from "../constants";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import ConfigurationPage from "./Configuration/ConfigurationPage";
import {Alert, Snackbar, Stack} from "@mui/material";


const drawerWidth = 240;
const navItems = [{
  'id': 'logs',
  'title': 'Activity Logs',
  'url': urlLogs,
  'admin': true,
},{
  'id': 'recent-activity',
  'title': 'Users',
  'url': urlUsersRecentActivity,
  'admin': true,
},{
  'id': 'refresh-access-control',
  'title': 'Refresh Access',
  'url': urlRefreshAccessControl,
  'admin': true,
},{
  'id': 'logout',
  'title': 'Logout',
  'url': urlAuthLogout,
  'admin': false,
}];
const PanelId = {
	Data: "panel-data",
	Scenario: "panel-scenario",
	Configuration: "panel-configuration"
}

export default function Home({userInfo, batchOpen}) {
  const {datasetId, configsetId, batchParamsEncoded} = useParams();
  const [scenarioListRefresher, setScenarioListRefresher] = useState(0);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("");
  const navigate = useNavigate();
  const isAdmin = userInfo["admin_permission"];

  // this variable stores what we need to know to run a simulation
  let simulationScenario = useRef({
    datasetId: datasetId || "",
    configsetId: configsetId || "default",
  });

  let batchParams = useRef({
    open: batchOpen || false,
    batchParamsEncoded: batchParamsEncoded || "",
  })

  const updateWindowUrl = (avoidHistory=false) => {
    const url = !batchParams.current.open? urlHome(
      simulationScenario.current.datasetId || "",
      simulationScenario.current.configsetId || ""
    ) : urlHomeBatchDialog(
      simulationScenario.current.datasetId || "",
      simulationScenario.current.configsetId || "",
      batchParams.current.batchParamsEncoded
    );
    navigate(url, {replace: avoidHistory});
  }

  const runSimulation = (callbackDone=null, callbackError=null) => {
    const {datasetId, configsetId} = simulationScenario.current;

    axios.put(urlApiSimulation(datasetId, configsetId)).then(response => {
      console.log("runSimulation", response.data);
      const resultId = response.data["payload"]["result_id"];
      const url = urlDeployment(resultId);
      if (callbackDone)
        callbackDone(resultId);
      navigate(url);
		}).catch(error => {
          // console.log(error);
          if ("response" in error) {
            setErrorSnackbarMessage(`${error.response.data} [${error.response.status}]`);
          } else
            setErrorSnackbarMessage(`${error.message}`);
          setErrorSnackbarOpen(true);
          if (callbackError)
            callbackError(error);
    })
  }

  const runBatchSimulations = (batchParams, callbackDone=null, callbackError=null) => {
    const {datasetId, configsetId} = simulationScenario.current;

    axios.put(urlApiBatchSimulation, {
      "dataset_id": datasetId,
      "configset_id": configsetId,
      "batch_params": batchParams
    }).then(response => {
      console.log("runBatchSimulations", urlApiBatchSimulation, response.data);

      const batchId = response.data["payload"]["batch_id"];
      if (callbackDone)
        callbackDone(batchId, response.data["payload"]);

      // navigate(url);
    }).catch(error => {
      // console.log(error);
      if ("response" in error) {
        setErrorSnackbarMessage(`${error.response.data} [${error.response.status}]`);
      } else
        setErrorSnackbarMessage(`${error.message}`);
      setErrorSnackbarOpen(true);
      if (callbackError)
        callbackError(error);
    })
  }

  const handleDatasetSelect = useCallback(item => {
    simulationScenario.current.datasetId = item;
    updateWindowUrl();
  },[]);

  const handleConfigsetSelect = useCallback(item => {
    simulationScenario.current.configsetId = item;
    updateWindowUrl();
  },[]);

  const handleBatchOpenChange = useCallback(open => {
    batchParams.current.open = open;
    updateWindowUrl();
  },[]);

  const handleBatchParamChange = useCallback(encodedString => {
    batchParams.current.batchParamsEncoded = encodedString;
    updateWindowUrl(true);
  },[]);

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  function refreshScenarioList() {
    setScenarioListRefresher(scenarioListRefresher + 1);
  }

  const panels = [{
    "id": PanelId.Data,
    "heading": "Dataset",
    "subheading": "",
    "content": <DatasetPage
      userInfo={userInfo}
      initDatasetId={simulationScenario.current.datasetId}
      onSelect={handleDatasetSelect}
    />,
  }, {
    "id": PanelId.Scenario,
    "heading": "Scenario",
    "subheading": "",
    "content": <ScenarioPage
      userInfo={userInfo}
      listRefresh={scenarioListRefresher}
      initConfigsetId={simulationScenario.current.configsetId}
      onSelect={handleConfigsetSelect}
    />,
  }, {
    "id": PanelId.Configuration,
    "heading": "Configuration",
    "subheading": "",
    "content": <ConfigurationPage
      datasetId={simulationScenario.current.datasetId}
      configsetId={simulationScenario.current.configsetId}
      userInfo={userInfo}
      batchOpen={batchOpen}
      batchParamsEncoded={batchParams.current.batchParamsEncoded}
      onConfigsetIdChange={(id) => {
        handleConfigsetSelect(id);
        refreshScenarioList();
      }}
      onBatchOpenChange={(open) => {
        handleBatchOpenChange(open);
      }}
      onBatchParamChange={(encodedString) => {
        handleBatchParamChange(encodedString);
      }}
      onRunBatch={runBatchSimulations}
      onDone={runSimulation}
    />,
  }]

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Polaris
      </Typography>
      <Divider />
      <List>
        {navItems.filter(item => !item["admin"] || isAdmin).map((item) => (
          <ListItem key={item.id} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} href={item.url}>
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  function isPanelExpanded(panelId) {
    if (panelId === PanelId.Data)
      return !(simulationScenario.current.datasetId && simulationScenario.current.datasetId.length>0);
    if (panelId === PanelId.Scenario)
      return !(simulationScenario.current.configsetId && simulationScenario.current.configsetId.length>0 && simulationScenario.current.configsetId!=="default");
    if (panelId === PanelId.Configuration)
      return true;
    return true;
  }

  const renderAccessDeniedForViewerUsers = () => {
    return (
      <Stack spacing={2}>
        <Typography variant={"h1"} textAlign={"left"}>
          403
        </Typography>
        <Typography variant={"h4"} textAlign={"left"}>
          Access Denied
        </Typography>
        <Typography style={{ whiteSpace: "pre-line" }} textAlign={"left"}>
          {userInfo["email"]} can view results but cannot run simulations.
        </Typography>
      </Stack>
    )
  }

  function renderAccordionPanels() {
    return (
      panels.map((panel) => (
        <Accordion
          key={panel.id}
          sx={{flexGrow: 1}}
          defaultExpanded={isPanelExpanded(panel.id)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon/>} className="accordion-header">
            <Typography className="accordion-heading-text" variant="h6">{panel.heading}</Typography>
            <Typography sx={{color: 'text.secondary'}}>{panel.subheading}</Typography>
          </AccordionSummary>
          <AccordionDetails className="accordion-body">
            {panel.content}
          </AccordionDetails>
        </Accordion>
      ))
    );
  }

  return (
    <Box sx={{display: 'flex', width: 1}}>
      <AppBar component="nav" className="home-appbar">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{mr: 2, display: {sm: 'none'}}}
          >
            <MenuIcon/>
          </IconButton>

          <Avatar src="/polaris-logo-white.png"/>

          <Typography
            variant="h6"
            component="div"
            sx={{flexGrow: 1, display: {xs: 'none', sm: 'block'}}}
          />
          <Box sx={{display: {xs: 'none', sm: 'block'}}}>
            {navItems.filter(item => !item["admin"] || isAdmin).map((item) => (
              <Button key={item.id} sx={{color: '#fff'}} href={item.url}>{item.title}</Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer variant="temporary" open={drawerOpen} onClose={handleDrawerToggle}
                ModalProps={{keepMounted: true}} // Better open performance on mobile
                sx={{
                  display: {xs: 'block', sm: 'none'},
                  '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{p: 3, width: 1}}>
        <Toolbar/>

        {userInfo["user_permission"] === true? renderAccordionPanels() : null }
        {userInfo["user_permission"] !== true? renderAccessDeniedForViewerUsers() : null }

      </Box>
      <Snackbar
        open={errorSnackbarOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => {setErrorSnackbarOpen(false)}}>
        <Alert onClose={() => {setErrorSnackbarOpen(false)}} severity="error" sx={{ width: '100%' }}>
          {errorSnackbarMessage && errorSnackbarMessage.length>0? errorSnackbarMessage : "An error occurred"}
        </Alert>
      </Snackbar>
    </Box>
  );
}
