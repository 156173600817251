import * as React from 'react';
import {
  Alert, Checkbox,
  FormControlLabel,
  Grid, InputAdornment, Radio,
  Stack,
  TextField
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import {urlApiConfigFile} from "../../constants";
import axios from "axios";
import SearchIcon from '@mui/icons-material/Search';
import {DataGrid} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const columns = [{
    field: 'selected',
    headerName: '',
    width: 60,
    editable: false,
    sortable: false,
    renderCell: (props) => (
        <Radio checked={props.row.selected} />
    ),
  },{
    field: 'desc',
    headerName: 'Description',
    width: 200,
    editable: false,
    sortable: true,
  },{
    field: 'owner',
    headerName: 'Owner',
    width: 150,
    editable: false,
    sortable: true,
  },{
    field: 'private',
    headerName: 'Private',
    width: 80,
    editable: false,
    sortable: true,
  },{
    field: 'updated',
    headerName: 'Updated',
    width: 200,
    editable: false,
    sortable: true,
  },{
		field: 'delete',
		headerName: "",
		width: 50,
		sortable: false,
		renderCell: (props) => (!props.row.deletable ? null :
        <IconButton onClick={() => props.row.onDelete(props.row.id)}>
          <DeleteIcon/>
        </IconButton>
    ),
	}
];

const ScenarioList = React.memo(({userInfo, isLoading, isFailed, items, selectedId, onSelect, onDelete}) => {

  const [filterText, setFilterText] = React.useState('');
  const handleFilterTextChange = (event) => {
    setFilterText(event.target.value);
  };

  const [isOwnFilesOnlyVisible, setIsOwnFilesOnlyVisible] = React.useState(false);
  const handlePublicCheckboxChange = (event) => {
    setIsOwnFilesOnlyVisible(event.target.checked)
  };

  // if loading, show an indicator
  if (isLoading)
    return null;

  // if unable to get the list, indicate failure
  if (isFailed)
    return <Alert severity="error" sx={{margin: 2}}>Failed to load current data set.</Alert>

  // if no items, hide the list
  if (items === null || items.length === 0)
    return null;

  function handleItemClick(id) {
    if (onSelect)
      onSelect(id);
  }

  function handleDelete(id) {
    const confirmed = window.confirm("Are you certain you wish to continue?");
    if (confirmed) {
      axios.delete(urlApiConfigFile(id)).then(() => {
        if (onDelete)
          onDelete(id)
      });
    }
  }

  function itemIncludesText(item) {
    if (!filterText || filterText === "" || !item)
      return true;
    return (item.desc.toLowerCase().includes(filterText.toLowerCase()) || item.owner.toLowerCase().includes(filterText.toLowerCase()));
  }

  function itemOwned(item) {
    return userInfo["email"].toLowerCase() === item.owner.toLowerCase();
  }

  function isItemVisible(item) {
    return itemIncludesText(item) && (!isOwnFilesOnlyVisible || itemOwned(item));
  }

  const itemsToShow = items.filter(isItemVisible);

  function renderConfigsetTable() {
		let rows = itemsToShow.map((item, idx) => ({
      id: item["id"],
      desc: item["desc"],
      owner: item["owner"],
      private: item["private"],
      updated: item["updated"],
      deletable: item["write"],
      selected: item["id"] === selectedId,
      onDelete: () => {handleDelete(item["id"])}
    }));

    return (
      <Box sx={{ height: 40*(rows.length+1.5), width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          selectionModel={selectedId}
          disableColumnMenu
          onRowClick={(e) => {handleItemClick(e.row["id"])}}
        />
      </Box>
    )
  }

  // otherwise, return the list selector
  return <Stack spacing={2}>
    <Typography align="left">
      Select simulation configuration file:
    </Typography>
    <Grid container sx={{marginBottom: 2}} direction="row" justifyContent="flex-start" alignItems="center">
      <Grid item xs={12} sm={12} md={6} lg={7} sx={{paddingRight: 2}}>
        <TextField label="Filter" variant="outlined" fullWidth size="small" onChange={handleFilterTextChange} defaultValue={filterText}
                   InputProps={{startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>)}}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={5} textAlign="left">
        <FormControlLabel
          control={<Checkbox checked={isOwnFilesOnlyVisible} onChange={handlePublicCheckboxChange} />}
          label="Own files only"/>
      </Grid>
    </Grid>

    {renderConfigsetTable()}

  </Stack>
});

export default ScenarioList;