import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import {DataGrid} from '@mui/x-data-grid';
import {
	Card,
	CardContent,
	FormControl,
	Grid,
	InputLabel,
	LinearProgress,
	MenuItem,
	Select, Stack, Tooltip
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef} from "react";
import axios from "axios";
import {
	urlApiSimulationResultData,
	urlApiSimulationRobotData,
	urlApiConfigFile,
	urlHome,
	urlApiDataHeatmapFromConfigsetId,
	distanceConvMult,
} from "../constants";
import Typography from "@mui/material/Typography";
import JsonDialog from "../Components/JsonDialog";
import {useTimer} from 'use-timer';
import Link from "@mui/material/Link";
import {Map} from "../Components/Map";
import RobotJourneyMap from "./RobotJourneyMap";


function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Deployment({userInfo}) {
	const navigate = useNavigate();
	const {resultId} = useParams();
	const dataRefreshTimer = useRef(null);
	const timer = useTimer();
	const [isLoading, setIsLoading] = React.useState(true);
	const [results, setResults] = React.useState(null);
	const [jsonDialog, setJsonDialog] = React.useState({"open": false});
	const [dispatchType, setDispatchType] = React.useState("all");
	const [robotsData, setRobotsData] = React.useState(null);
	const [heatmap, setHeatmap] = React.useState(null);

	const datasetMeta = results? results["index"]["dataset"] : null;
	const configsetId = results? results["index"]["configsetId"] : null;
	const isAdmin = userInfo["admin_permission"];

	function printStatsToConsole(results) {
		const stats = results["results"]["stats"]["all"];
		const configset = results["configset"];
		const dailyOperatingHours = configset["OperatingHours"][1] - configset["OperatingHours"][0];
		console.log([
			"Robots",
			"Speed",
			"Start Hour",
			"End Hour",
			"Pickup Distance Limit",
			"Delivery Distance Limit",
			"Funnel Leak",
			"Enforce LAB Deadline",
			"LAB Deadline Margin",
			"Deliveries Completed",
			"Eligible Deliveries",
			"Late Deliveries",
			"Daily Robot Deliveries",
			"Hourly Robot Deliveries",
			"Eligible Fulfilled %",
			"Used Robot Hours %",
			"Max Delivery Miles",
			"Max Dropoff Minutes",
			"Avg Delivery Miles",
			"Avg Dropoff Minutes",
			"Late Deliveries %",
			"Avg Late Mins",
		].join(","))
		console.log([
			configset["RobotCount"]["value"],
			(configset["RobotAvgSpeedKmh"] * distanceConvMult).toFixed(5),
			configset["OperatingHours"][0],
			configset["OperatingHours"][1],
			(configset["FullDelivery/MaxPickupKm"]["value"] * distanceConvMult).toFixed(6),
			(configset["FullDelivery/MaxDeliveryKm"]["value"] * distanceConvMult).toFixed(6),
			configset["JobFunnelLeak"],
			configset["FilterByDeadline"],
			(configset["FilterByDeadlineMarginSec"] / 60).toFixed(4),
			stats["jobs_done"].toFixed(0),
			stats["jobs_suitable"].toFixed(0),
			!stats["late_deliveries_count"]? null : stats["late_deliveries_count"].toFixed(0),
			stats["robot_avg_job_count"].toFixed(4),
			(stats["robot_avg_job_count"] / dailyOperatingHours).toFixed(4),
			(stats["jobs_done"] / stats["jobs_suitable"]).toFixed(6),
			((stats["delivery_duration"]["avg"] * stats["robot_avg_job_count"] / 60.0) / dailyOperatingHours).toFixed(6),
			(stats["delivery_distance"]["max"] * distanceConvMult).toFixed(6),
			stats["dropoff_leg_duration"]["max"].toFixed(4),
			(stats["delivery_distance"]["avg"] * distanceConvMult).toFixed(6),
			stats["dropoff_leg_duration"]["avg"].toFixed(4),
			!stats["late_deliveries_perc"]? null : stats["late_deliveries_perc"].toFixed(6),
			!stats["late_deliveries"]? null : stats["late_deliveries"]["avg"].toFixed(4),
		].join(","));
	}

	useEffect(() => {
		// executed upon first component mount
		timer.start();

		// executed upon component unmount
		return () => {
			timer.reset();
			clearTimeout(dataRefreshTimer.current)
		}
	},[]);

	const fetchResultData = () => {
		axios.get(urlApiSimulationResultData(resultId)).then(response => {
			timer.pause();
      		console.log("results", response.data);
			setIsLoading(false);
			setResults(response.data);
			printStatsToConsole(response.data);
			fetchRobotsData();
		}).catch(error => {
			console.log("Results not ready...", error);
			dataRefreshTimer.current = setTimeout(fetchResultData, 2000);
		})
	}

	const fetchRobotsData = () => {
		axios.get(urlApiSimulationRobotData(resultId)).then(response => {
      console.log("robots", response.data);
			setRobotsData(response.data);
		}).catch(error => {
			console.log("robots", error);
		})
	}

	const fetchHeatmapData = () => {
		const datasetId = results["index"]["dataset"]["id"];
		const configsetId = results["index"]["configset"]["id"];
		axios.get(urlApiDataHeatmapFromConfigsetId(datasetId, configsetId)).then(response => {
			console.log("loadHeatmap", response)
			setHeatmap(response.data);
		}).catch(error => {
			console.log("heatmap", error);
		});
	}

	useEffect(() => {
		fetchResultData();
		setIsLoading(true);
	}, [resultId])

	useEffect(() => {
		if (results) {
			console.log("fetching heatmap");
			setHeatmap(null);
			fetchHeatmapData();
		}
	}, [!results? null : results["index"]["datasetId"], !results? null : results["index"]["configsetId"]]);

	const handleClose = () => {
		if (results == null || "error" in results) {
			navigate(-1);
			return;
		}

		const datasetId = results["index"]["dataset"]["id"];
		const configsetId = results["index"]["configset"]["id"];
		const url = urlHome(datasetId, configsetId);
		navigate(url);
	}

	const handleResultsDialogOpen = () => {
		setJsonDialog({
			"title": "Results",
			"open": true,
			"data": results,
			"url": urlApiSimulationResultData(resultId)
		});
	}

	const handleScenarioDialogOpen = () => {
		setJsonDialog({
			"title": "Configuration",
			"open": true,
			"data": results["configset"],
			"url": urlApiConfigFile(configsetId)
		});
	}

	const handleJsonDialogClose = () => {
		setJsonDialog({
			"open": false
		});
	}

	function renderTimer() {
		if (!isLoading)
			return null;
		return "Running model... "  + (timer.time > 0? `(${String(timer.time).padStart(2, '0')})` : "");
	}

	function renderTitle() {
		if (isLoading || !results)
			return null;
		return results["index"]["dataset"]["desc"]
			+ " [" + results["index"]["dataset"]["date"] + "]"
			+ " / " + results["index"]["configset"]["desc"];
	}

	function renderLoadingBar() {
		if (!isLoading)
			return null;
		return <LinearProgress />;
	}

	function renderScenarioButton() {
		if (isLoading)
			return null;
		return (
			<Button sx={{color: '#fff'}} onClick={handleScenarioDialogOpen}>
				Config
			</Button>
		);
	}

	function renderAdminButtons() {
		if (isLoading || ("error" in results) || !isAdmin)
			return null;

		return ([
			<Button key="button-robots-json" sx={{color: '#fff'}}
							href={urlApiSimulationRobotData(resultId)} target="_blank">
				Robots
				<DownloadIcon />
			</Button>,
			<Button key="button-results-json" sx={{color: '#fff'}}
							onClick={handleResultsDialogOpen}>
				Results
			</Button>
		]);
	}

	function renderFailedResult() {
		if (isLoading || !("error" in results))
			return null;

		return (
			<Stack spacing={2}>
				<Typography variant={"h1"} textAlign={"left"}>
					{results["error"]["code"]}
				</Typography>
				<Typography variant={"h4"} textAlign={"left"}>
					{results["error"]["message"]}
				</Typography>
				<Typography style={{ whiteSpace: "pre-line" }} textAlign={"left"}>
					{results["error"]["details"]}
				</Typography>
			</Stack>
		)
	}

	function renderHeaderRow() {
		if (isLoading || "error" in results)
			return null;
		const configset = results["configset"];

		return (!configset["DispatchFullDelivery"] || !configset["DispatchPickupOnlyDelivery"]? null :
			<Grid item key="selector" xs={12}  align="left" sx={{display: "flex"}} alignItems="center" justifyContent="center">
				<FormControl sx={{ m: 1, minWidth: 200 }}>
					<InputLabel>Delivery Type</InputLabel>
					<Select
						value={dispatchType}
						label="Delivery Type"
						onChange={(event) => (setDispatchType(event.target.value))}
					>
						<MenuItem value="all">All Deliveries</MenuItem>
						<MenuItem value="full">End-to-end Deliveries</MenuItem>
						<MenuItem value="pickup_only">Pick-up by Robot</MenuItem>
					</Select>
				</FormControl>
				<Typography component="div" align="left" sx={{flexGrow: 1}} />
				<Typography align="right" color="text.secondary" variant="h5">{results["index"]["dataset"]["date"]}</Typography>
			</Grid>
		);
	}

	function renderHighlights() {
		if (isLoading || "error" in results)
			return null;
		const stats = results["results"]["stats"][dispatchType];
		const configset = results["configset"];
		const funnel = results["results"]["funnel"];

		const robot_count = configset["RobotCount"]["value"];
		const robot_operating_hours = configset["OperatingHours"][1] - configset["OperatingHours"][0];

		const cards = [
			<Grid item key="robots-card" xs={4} sm={4} md={4}>
				<Card>
					<CardContent>
						<Typography sx={{ fontSize: 12 }} color="text.secondary" align="left">Robots</Typography>
						<Typography color="text.primary" variant="h3">
							{numberWithCommas(robot_count)}
						</Typography>
					</CardContent>
				</Card>
			</Grid>,
			<Grid item key="jobs-card" xs={4} sm={4} md={4}>
				<Card>
					<CardContent>
						<Typography sx={{ fontSize: 12 }} color="text.secondary" align="left">Jobs</Typography>
						<Typography color="text.primary" variant="h3">
							{numberWithCommas(stats["jobs_done"])}
						</Typography>
					</CardContent>
				</Card>
			</Grid>,
			<Grid item key="jobs-per-robot-per-day-card" xs={4} sm={4} md={4}>
				<Card>
					<CardContent>
						<Typography sx={{ fontSize: 12 }} color="text.secondary" align="left">Daily Robot Jobs</Typography>
						<Typography color="text.primary" variant="h3">
							{stats["robot_avg_job_count"].toFixed(1)}
						</Typography>
					</CardContent>
				</Card>
			</Grid>,
			<Grid item key="jobs-per-robot-per-hour-card" xs={4} sm={4} md={4}>
				<Card>
					<CardContent>
						<Typography sx={{ fontSize: 12 }} color="text.secondary" align="left">Hourly Robot Jobs</Typography>
						<Typography color="text.primary" variant="h3">
							{(stats["robot_avg_job_count"] / (robot_operating_hours)).toFixed(1)}
						</Typography>
					</CardContent>
				</Card>
			</Grid>,
			<Grid item key="jobs-fulfillment-card" xs={4} sm={4} md={4}>
				<Card>
					<CardContent>
						<Typography sx={{ fontSize: 12 }} color="text.secondary" align="left">Eligible Fulfilled</Typography>
						<Typography color="text.primary" variant="h3">
							{(stats["jobs_done"] / funnel[funnel.length-1]["jobs"] * 100).toFixed(0)}%
						</Typography>
					</CardContent>
				</Card>
			</Grid>,
			<Grid item key="robot-hours-utilized-card" xs={4} sm={4} md={4}>
				<Card>
					<CardContent>
						<Typography sx={{ fontSize: 12 }} color="text.secondary" align="left">Used Robot Hours</Typography>
						<Typography color="text.primary" variant="h3">
							{((stats["delivery_duration"]["avg"] * stats["robot_avg_job_count"] / 60.0) / robot_operating_hours * 100).toFixed(0)}%
						</Typography>
					</CardContent>
				</Card>
			</Grid>,
			stats["late_deliveries_perc"] == null? null : <Grid item key="late-perc-card" xs={4} sm={4} md={4}>
				<Card>
					<CardContent>
						<Typography sx={{ fontSize: 12 }} color="text.secondary" align="left">Late Jobs</Typography>
						<Typography color="text.primary" variant="h3">
							{stats["late_deliveries_perc"] != null? (stats["late_deliveries_perc"] * 100).toFixed(1)+"%" : "-"}
						</Typography>
					</CardContent>
				</Card>
			</Grid>
		];

		return <Grid item key="highlights-row" xs={12} sm={12} md={12}>
			<div style={{overflowX: 'auto', width: '100%', padding: 1}}>
				<Grid container spacing={1}  wrap="nowrap">
					{cards}
				</Grid>
			</div>
		</Grid>
	}

	function renderJobFunnel() {
		if (isLoading || "error" in results)
			return null;
		const stats = results["results"]["stats"][dispatchType];
		const funnel = results["results"]["funnel"].slice(1);
		const columns = [
			{
				field: 'step',
				headerName: 'Filter',
				width: 200,
				editable: false,
				sortable: false,
				renderCell: (props) =>  (
       		<Tooltip title={props.value.title + "—" + props.value.tip} >
						<span>{props.value.title}</span>
        	</Tooltip>
      	),
			},
			{
				field: 'jobs',
				headerName: 'Jobs',
				type: 'number',
				width: 80,
				editable: false,
				sortable: false,
			}
		];
		for (let idx = 0; idx < funnel.length; idx++)
			columns.push({
				field: 'leak-rate-' + idx,
				headerName: '',
				type: 'number',
				width: 60,
				editable: false,
				sortable: false,
			});

		let rows = funnel.map((item, idx) => ({id: idx, step: {title: item["step"], tip: item["desc"]}, jobs: item["jobs"]}));
		rows.push({id: funnel.length, step: {title: "Jobs Completed", tip: "Jobs completed by robots"}, jobs: stats["jobs_done"]});
		for (let i = 0; i < rows.length; i++)
			for (let j = i; j < rows.length; j++)
				rows[j]["leak-rate-" + i] = (i === j? "100" : (rows[j].jobs / rows[i].jobs * 100).toPrecision(2)) + "%";

		return (
			<Grid item md={6} sm={12} xs={12} align="left">
				<Card sx={{ minWidth: 275 }}>
					<CardContent>
						<Typography sx={{ fontSize: 14 }} color="text.secondary" align="left">Jobs Funnel</Typography>
						<Box sx={{ height: 40*(funnel.length+1.5), width: '100%' }}>
							<DataGrid
								rows={rows}
								columns={columns}
								hideFooterPagination
								hideFooter
								disableSelectionOnClick
								disableColumnMenu
								disableColumnFilter
								density={"compact"}
							/>
						</Box>
					</CardContent>
				</Card>
			</Grid>
		);
	}

	function renderFleetMap() {
		if (isLoading || "error" in results)
			return null;
		return (
			<Grid item md={6} sm={12} xs={12}>
				<Card sx={{ minWidth: 275 }}>
					<CardContent>
						<Typography sx={{ fontSize: 14 }} color="text.secondary" align="left">Robot Deployment Map</Typography>
						<div style={{ height: '260px', width: '100%' }}>
							<Map
								configset={results["configset"]}
								merchants={datasetMeta["merchants"]}
								heatmap={heatmap}
								merchantsServed={results["results"]["merchants"]}
								enableMarkerDrag={false}
							/>
						</div>
						<Typography sx={{ fontSize: 10 }} color="text.secondary" align="left">Grey: merchant. Blue: merchant served by robot. Red: hot zones.</Typography>
					</CardContent>
				</Card>
			</Grid>
		);
	}

	function renderDeliveryStats() {
		if (isLoading || "error" in results)
			return null;
		const stats = results["results"]["stats"][dispatchType];

		const colSize = 150;
		const columns = [
			{
				field: 'title',
				headerName: '',
				width: colSize,
				editable: false,
			}, {
				field: 'deliveryDistance',
				headerName: 'Delivery Miles',
				type: 'number',
				width: colSize,
				editable: false,
			}, {
				field: 'pickupDistance',
				headerName: 'Pick-up Miles',
				type: 'number',
				width: colSize,
				editable: false,
			}, {
				field: 'dropoffDistance',
				headerName: 'Drop-off Miles',
				type: 'number',
				width: colSize,
				editable: false,
			}, {
				field: 'deliveryTime',
				headerName: 'Delivery Mins',
				type: 'number',
				width: colSize,
				editable: false,
			}, {
				field: 'customerTime',
				headerName: 'Customer Wait',
				type: 'number',
				width: colSize,
				editable: false,
			}, {
				field: 'pickupTravel',
				headerName: 'P.U. Travel Mins',
				type: 'number',
				width: colSize,
				editable: false,
			}, {
				field: 'pickupWait',
				headerName: 'P.U. Wait Mins',
				type: 'number',
				width: colSize,
				editable: false,
			}, {
				field: 'dropoffTravel',
				headerName: 'D.O. Travel Mins',
				type: 'number',
				width: colSize,
				editable: false,
			}, {
				field: 'dropoffWait',
				headerName: 'D.O. Wait Mins',
				type: 'number',
				width: colSize,
				editable: false,
			}, {
				field: 'lateTime',
				headerName: 'Late Mins',
				type: 'number',
				width: colSize,
				editable: false,
			}
		];

		let rows = ["avg","med","max"].map((stat) => ({
			id: stat,
			title: {"avg": "Average", "med": "Median", "max": "Maximum"}[stat],
			deliveryDistance: (stats["delivery_distance"][stat] * distanceConvMult).toPrecision(3),
			pickupDistance: (stats["pickup_distance"][stat] * distanceConvMult).toPrecision(3),
			dropoffDistance: (stats["dropoff_distance"][stat] * distanceConvMult).toPrecision(3),
			lateTime: stats["late_deliveries"]? stats["late_deliveries"][stat].toPrecision(3) : "-",
			deliveryTime: stats["delivery_duration"][stat].toPrecision(3),
			customerTime: stats["customer_wait_duration"][stat].toPrecision(3),
			pickupTravel: stats["pickup_leg_duration"][stat].toPrecision(3),
			pickupWait: stats["pickup_wait_duration"][stat].toPrecision(3),
			dropoffTravel: stats["dropoff_leg_duration"][stat].toPrecision(3),
			dropoffWait: stats["dropoff_wait_duration"][stat].toPrecision(3),
		}));

		return (
			<Grid item md={12} sm={12} xs={12} align="left">
				<Card sx={{ minWidth: 275 }}>
					<CardContent>
						<Typography sx={{ fontSize: 14 }} color="text.secondary" align="left">Delivery Stats</Typography>
						<Box sx={{ height: 170, width: '100%' }}>
							<DataGrid
								rows={rows}
								columns={columns}
								hideFooterPagination
								hideFooter
								disableSelectionOnClick
								disableColumnMenu
								disableColumnFilter
								density={"compact"}
							/>
						</Box>
					</CardContent>
				</Card>
			</Grid>
		);
	}

	function renderMerchants() {
		if (isLoading || "error" in results)
			return null;
		const merchants = results["results"]["merchants"];
		const funnel = results["results"]["funnel"];
		const configset = results["configset"];
		const columns = [
			{
				field: 'rank',
				headerName: 'Rank',
				type: 'number',
				width: 60,
				editable: false,
			},{
				field: 'id',
				headerName: 'ID',
				width: 100,
				editable: false,
			},{
				field: 'merchant',
				headerName: 'Merchant',
				width: 200,
				editable: false,
				renderCell: (props) => (<Link
						href={`https://maps.google.com/?q=${props.value.lat},${props.value.lng}`}
						target="_blank"
					>
						{props.value.name}
					</Link>
				)
			},{
				field: 'jobs',
				headerName: 'Jobs Done',
				type: 'number',
				width: 100,
				editable: false,
			},{
				field: 'eligible',
				headerName: 'Jobs Eligible',
				type: 'number',
				width: 100,
				editable: false,
			},{
				field: 'doneRatio',
				headerName: '% Jobs Done',
				type: 'number',
				width: 120,
				editable: false,
			},{
				field: 'cumulativeDone',
				headerName: 'Cumulative Jobs Done %',
				type: 'number',
				width: 180,
				editable: false,
			},{
				field: 'cumulativeDemand',
				headerName: 'Cumulative Demand %',
				type: 'number',
				width: 180,
				editable: false,
			},{
				field: 'distanceBase',
				headerName: 'Distance to Base (mi)',
				type: 'number',
				width: 120,
				editable: false,
			}
		]
		if (configset["UseHotZones"])
			columns.push({
				field: 'distanceHotZones',
				headerName: 'Distance to Hot Zone (mi)',
				type: 'number',
				width: 120,
				editable: false,
			})

		let rows = merchants.map((m,i) => ({
			rank: i+1,
			id: m["id"],
			merchant: {name: m["name"], lat: m["lat"], lng: m["lng"]},
			jobs: m["count"],
			eligible: m["filtered_jobs"],
			doneRatio: (m["count"] === m["filtered_jobs"]? "100%" : (m["count"] / m["filtered_jobs"] * 100).toPrecision(2) + "%"),
			distanceBase: m["distance_to_base"]? m["distance_to_base"].toPrecision(2) : "",
			distanceHotZones: m["distance_to_hotzone"]? m["distance_to_hotzone"].toPrecision(2) : "",
		}));

		let accum_eligible = 0, accum_done = 0;
		const total_done = results["results"]["stats"]["all"]["jobs_done"];
		const total_eligible = funnel[funnel.length-1]["jobs"];
		for (let i = 0; i < rows.length; i++) {
			accum_done += rows[i].jobs;
			accum_eligible += rows[i].eligible;
			rows[i].cumulativeDone = (accum_done / total_done * 100).toPrecision(2) + "%";
			rows[i].cumulativeDemand = (accum_eligible / total_eligible * 100).toPrecision(2) + "%";
		}

		return (
			<Grid item md={12} sm={12} xs={12} align="left">
				<Card sx={{ minWidth: 275 }}>
					<CardContent>
						<Typography sx={{ fontSize: 14 }} color="text.secondary" align="left">Merchant Popularity</Typography>
						<Box sx={{ height: 400, width: '100%' }}>
							<DataGrid
								rows={rows}
								columns={columns}
								pageSize={50}
								disableSelectionOnClick
								density={"compact"}
							/>
						</Box>
					</CardContent>
				</Card>
			</Grid>
		);
	}

	function renderRobotsJourney() {
		if (isLoading || "error" in results || !robotsData)
			return null;
		const merchants = results["results"]["merchants"];
		const configset = results["configset"];

		return (<RobotJourneyMap
			robots={robotsData}
			heatmap={heatmap}
			configset={configset}
		/>);
	}

	function renderDropoffVehicleScenarios() {
		if (isLoading || "error" in results || !("dropoff_vehicle" in results["results"]) || results["results"]["dropoff_vehicle"]["vehicles"].length === 0)
			return null;
		const scenarios = results["results"]["dropoff_vehicle"]["dropoff_vehicle_scenarios"];
		const columns = [
			{
				field: 'vehicles',
				headerName: 'Vehicles',
				type: 'number',
				width: 100,
				editable: false,
			},{
				field: 'job_count',
				headerName: 'Jobs',
				type: 'number',
				width: 100,
				editable: false,
			},{
				field: 'jobs_per_vehicle',
				headerName: 'Jobs Per Vehicle',
				type: 'number',
				width: 160,
				editable: false,
			},{
				field: 'trip_count',
				headerName: 'Trips',
				type: 'number',
				width: 100,
				editable: false,
			},{
				field: 'trips_per_vehicle',
				headerName: 'Trips Per Vehicle',
				type: 'number',
				width: 160,
				editable: false,
			},{
				field: 'total_vehicles_roundtrip_km',
				headerName: 'Fleet Roundtrip Km',
				type: 'number',
				width: 160,
				editable: false,
			},{
				field: 'roundtrip_per_vehicle_km',
				headerName: 'Roundtrip Km Per Vehicle',
				type: 'number',
				width: 200,
				editable: false,
			},{
				field: 'total_displacement_km',
				headerName: 'Goods Displaced Km',
				type: 'number',
				width: 160,
				editable: false,
			},{
				field: 'displacement_per_vehicle_km',
				headerName: 'Displaced Km Per Vehicle',
				type: 'number',
				width: 220,
				editable: false,
			},{
				field: 'displacement_to_vehicle_km',
				headerName: 'Displacement to Travel Ratio',
				type: 'number',
				width: 240,
				editable: false,
			}
		]

		let rows = scenarios.map((s,i) => ({
			id: i,
			vehicles: s["vehicles"],
			job_count: s["job_count"],
			jobs_per_vehicle: (s["job_count"] / s["vehicles"]).toFixed(1),
			trip_count: s["trip_count"],
			trips_per_vehicle: (s["trip_count"] / s["vehicles"]).toFixed(1),
			total_vehicles_roundtrip_km: s["total_vehicles_roundtrip_km"].toFixed(0),
			roundtrip_per_vehicle_km: (s["total_vehicles_roundtrip_km"] / s["vehicles"]).toFixed(1),
			total_displacement_km: s["total_displacement_km"].toFixed(0),
			displacement_per_vehicle_km: (s["total_displacement_km"] / s["vehicles"]).toFixed(1),
			displacement_to_vehicle_km: s["displacement_to_vehicle_km"].toFixed(2),
		}));

		return (
			<Grid item md={12} sm={12} xs={12} align="left">
				<Card sx={{ minWidth: 275 }}>
					<CardContent>
						<Typography sx={{ fontSize: 14 }} color="text.secondary" align="left">Dropoff Vehicles — Fleet Scenarios</Typography>
						<Box sx={{ height: 400, width: '100%' }}>
							<DataGrid
								rows={rows}
								columns={columns}
								pageSize={50}
								disableSelectionOnClick
								density={"compact"}
							/>
						</Box>
					</CardContent>
				</Card>
			</Grid>
		);
	}

	function renderJsonDialog() {
		if (isLoading || "error" in results)
			return null;
		return (
			<JsonDialog
				url={"url" in jsonDialog? jsonDialog["url"] : ""}
				data={"data" in jsonDialog? jsonDialog["data"] : null}
				open={"open" in jsonDialog? jsonDialog["open"] : false}
				title={"title" in jsonDialog? jsonDialog["title"] : ""}
				onClose={handleJsonDialogClose}
			/>
		);
	}

	return (
		<Box sx={{display: 'flex', width: 1}}>
			<AppBar component="nav" className="home-appbar">
				<Toolbar>
					<Box>
						{userInfo["user_permission"] === true? <Button sx={{color: '#fff'}} onClick={handleClose}><CloseIcon/></Button> : null}
			  		</Box>
					<Typography component="div" align="left" sx={{flexGrow: 1}}>
						{renderTimer()}
						{renderTitle()}
				  	</Typography>
					<Box>
						{renderAdminButtons()}
						{renderScenarioButton()}
			  		</Box>
				</Toolbar>
				{renderLoadingBar()}
			</AppBar>
			<Box component="main" sx={{p: 3, width: 1}}>
				<Toolbar />
				<Grid container spacing={2}>
					{renderFailedResult()}
					{renderHeaderRow()}
					{renderHighlights()}
					{renderDeliveryStats()}
					{renderFleetMap()}
					{renderJobFunnel()}
					{renderMerchants()}
					{renderRobotsJourney()}
					{renderDropoffVehicleScenarios()}
					{renderJsonDialog()}
				</Grid>
		  	</Box>
		</Box>
	)
}
