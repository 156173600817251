import * as React from 'react';
import {Alert, LinearProgress, Radio} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import {urlApiDataFile} from "../../constants";
import axios from "axios";
import Box from "@mui/material/Box";
import {DataGrid} from "@mui/x-data-grid";

function numberWithCommas(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const columns = [
  {
    field: 'selected',
    headerName: '',
    width: 60,
    editable: false,
    sortable: false,
    renderCell: (props) => (
      <Radio checked={props.row.selected} />
      ),
  },{
    field: 'desc',
    headerName: 'Description',
    width: 250,
    editable: false,
    sortable: true,
  },{
    field: 'rows',
    headerName: 'Rows',
    width: 80,
    type: 'number',
    editable: false,
    sortable: true,
  },{
    field: 'date',
    headerName: 'Date',
    width: 100,
    editable: false,
    sortable: true,
  },{
    field: 'private',
    headerName: 'Private',
    width: 100,
    editable: false,
    sortable: true,
  },{
    field: 'deadline',
    headerName: 'LAB',
    width: 100,
    editable: false,
    sortable: true,
  },{
    field: 'owner',
    headerName: 'Owner',
    width: 250,
    editable: false,
    sortable: true,
  },{
    field: 'delete',
    headerName: "",
    width: 50,
    sortable: false,
    renderCell: (props) => (!props.row.deletable ? null :
      <IconButton onClick={() => props.row.onDelete(props.row.id)}>
        <DeleteIcon/>
      </IconButton>
    ),
  }
];

const DatasetList = React.memo(({isLoading, isFailed, items, selectedId, onSelect, onDelete}) => {
  // if loading, show an indicator
  if (isLoading)
    return <LinearProgress sx={{margin: 4}}/>;

  // if unable to get the list, indicate failure
  if (isFailed)
    return <Alert severity="error" sx={{margin: 2}}>Failed to load dataset list.</Alert>

  // if no items, state that
  if (items === null || items.length === 0)
    return <Alert severity="info" sx={{margin: 2}}>No datasets available. Please upload one.</Alert>

  function handleItemClick(id) {
    if (onSelect)
      onSelect(id);
  }

  function handleDelete(id) {
    const confirmed = window.confirm("Are you certain you wish to continue?");
    if (confirmed) {
      axios.delete(urlApiDataFile(id)).then(() => {
        if (onDelete)
          onDelete(id)
      });
    }
  }

  let rows = items.map((item, idx) => ({
    id: item.id,
    desc: item.desc.length > 0? item.desc : "—",
    rows: item.rows,
    owner: item.owner,
    private: item.private,
    deadline: item.deadline,
    date: item.date,
    deletable: item.write,
    customFields: "custom_fields" in item? Object.keys(item.custom_fields).join(",") : "",
    selected: item.id === selectedId,
    onDelete: () => {handleDelete(item["id"])}
  }));

  return (
    <Box sx={{ height: 320, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        selectionModel={selectedId}
        disableColumnMenu
        onRowClick={(e) => {handleItemClick(e.row["id"])}}
      />
    </Box>
  )
});

export default DatasetList;