import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { JsonViewer } from '@textea/json-viewer'
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from '@mui/icons-material/Download';
import {DialogContent} from "@mui/material";
import Typography from "@mui/material/Typography";

export default function JsonDialog({onClose, title, data, url, open}) {

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog fullScreen onClose={handleClose} open={open}>
			<DialogTitle sx={{display: "flex", m: 0, paddingTop: 0, paddingBottom: 0}} open textAlign="end">
				<Typography>{title}</Typography>
				<div style={{flexGrow: 1}}/>
				<IconButton href={url} target="_blank">
					<DownloadIcon />
				</IconButton>
				<IconButton onClick={handleClose}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>

			<DialogContent style={{margin: 14}}>
        <JsonViewer value={data} collapsed={false}/>
			</DialogContent>
    </Dialog>
  );
}

