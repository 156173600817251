import {
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Stack,
	TextField
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import Button from "@mui/material/Button";
import {useState} from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";


export const SaveDialog = ({open, initDescription, initPrivate, onSave, onClose}) => {
	const [description, setDescription] = useState(initDescription);
	const [isPrivate, setIsPrivate] = useState(initPrivate);

	return (
		<Dialog open={open} fullWidth>
			<DialogTitle sx={{ m: 0, paddingTop: 0, paddingBottom: 0 }} open textAlign="end">
				<IconButton onClick={onClose}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>

			<DialogContent dividers>
				<Stack spacing={2}>
					<TextField label="Description" fullWidth value={description} onChange={(e) => {setDescription(e.target.value)}}/>
					<FormControlLabel
							control={<Checkbox
								checked={isPrivate}
								icon={<VisibilityIcon />}
								checkedIcon={<VisibilityOffIcon />}
								onChange={(e) => {setIsPrivate(e.target.checked)}}
							/>}
							label="Make private"
					/>
				</Stack>
			</DialogContent>

			<DialogActions>
				<Button onClick={() => onSave(description, isPrivate)}>Save As</Button>
			</DialogActions>
		</Dialog>
	)
}