import * as React from 'react';
import {Alert, Checkbox, FormControlLabel, LinearProgress, Snackbar, Stack, TextField, Tooltip} from "@mui/material";
import {urlApiData} from "../../constants";
import {useEffect, useRef, useState} from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Dropzone } from "dropzone";


export default function DatasetUpload({onUploadCompleted}) {
  const [file, setFile] = useState();
  const [description, setDescription] = useState("");
  const [isPrivate, setIsPrivate] = useState(false);
  const [isAnonymizeDropoff, setIsAnonymizeDropoff] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [uploadPercent, setUploadPercent] = React.useState(0);
  const dropzoneRef = useRef(null);
  const myDropzone= useRef(null);
  const uploadStatusTimer = useRef(null);

  useEffect(() => {
    if (isUploading)
  		uploadStatusTimer.current = setInterval(() => {
        if (file && file.upload)
          setUploadPercent(file.upload.progress);
      },100);
    else if (uploadStatusTimer.current) {
      clearInterval(uploadStatusTimer.current);
      uploadStatusTimer.current = null;
    }
  },[isUploading]);

  function resetControls() {
    setFile(null);
    setDescription("");
    setIsPrivate(false);
    setIsAnonymizeDropoff(false);
    setIsUploading(false);
    setSuccessAlertOpen(false);
    setErrorAlertOpen(false);
    setErrorMessage(null);
  }

  function handleUpload() {
    setIsUploading(true);
    setErrorAlertOpen(false);
    myDropzone.current.processQueue();
  }

  function getUploadUrl() {
    const params = new URLSearchParams();
    params.set("desc", description);
    params.set("private", isPrivate.toString());
    params.set("anonymize", isAnonymizeDropoff.toString());
		return urlApiData + "?" + params.toString();
  }

  function handleDescriptionChange(event) {
    setDescription(event.target.value);
  }

  function handlePrivateCheckboxChange(event) {
    setIsPrivate(event.target.checked);
  }

  function handleAnonymizeDropoff(event) {
    setIsAnonymizeDropoff(event.target.checked);
  }

  const handleSuccessAlertClose = () => {
    setSuccessAlertOpen(false);
  };

  const handleErrorAlertClose = () => {
    setErrorAlertOpen(false);
  };

  useEffect(() => {
    // This implements chuncked files upload. See here https://codecalamity.com/uploading-large-files-by-chunking-featuring-python-flask-and-dropzone-js/
    if (!myDropzone.current) {
      myDropzone.current = new Dropzone(dropzoneRef.current, {
        url: getUploadUrl(),
        paramName: 'file',
        acceptedFiles: ".csv",
        chunking: true,
        forceChunking: true,
        maxFilesize: 1025,
        chunkSize: 8000000,
        autoProcessQueue: false,
        clickable: true,
        complete: (file) => {
          console.log("Upload attempt finished", file);
          if (file.status === "error") {
            setIsUploading(false);
            setErrorMessage(file.xhr.responseText);
            setErrorAlertOpen(true);
            myDropzone.current.removeFile(file);
            setFile(null);
          } else {
            const res = JSON.parse(file.xhr.responseText);
            console.log("New dataset id", res.id);
            // if null, the file upload failed due to formatting issues
            if (res.id === null) {
              setIsUploading(false);
              setErrorMessage("File upload filed, likely due to formatting issues");
              setErrorAlertOpen(true);
              myDropzone.current.removeFile(file);
              setFile(null);
            } else {
              myDropzone.current.removeFile(file);
              resetControls();
              setSuccessAlertOpen(true);
              if (onUploadCompleted)
                onUploadCompleted(res.id);
            }
          }
        },
        addedfile: (file) => {
          setFile(file);
          console.log(file)
        },
      });
    } else {
      myDropzone.current.options.url = getUploadUrl();
    }
  }, [description, isPrivate, isAnonymizeDropoff]);


  // otherwise, return the list selector
  return (<Stack spacing={1}>

    <form ref={dropzoneRef} id="dropzone" >
      <Button variant="outlined" fullWidth component="label" disabled={isUploading} onClick={() => {
        myDropzone.current.hiddenFileInput.click();
      }}>
        Select File{file != null? ": " + file.name : ""}
      </Button>
        {file != null ? null :
          <Typography align="left" sx={{color: 'text.secondary', paddingTop: 2, fontSize: 11}}>
            Data files must be in CSV format and include the following fields:<br/>
            &emsp; ● id (unique delivery identification; e.g., 289999157)<br/>
            &emsp; ● timestamp (epoch time when delivery was requested; e.g., 1597003806)<br/>
            &emsp; ● pickup_id (unique merchant identification; e.g., 3883955)<br/>
            &emsp; ● pickup_name (merchant name; e.g., Happy's Liquor)<br/>
            &emsp; ● pickup_lat (e.g., 33.9209976)<br/>
            &emsp; ● pickup_lng (e.g., -118.0386207)<br/>
            &emsp; ● dropoff_lat (e.g., 33.9528264)<br/>
            &emsp; ● dropoff_lng (e.g., -118.0265452)<br/>
            &emsp; ● timezone (e.g., -8)<br/>
            &emsp; ● <i>custom_field_1</i> (optional fields may be used for filtering deliveries; e.g., 0)<br/>
            &emsp; ● <i>custom_field_2</i> (optional fields may be used for filtering deliveries; e.g., 1)<br/>
          </Typography>
        }
    </form>

    {file == null? null :
      <TextField label="Description"
                 variant="outlined"
                 disabled={isUploading}
                 onChange={handleDescriptionChange}
      />
    }

    {file == null? null :
      <Tooltip title="If selected, dropoff locations will be randomly relocated within a block radius">
        <FormControlLabel
            control={<Checkbox
              disabled={isUploading}
              checked={isAnonymizeDropoff}
              onChange={handleAnonymizeDropoff}/>}
            label="Anonymize customer locations"
        />
      </Tooltip>
    }

    {file == null? null :
      <FormControlLabel
          control={<Checkbox
            checked={isPrivate}
            disabled={isUploading}
            icon={<VisibilityIcon />}
            checkedIcon={<VisibilityOffIcon />}
            onChange={handlePrivateCheckboxChange}/>}
          label="Make dataset private"
      />
    }

    {file == null? null :
      <Button variant="contained" disabled={isUploading} onClick={handleUpload}>
        {!isUploading? "Upload" : (isUploading && uploadPercent === 100? "Processing..." : "Uploading...")}
      </Button>
    }

    {isUploading? <LinearProgress
      variant={uploadPercent === 100? 'indeterminate' : 'determinate'}
      value={uploadPercent}
    /> : null}

    <Snackbar
      open={successAlertOpen}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      onClose={handleSuccessAlertClose}
    >
      <Alert severity="success" sx={{ width: '100%' }}>
        Upload successful
      </Alert>
    </Snackbar>

    <Snackbar
      open={errorAlertOpen}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      onClose={handleErrorAlertClose}
    >
      <Alert severity="error" sx={{ width: '100%' }}>
        {errorMessage? errorMessage : "Upload failed"}
      </Alert>
    </Snackbar>

  </Stack>);
}