import pako from "pako";


function parseCsv(csvString, headerLine=0, keyMap=null) {
  const lines = csvString.split('\n').filter(line => line.trim() !== ''); // Split by new lines and filter empty lines
  const headers = lines[headerLine].split(','); // The first line contains headers
  const rows = lines.slice(headerLine+1).map(line => {
    const values = line.split(',');
    return headers.reduce((acc, header, idx) => {
      if (keyMap === null || !Object.keys(keyMap).includes(header) )
        acc[header] = values[idx];
      else
        acc[keyMap[header]] = values[idx];
      return acc;
    }, {});
  });
  return { headers, rows };
}


function encodeObjectIntoUrlBinaryString(obj) {
    if (!obj)
        return "";
    const jsonString = JSON.stringify(obj);
    const compressedData = pako.deflate(jsonString);
    const binaryString = btoa(String.fromCharCode.apply(null, compressedData));
    return encodeURIComponent(binaryString);
}


function decodeUrlBinaryStringToObject(encodedString) {
    if (!encodedString)
        return null;
    const binaryString = decodeURIComponent(encodedString);
    const charArray = atob(binaryString).split('').map(char => char.charCodeAt(0));
    const compressedData = new Uint8Array(charArray);
    const decompressedData = pako.inflate(compressedData, { to: 'string' });
    return JSON.parse(decompressedData);
}


// utility function to compare arrays
const arraysEqual = (a, b) => {
    if (a.length !== b.length) return false;
    const sortedA = [...a].sort();
    const sortedB = [...b].sort();
    for (let i = 0; i < a.length; i++) {
        if (sortedA[i] !== sortedB[i]) return false;
    }
    return true;
};



export {parseCsv, encodeObjectIntoUrlBinaryString, decodeUrlBinaryStringToObject, arraysEqual}
