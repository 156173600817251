import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {DataGrid} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from '@mui/icons-material/Download';
import LaunchIcon from '@mui/icons-material/Launch';
import {useEffect} from "react";
import axios from "axios";
import {urlApiUsersRecentActivity, urlApiLogsResponse, urlDeployment} from "../constants";
import {Alert, LinearProgress, Tooltip} from "@mui/material";

const defaultPageSize = 50;

const columns = [
  {
    field: 'view',
    headerName: 'View',
    width: 60,
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    disableExport: true,
		renderCell: (props) => {
      if (props.row.viewButtonRender)
        return props.row.viewButtonRender(props.row);
    },
  },{
    field: 'timestamp',
    headerName: 'Time',
    width: 180,
    type: "dateTime",
    editable: false,
    sortable: true,
  },{
    field: 'user',
    headerName: 'User',
    width: 200,
    editable: false,
    sortable: true,
    renderCell: (props) =>  (
       <Tooltip title={props.row.user} >
          <span className="table-cell-trucate">{props.row.user}</span>
      </Tooltip>
    ),
  },{
    field: 'type',
    headerName: 'Type',
    width: 180,
    editable: false,
    sortable: true,
    renderCell: (props) =>  (
       <Tooltip title={props.row.type} >
          <span className="table-cell-trucate">{props.row.type}</span>
      </Tooltip>
    ),
  },{
    field: 'resource',
    headerName: 'Resource',
    width: 200,
    editable: false,
    sortable: true,
    renderCell: (props) =>  (
       <Tooltip title={props.row.resource} >
          <span className="table-cell-trucate">{props.row.resource}</span>
      </Tooltip>
    ),
  },{
    field: 'payload',
    headerName: 'Payload',
    width: 300,
    editable: false,
    sortable: true,
    renderCell: (props) =>  (
       <Tooltip title={JSON.stringify(props.row.payload)} >
          <span className="table-cell-trucate">{JSON.stringify(props.row.payload)}</span>
      </Tooltip>
    ),
  },{
    field: 'response',
    headerName: 'Response',
    width: 100,
    editable: false,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    disableExport: true,
		renderCell: (props) => (!props.row.responseUrl ? null :
        <IconButton href={props.row.responseUrl} target="_blank">
          <DownloadIcon/>
        </IconButton>
    ),
  }
];

export default function UsersRecentActivity() {
  const [logRecords, setLogRecords] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(defaultPageSize);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isFailed, setIsFailed] = React.useState(false);
  const [isNotPermitted, setIsNotPermitted] = React.useState(false);

  const loadActivities = (lastId="") => {
    const params = new URLSearchParams();
		const url = urlApiUsersRecentActivity + "?" + params.toString();

    setIsLoading(true);
    axios.get(url).then(response => {
      console.log(response.data);
      let newData = logRecords.concat(response.data);
      // make sure all entries are unique
      let newDataObj = {}
      for (const r of newData)
        newDataObj[r["id"]] = r;
      // convert back into array and sort by time
      newData = [];
      for (const key of Object.keys(newDataObj))
        newData.push(newDataObj[key]);
      newData = newData.sort((a,b) => new Date(Date.parse(b["timestamp"])) - new Date(Date.parse(a["timestamp"])))

      setLogRecords(newData);
      setIsLoading(false);
    }).catch(error => {
      console.log(error);
      setIsLoading(false);
      if (error.response.status === 403)
        setIsNotPermitted(true);
      else
        setIsFailed(true);
    });
  }

  const renderViewButton = (item) => {
    console.log(item)
    switch (item["type"]) {
      case "deployment_result":
        return (
          <IconButton href={urlDeployment(item["payload"]["result_id"])} target="_blank">
            <LaunchIcon/>
          </IconButton>
        );
      case "deployment_run":
        return null;
      case "dataset_upload":
        return null;
      case "dataset_delete":
        return null;
      case "configset_upload":
        return null;
      case "configset_update":
        return null;
      case "configset_delete":
        return null;
      default:
        return null;
    }
  }

  // first load
  useEffect(() => {
    loadActivities();
  },[]);

  const rows = logRecords.map((item, idx) => ({
    id: item["id"],
    user: item["user"],
    timestamp: new Date(Date.parse(item["timestamp"])).toLocaleDateString() + " " + new Date(Date.parse(item["timestamp"])).toLocaleTimeString(),
    type: item["type"],
    resource: `${item["method"]} ${item["resource"]}`,
    payload: item["payload"],
    responseUrl: urlApiLogsResponse(item["id"]),
    viewButtonRender: renderViewButton,
  }));

  // if unable to get the list, indicate failure
  if (isFailed)
    return <Alert severity="error" sx={{margin: 2}}>Failed to load dataset list.</Alert>

  // if unable to get the list, indicate failure
  if (isNotPermitted)
    return <Alert severity="error" sx={{margin: 2}}>Not permitted.</Alert>

  return (
    <Box sx={{display: 'flex', width: 1}}>
      <AppBar component="nav" className="home-appbar">
        <Toolbar>
          <Typography variant="h6" component="div" align="left" sx={{flexGrow: 1, display: {xs: 'none', sm: 'block'}}}>
            Recent Activities
          </Typography>
        </Toolbar>
        {isLoading? <LinearProgress sx={{margin: 0}}/> : null}
      </AppBar>
      <Box component="main" sx={{p: 3, width: 1}}>
        <Toolbar/>

        <Box height="70vh" display="flex" flexDirection="column">
          <Box flex={1} overflow="auto">
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={pageSize => setPageSize(pageSize)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}