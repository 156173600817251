import * as React from "react";
import {useEffect, useState} from "react";
import axios from "axios";
import {urlApiConfig, urlApiConfigFile} from "../../constants";
import {Grid, LinearProgress, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import ScenarioList from "./ScenarioList";
import ScenarioUploadDialog from "./ScenarioUploadDialog";

const ScenarioPage = React.memo(({initConfigsetId, userInfo, listRefresh, onSelect}) => {
	const [isLoading, setLoading] = useState(true);
	const [isFailed, setFailed] = useState(false);
	const [getItems, setGetItems] = useState(null);
	const [selectedId, setSelectedId] = React.useState(initConfigsetId);
	const [selectedContent, setSelectedContent] = React.useState(null);
	const [uploadDialogOpen, setUploadDialogOpen] = React.useState(false);

	useEffect(() => {
		if (onSelect && initConfigsetId !== selectedId)
			onSelect(selectedId);
	}, [selectedId, onSelect]);

	useEffect(() => {
		if (initConfigsetId !== selectedId)
			setSelectedId(initConfigsetId);
	}, [initConfigsetId])

	function fetchConfigsetList() {
		axios.get(urlApiConfig).then(response => {
			setLoading(false);
			setGetItems(response.data);
		}).catch(error => {
			setLoading(false);
			setFailed(true);
			console.log(error);
		})
	}

	useEffect(() => {
		fetchConfigsetList()
	}, [listRefresh]);

	useEffect(() => {
		if (!selectedId || selectedId === "") {
			setSelectedContent(null);
			return;
		}

		axios.get(urlApiConfigFile(selectedId)).then(response => {
			setSelectedContent(response.data);
		}).catch(error => {
			console.log(error);
		})
	}, [selectedId])

	const handleOnSelect = (id) => {
		setSelectedId(id);
	};

	const handleUploaded = (dataId) => {
		setUploadDialogOpen(false)
		fetchConfigsetList();
		setSelectedId(dataId);
	}

	const handleDeleted = (id) => {
		fetchConfigsetList();
		if (selectedId === id)
			setSelectedId("");
	};

	return <div>
		<Grid container alignItems="flex-start" justifyContent="center" spacing={3} sx={{padding: 2}}>
			<Grid item lg={8} md={10} sm={12} xs={12}>
				<Stack spacing={1}>
					<ScenarioList
						userInfo={userInfo}
						isLoading={isLoading}
						isFailed={isFailed}
						items={getItems}
						selectedId={selectedId}
						onSelect={handleOnSelect}
						onDelete={handleDeleted}
					/>
				</Stack>
			</Grid>
		</Grid>

		{isLoading? <LinearProgress /> : null}

		<Grid container justifyContent="flex-end">
			{!(userInfo && userInfo["admin_permission"]) ? null :
				<Button download={`${selectedId}.json`} disabled={selectedId === ''}
								href={"data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(selectedContent, null, 2))}
				>
					Download
				</Button>
			}

			{!(userInfo && userInfo["admin_permission"]) ? null :
				<Button onClick={() => {setUploadDialogOpen(true)}}>
					Upload
				</Button>
			}
		</Grid>

		<ScenarioUploadDialog
			open={uploadDialogOpen}
			onUpload={handleUploaded}
			onDismiss={() => {setUploadDialogOpen(false)}}
		/>
	</div>
});

export default ScenarioPage;