import {
	Alert,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid, Radio, RadioGroup, Stack,
	TextField
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import {useCallback, useRef, useState, useEffect} from "react";
import Box from "@mui/material/Box";
import {DataGrid} from "@mui/x-data-grid";
import * as React from "react";
import {Map} from "../../Components/Map";
import axios from "axios";
import {urlApiDataHeatmapFromConfigset} from "../../constants";

const FilterTypes = {
	None: "none",
	Include: "include",
	Exclude: "exclude",
}

const merchantColumns = [
	{
		field: 'name',
		headerName: 'Name',
		width: 150,
		editable: false,
		sortable: true,
	}, {
		field: 'jobs',
		headerName: 'Jobs',
		width: 70,
		type: 'number',
		editable: false,
		sortable: true,
	}, {
		field: 'lat',
		headerName: 'Latitude',
		width: 100,
		type: 'number',
		editable: false,
		sortable: false,
	}, {
		field: 'lng',
		headerName: 'Longitude',
		width: 100,
		type: 'number',
		editable: false,
		sortable: false,
	}
]

export const MerchantDialog = ({open, datasetId, configset, merchants, onClose, onChange}) => {
	const [searchFilter, setSearchFilter] = useState("");
	const selectedIDs = useRef(configset["MerchantsWhitelist"] || configset["MerchantsBlacklist"]);
	const [heatmap, setHeatmap] = useState(null);
	const [heatmapOutdated, setHeatmapOutdated] = useState(false);

	const heatmapFetcherSource = useRef(null);

	const blacklist = "MerchantsBlacklist" in configset? configset["MerchantsBlacklist"] : null;
	const whitelist = "MerchantsWhitelist" in configset? configset["MerchantsWhitelist"] : null;

	const filterType = (whitelist == null && blacklist == null? FilterTypes.None :
		(whitelist != null && blacklist == null? FilterTypes.Include :
			(blacklist != null && whitelist == null? FilterTypes.Exclude :
				(whitelist.length > 0? FilterTypes.Include :
						blacklist.length > 0? FilterTypes.Exclude : FilterTypes.Include
		))))

	useEffect(() => {
		setHeatmapOutdated(true);
		if (open)
			fetchHeatmap();
		else
			setHeatmap(null);
	}, [configset, datasetId]);

	useEffect(() => {
		if (open && !heatmap) {
			fetchHeatmap();
		}
	}, [open])

	function fetchHeatmap() {
		if (configset && datasetId) {
			console.log("fetching heatmap...");
			// cancel previous request
			if (heatmapFetcherSource.current)
				heatmapFetcherSource.current.cancel("Canceling previous fetch requests");
			else
				heatmapFetcherSource.current = axios.CancelToken.source();
			// make a new request
			axios.post(urlApiDataHeatmapFromConfigset(datasetId), {"configset": configset}, {cancelToken: heatmapFetcherSource.current.token}).then(response => {
				console.log("heatmap", response)
				heatmapFetcherSource.current = null;
				setHeatmapOutdated(false);
				setHeatmap(response.data);
			}).catch(error => {
				console.log("heatmap error", error);
				heatmapFetcherSource.current = null;
			});
		}
	}

	function handleFilterTypeChange(event) {
		const newValue = event.target.value;
		const oldValue = filterType;

		if (newValue === FilterTypes.Exclude)
			onChange(selectedIDs.current, "MerchantsBlacklist");
		if (newValue === FilterTypes.Include)
			onChange(selectedIDs.current, "MerchantsWhitelist");
		if (oldValue === FilterTypes.Exclude || newValue === FilterTypes.None)
			onChange(null, "MerchantsBlacklist");
		if (oldValue === FilterTypes.Include || newValue === FilterTypes.None)
			onChange(null, "MerchantsWhitelist");
	}

	const handleMerchantMarkerClick = useCallback((id) => {
		if (filterType === FilterTypes.None || selectedIDs.current.indexOf(id) >= 0)
			return;

		selectedIDs.current = selectedIDs.current.concat([id]);
		if (filterType === FilterTypes.Include)
			onChange(selectedIDs.current, "MerchantsWhitelist");
		if (filterType === FilterTypes.Exclude)
			onChange(selectedIDs.current, "MerchantsBlacklist");
	}, [filterType]);

	const handleWhitelistMerchantMarkerClick = useCallback((id) => {
		if (filterType === FilterTypes.None || selectedIDs.current.indexOf(id) === -1)
			return;

		selectedIDs.current = selectedIDs.current.filter(item => item !== id);
		if (filterType === FilterTypes.Include)
			onChange(selectedIDs.current, "MerchantsWhitelist");
	}, [filterType]);

	const handleBlacklistMerchantMarkerClick = useCallback((id) => {
		if (filterType === FilterTypes.None || selectedIDs.current.indexOf(id) === -1)
			return;

		selectedIDs.current = selectedIDs.current.filter(item => item !== id);
		if (filterType === FilterTypes.Exclude)
			onChange(selectedIDs.current, "MerchantsBlacklist");
	}, [filterType]);

	function renderMerchantsTable() {
		if (!merchants)
			return;

		let rows = Object.keys(merchants).map(id => ({
			id: id,
			name: merchants[id]["name"],
			lat: merchants[id]["lat"],
			lng: merchants[id]["lng"],
			jobs: merchants[id]["jobs"],
		}));

		return (
			<Box sx={{stretch: { height: "100%" }, minHeight: "250px", height: '100%', width: '100%'}}>
				<DataGrid
					rows={searchFilter === ""? rows : rows.filter(r => r["name"].toLowerCase().includes(searchFilter.toLowerCase()))}
					columns={merchantColumns}
					selectionModel={filterType !== FilterTypes.None? selectedIDs.current : []}
					hideFooter
					hideFooterPagination
	        checkboxSelection={filterType !== FilterTypes.None}
					disableColumnMenu
					disableColumnFilter
					density={"compact"}
					experimentalFeatures={{ newEditingApi: true }}
					keepNonExistentRowsSelected={true}

					onSelectionModelChange={(ids) => {
						selectedIDs.current = ids;
						if (filterType === FilterTypes.Exclude)
							onChange(ids, "MerchantsBlacklist");
						else if (filterType === FilterTypes.Include)
							onChange(ids, "MerchantsWhitelist");
					}}
				/>
			</Box>
		)
	}

	if (!merchants)
		return <Alert severity="info" sx={{margin: 2}}>Please select a valid dataset</Alert>;

	if (!open)
		return null;

	return (
		<Dialog fullScreen open={open} fullWidth maxWidth = {'lg'} PaperProps={{sx: {minHeight: "60%"}}}>
			<DialogTitle sx={{ m: 0, paddingTop: 0, paddingBottom: 0 }} open textAlign="end">
				<IconButton onClick={onClose}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>

			<DialogContent dividers>
				<Grid container spacing={2} sx={{height: "90vh", paddingBottom: 2}} alignItems="stretch">
					<Grid item sm={12} md={4} textAlign={"left"} sx={{display: "flex", flexDirection: "column" }}>

							<RadioGroup value={filterType} onChange={handleFilterTypeChange}>
								<Grid container direction="row" sx={{margin: 1}}>
									<FormControlLabel sx={{flexGrow: 1}} value={FilterTypes.None} control={<Radio />} label="No filter" />
									<FormControlLabel sx={{flexGrow: 1}} value={FilterTypes.Include} control={<Radio />} label="Only Include" />
									<FormControlLabel sx={{flexGrow: 1}} value={FilterTypes.Exclude} control={<Radio />} label="Exclude" />
								</Grid>
							</RadioGroup>

							<TextField label="Filter" variant="outlined" value={searchFilter} sx={{marginBottom: 1}}
												 onChange={event => setSearchFilter(event.target.value)}
							/>

							{renderMerchantsTable()}
					</Grid>

					<Grid item xs={12} sm={12} md={8} lg={8} sx={{display: "flex", flexDirection: "column" }}>
						<div style={{stretch: { height: "100%" }, width: '100%', height: '100%', minHeight: "300px"}}>
							<Map
								merchants={merchants}
								configset={configset}
								heatmap={heatmap}
								heatmapOutdated={heatmapOutdated}
								filterType={filterType}
								enableMarkerDrag={false}
								onMerchantMarkerClick={handleMerchantMarkerClick}
								onWhitelistMerchantMarkerClick={handleWhitelistMerchantMarkerClick}
								onBlacklistMerchantMarkerClick={handleBlacklistMerchantMarkerClick}
							/>
						</div>
					</Grid>

				</Grid>
			</DialogContent>
		</Dialog>
	);
}